import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useEffect } from "react";
import Loading from "../../../../ATS/images/waiting.gif";
import Success from "../../../../ATS/images/confetti.gif";
import jsPDF from "jspdf";
import {
  convertBase64,
  getUserData,
  s3Client,
} from "../../../../Base/helper/baseFunctions";
import {
  GetOfferLetterTemplateResponseHelper,
  OfferEmailSentHelper,
  UpdateOfferLetterTemplateResponseHelper,
} from "../../../helper/AtsFormTemplateResponseHelper";
import Quill from "../../../../Base/views/Quill";
import { GetAtsJobKanbanOfferHelper } from "../../../../ATS/helper/AtsJobKanbanHelper";
import Popup from "../../../../Base/views/Popup";
const Completeoffer = ({
  currentJobData,
  resData,
  setIsOfferForm,
  setElements,
  OfferEdit,
  setCurrentData
}) => {
  const [candidateData, setCandidateData] = useState({});
  const [viewMode, setViewMode] = useState("Loading");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [step, setStep] = useState(OfferEdit === "true" ? 2 : 1);
  const [offerLoading, setOfferLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(Loading);
  const [message, setMessage] = useState(
    "Your personalized offer letter is being prepared. Please hold tight!"
  );
  const userData = getUserData();

  useEffect(() => {
    GetOfferLetterTemplateResponseHelper({
      workspace: userData?.domain,
      offer_letter_response_id: resData?.offer_letter_response_id,
    }).then((res) => {
      setCandidateData(res?.data?.data[0]);
      OfferEdit === "true"
        ? setDescription(res?.data?.data[0]?.offer_letter_response)
        : setDescription(
            res?.data?.data[0]?.offer_letter_edited ||
              res?.data?.data[0]?.offer_letter_response
          );
      setViewMode("Feedback");
      res?.data?.data[0]?.company_logo &&
        getImageSignedUrl(res?.data?.data[0]?.company_logo);
    });

    //eslint-disable-next-line
  }, []);
  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };

  const handleOfferComplete = async () => {
    setOfferLoading(true);
    let b64string = "";
    let filename = "";
    let old_offer_name = "";
    const htmlElement = document.querySelector(".a4-sheet");
    let jsPdf = new jsPDF("p", "pt", "letter");
    const opt = {
      callback: async function (jsPdf) {
        const pdfBlob = jsPdf.output("blob");
        b64string = await convertBase64(pdfBlob);

        if (candidateData?.offer_letter_cloud_path) {
          old_offer_name = candidateData?.offer_letter_cloud_path;
        }

        filename = `${userData?.domain}/ATS/OfferLetters/` + uuidv4() + ".pdf";
        const response = await UpdateOfferLetterTemplateResponseHelper({
          workspace: userData?.domain,
          user_id: userData?.employee_id,
          offer_letter_response_id: candidateData?.offer_letter_response_id,
          offer_letter_fields_response: JSON.stringify(
            resData?.offer_letter_fields_response
          ),
          offer_letter_response: description,
          file: b64string,
          file_name: filename,
          old_offer_name: old_offer_name,
        });

        if (response?.data?.statuscode === 200) {
          setMessage("Voila! Your offer letter is ready for viewing.");
          setImageSrc(Success);
          setCandidateData(response?.data?.data[0]);
          GetAtsJobKanbanOfferHelper({
            ats_jobpost_id: currentJobData?.ats_jobpost_id,
          }).then((res) => {
            res?.data?.data && setElements(res?.data?.data);
            res?.data?.data && setCurrentData(res?.data?.data?.[0]);
            setOfferLoading(false);
            setIsOfferForm(false);
          });
        }
      },
      margin: [10, 20, 20, 20], // Margin of 10pt on top, 20pt on other sides
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        useCORS: true, // Enable cross-origin images
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.9, // Adjust the scale if needed
      },
    };

    jsPdf.html(htmlElement, opt);
  };
  const handleUpdate = async () => {
    const response = await UpdateOfferLetterTemplateResponseHelper({
      workspace: userData?.domain,
      user_id: userData?.employee_id,
      offer_letter_response_id: candidateData?.offer_letter_response_id,
      offer_letter_fields_response: JSON.stringify(
        resData?.offer_letter_fields_response
      ),
      offer_letter_response: description,
      file: null,
      file_name: candidateData?.offer_letter_cloud_path,
    });
    if (response?.data?.statuscode === 200) {
      setCandidateData(response?.data?.data[0]);
      setStep(1);
      OfferEmailSentHelper({
        update_tblcolumn: "approver_response",
        offer_letter_response_id: candidateData?.offer_letter_response_id,
        update_tblcolumn_value: "false",
      });
    }
  };

  return (
    <>
      {viewMode === "Loading" ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", pb: 2 }}
                  >
                    <Skeleton animation="wave" width={200} />
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={350}
                        width={400}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Skeleton animation="wave" height={50} width={100} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : viewMode === "Feedback" ? (
        <>
          {step === 1 && (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ mb: 1, textAlign: "center" }} variant="h6">
                  Offer Letter Preview
                </Typography>
              </Box>
              <Grid container sx={{display:"block"}}>
                <div className="a4-sheet">
                  <div className="a4-header">
                    {candidateData?.company_logo && (
                      <img
                        className="company_logo"
                        alt="Nothing"
                        src={imageUrl}
                      />
                    )}
                    <div className="header-text">{candidateData?.header}</div>
                  </div>
                  <div className="a4-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          candidateData?.offer_letter_edited ||
                          candidateData?.offer_letter_response,
                      }}
                    ></div>
                  </div>
                  <div className="a4-footer">{candidateData?.footer}</div>
                </div>
                <Grid sx={{ width: "170mm", margin: "0 auto" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent:
                        OfferEdit === "true" ? "right" : "space-between",
                      marginTop: "20px",
                      padding: "0px 10px 40px 10px",
                    }}
                  >
                    {OfferEdit !== "true" && (
                      <Button
                        variant="contained"
                        className="mpl-primary-btn"
                        onClick={() => setStep(2)}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      className="mpl-primary-btn"
                      variant="contained"
                      onClick={handleOfferComplete}
                    >
                      Generate Offer Letter
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
          {step === 2 && (
            <>
              <Box className="response-main">
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    minHeight: "700px",
                  }}
                  className="offer_customization"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Quill
                      name="description"
                      value={description}
                      setValue={setDescription}
                      // error={Boolean(errors.description)}
                      style={{ height: "700px" }}
                      hideHeading={true}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      padding: "20px 10px 10px 10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      className="mpl-primary-btn"
                      onClick={handleUpdate}
                    >
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </>
          )}
        </>
      ) : null}

      <Popup
        maxWidth="md"
        isMasterProduct={true}
        setOpenPopup={setOfferLoading}
        openPopup={offerLoading}
        close={false}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#e6f4ea",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            padding: "20px",
            margin: "auto",
          }}
        >
          <img
            src={imageSrc}
            alt="Loading..."
            width={"100px"}
            height={"100px"}
            style={{
              backgroundColor: "#fff",
              borderRadius: "50%",
              padding: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
          <Typography sx={{ pt: 1, pb: 1, textAlign: "center" }} variant="h4">
            {message}
          </Typography>
        </Box>
        {message === "Voila! Your offer letter is ready for viewing." && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              className="mpl-primary-btn"
              variant="contained"
              onClick={() => setIsOfferForm(false)}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Popup>
    </>
  );
};
export default Completeoffer;
