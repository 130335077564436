
import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import { grey } from '@mui/material/colors';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddAtsWorkSpaceAction } from '../../../../store/ATS/SwitchWorkspace/Action';
import history from '../../../../history';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GetWorkspaceAction } from '../../../../store/Signin/Action';
import { GET_WORKSPACE_ERROR } from "../../../../store/Signin/ActionTypes";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export default function AtsMenu({ userInfoData, setAtsManageCollapse, atsManageCollapse, atsCollapse, atsSubMenu, setAtsCollapse, atsReportCollapse, setReportCollapse, atsReportMenu, open, sideBarData, selectedMenu, handleMenuClick, theme, isMobile, userData }) {
    let AtsUser = userInfoData?.is_ats_user;
    let CoreHRUser = userInfoData?.is_corehr_user;
    const dispatch = useDispatch();
    const { allowedWokspaceData } = useSelector(state => state.SignInReducer)
    const { atsModuleData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleReducer);

    useEffect(() => {
        (AtsUser && !CoreHRUser) ? (userData && allowedWokspaceData?.length === 0 && dispatch(GetWorkspaceAction({ email: userData?.email_id }))) : (userData && allowedWokspaceData?.length === 0 && dispatch(GetWorkspaceAction({ email: userData?.office_email })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!userData])


    const atsManageAccess = atsModuleData?.find(
        (item) => item.module_name === "ATS Manage"
    );

    const atsReportsAccess = atsModuleData?.find(
        (item) => item.module_name === "ATS Reports"
    );

    const atsInvoiceAccess = atsModuleData?.find(
        (item) => item.module_name === "ATS Invoice"
    );

    const atsOnboardingAccess = atsModuleData?.find(
        (item) => item.module_name === "ATS Employees Onboarding"
    );


    const storeWorkSpace = (item) => {
        if (userData?.domain !== item.domainname) {
            sessionStorage.setItem("selectedMenu", "switchworkspace");
            dispatch(AddAtsWorkSpaceAction({ domain: item }))
            dispatch({ type: GET_WORKSPACE_ERROR });
            history.push(`/ats/switchworkspace`)
        }
    };


    return (
        <React.Fragment key="ats-menu">
            <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pl: "5px", pb: 1 }}>
                <ListItemButton onClick={() => setAtsCollapse()} disableRipple selected={atsCollapse} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, paddingLeft: !open ? "5px" : "16px", borderRadius: "8px", fontWeight: 600 }}>
                    <ListItemIcon sx={{ minWidth: "35px" }}>
                        <span style={{ color: atsCollapse && `${theme?.palette?.primary?.main}` }}><ContentPasteSearchIcon fontSize='small' /></span>
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{
                        variant: "body2",
                        fontWeight: atsCollapse ? 600 : 550,
                        fontSize: "0.8rem",
                        color: atsCollapse ? "primary" : grey[600]
                    }} primary="ATS Workspaces" sx={{ opacity: open ? 1 : 0 }} />
                    {open && atsCollapse ? <ExpandLess /> : open && <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={atsCollapse} timeout="auto" unmountOnExit>
                <List sx={{ padding: "0px" }}>
                    {
                        allowedWokspaceData?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <>
                                        <ListItemButton sx={{ display: 'flex', fontWeight: "bold", fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: open ? 2 : 0 }} onClick={() => { storeWorkSpace(item) }}>
                                            <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'left', ml: !open && 1 }} >
                                                {open ? userData?.domain === item.domainname ? <ArrowDropDownIcon /> : <ArrowRightIcon /> : <HomeOutlinedIcon />}
                                            </ListItemIcon>
                                            <ListItemText primary={item?.domainname} sx={{ opacity: open ? 1 : 0, }} />
                                        </ListItemButton>
                                        <Collapse in={userData?.domain === item.domainname} timeout="auto" unmountOnExit>
                                            <List sx={{ padding: "0px" }}>
                                                {
                                                    sideBarData.map((res, i) => {
                                                        return (
                                                            <Link key={i} to={res?.route} >
                                                                {/* Tour step1 & Tour step 2 */}
                                                                {res?.show && <ListItem
                                                                    disablePadding
                                                                    data-testid={res?.label === "Dashboard" ? "dashboard" : res?.label === "Add Users" ? "add-users" : ""}
                                                                    sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: open ? 2 : 0 }}
                                                                    onClick={(e) => (handleMenuClick(res?.label))}
                                                                >
                                                                    <ListItemButton selected={selectedMenu === res?.label} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 2, borderRadius: "8px", fontWeight: 600, }} >
                                                                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'left', }} >
                                                                            <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                                                        </ListItemIcon>
                                                                        <ListItemText primaryTypographyProps={{
                                                                            variant: "body2",
                                                                            fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                                            fontSize: "0.8rem",
                                                                            color: selectedMenu === res?.label ? "primary" : grey[600]
                                                                        }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                                                    </ListItemButton>
                                                                </ListItem>}
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </List>
                                            {/* Tour-step_3 */}
                                            {
                                                atsManageAccess &&
                                                <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pl: open ? 2 : 0 }}>
                                                    <ListItemButton sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, borderRadius: "8px", fontWeight: 600 }} onClick={() => setAtsManageCollapse()} disableRipple selected={atsManageCollapse}
                                                        data-testid="manage-recruiter"
                                                    >
                                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                                            <span style={{ color: atsManageCollapse && `${theme?.palette?.primary?.main}` }}><ManageAccountsIcon fontSize='small' /></span>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{
                                                            variant: "body2",
                                                            fontWeight: selectedMenu === "Manage" ? 600 : 550,
                                                            fontSize: "0.8rem",
                                                            color: selectedMenu === "Manage" ? "primary" : grey[600]
                                                        }} primary="Manage" sx={{ opacity: open ? 1 : 0 }}
                                                        />
                                                        {open && atsManageCollapse ? <ExpandLess /> : open && <ExpandMore />}
                                                    </ListItemButton>
                                                </ListItem>
                                            }
                                            <Collapse in={atsManageCollapse} timeout="auto" unmountOnExit>
                                                <List sx={{ padding: "0px" }}>
                                                    {
                                                        atsSubMenu.map((res, i) => {
                                                            return (
                                                                <Tooltip key={i} title={res.tooltip} placement="right-end">
                                                                    <Link key={i} to={res?.route}>
                                                                        {/* Tour step_3&4 */}
                                                                        {res?.show && <ListItem
                                                                            disablePadding
                                                                            data-testid={res?.label === "Client" ? "manage-company" : res?.label === "Job" ? "manage-job" : ""}
                                                                            sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: open ? 4 : 0 }}
                                                                            onClick={(e) => (handleMenuClick(res?.label))}
                                                                        >
                                                                            <ListItemButton key={i} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 2, borderRadius: "8px", fontWeight: 600 }} selected={selectedMenu === res?.label}>
                                                                                <ListItemIcon key={i} sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'center', }} >
                                                                                    <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                                                                </ListItemIcon>
                                                                                <ListItemText key={i} primaryTypographyProps={{
                                                                                    variant: "body2",
                                                                                    fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                                                    fontSize: "0.8rem",
                                                                                    color: selectedMenu === res?.label ? "primary" : grey[600]
                                                                                }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />

                                                                            </ListItemButton>
                                                                        </ListItem>}
                                                                    </Link>
                                                                </Tooltip >
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </Collapse>
                                            {
                                                atsReportsAccess &&
                                                <>
                                                    <ListItem disablePadding sx={{ display: 'block', fontSize: "12px", pl: open ? 2 : 0 }}>
                                                        <ListItemButton key={"report-button"} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, borderRadius: "8px", fontWeight: 600 }} onClick={() => setReportCollapse()} disableRipple selected={atsReportCollapse}
                                                            data-testid="manage-reports"
                                                        >
                                                            <ListItemIcon key={"report-icon"} sx={{ minWidth: "35px" }}>
                                                                <span style={{ color: atsReportCollapse && `${theme?.palette?.primary?.main}` }}><AssessmentIcon fontSize='small' /></span>
                                                            </ListItemIcon>
                                                            <ListItemText key={"report-text"} primaryTypographyProps={{
                                                                variant: "body2",
                                                                fontSize: "0.8rem",
                                                            }} primary="Reports" sx={{ opacity: open ? 1 : 0 }}
                                                            />
                                                            {open && atsReportCollapse ? <ExpandLess /> : open && <ExpandMore />}
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Collapse in={atsReportCollapse} timeout="auto" unmountOnExit>
                                                        {atsReportMenu.map((res, i) => {
                                                            return (
                                                                <Tooltip key={i} title={res?.tooltip} placement="right-end">
                                                                    <Link key={i} to={res?.route}>
                                                                        <List sx={{ padding: "0px" }}>
                                                                            {res?.show && <ListItem
                                                                                disablePadding
                                                                                data-testid="Reports"
                                                                                sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: open ? 4 : 0 }}
                                                                                onClick={(e) => (handleMenuClick(res?.label))}
                                                                            >
                                                                                <ListItemButton key={"reports-button"} selected={selectedMenu === res?.label} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 2, borderRadius: "8px", fontWeight: 600, }} >
                                                                                    <ListItemIcon key={"reports-icon"} sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'left', }} >
                                                                                        <span style={{ color: selectedMenu === res?.label && `${theme?.palette?.primary?.main}` }}>{res?.icon}</span>
                                                                                    </ListItemIcon>
                                                                                    <ListItemText key={"reports-text"} primaryTypographyProps={{
                                                                                        variant: "body2",
                                                                                        fontWeight: selectedMenu === res?.label ? 600 : 550,
                                                                                        fontSize: "0.8rem",
                                                                                        color: selectedMenu === res?.label ? "primary" : grey[600]
                                                                                    }} primary={res?.label} sx={{ opacity: open ? 1 : 0 }} />
                                                                                </ListItemButton>
                                                                            </ListItem>}
                                                                        </List>
                                                                    </Link>
                                                                </Tooltip>
                                                            )
                                                        })}
                                                    </Collapse>
                                                </>}
                                            {
                                                atsInvoiceAccess &&
                                                <Tooltip key={"Invoice"} title={"Manage Invoice"} placement="right-end">
                                                    <Link to={"/ats/invoice"}>
                                                        <List sx={{ padding: "0px" }}>
                                                            <ListItem
                                                                key={"Invoice-item"}
                                                                disablePadding
                                                                data-testid="Invoice"
                                                                sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: open ? 2 : 0 }}
                                                                onClick={(e) => (handleMenuClick("Invoice"))}
                                                            >
                                                                <ListItemButton key={"Invoice-button"} selected={selectedMenu === "Invoice"} sx={{ "&.Mui-selected:hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, ":hover": { backgroundColor: "rgb(254 255 187 / 48%)" }, minHeight: 10, justifyContent: open ? 'initial' : 'center', px: 2, borderRadius: "8px", fontWeight: 600, }} >
                                                                    <ListItemIcon key={"Invoice-icon"} sx={{ minWidth: 0, mr: open ? 2 : 'auto', justifyContent: 'left', }} >
                                                                        <span style={{ color: selectedMenu === "Invoice" && `${theme?.palette?.primary?.main}` }}><ReceiptIcon fontSize='small' /></span>
                                                                    </ListItemIcon>
                                                                    <ListItemText key={"Invoice-text"} primaryTypographyProps={{
                                                                        variant: "body2",
                                                                        fontWeight: selectedMenu === "Invoice" ? 600 : 550,
                                                                        fontSize: "0.8rem",
                                                                        color: selectedMenu === "Invoice" ? "primary" : grey[600]
                                                                    }} primary={"Invoice"} sx={{ opacity: open ? 1 : 0 }} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        </List>
                                                    </Link>
                                                </Tooltip>
                                            }
                                            {
                                                // !userInfo?.is_ats_user && access?.length > 0 && access?.find(res => res?.module === "Onboarding")?.submodules?.[0]?.permission !== "XXXX" &&
                                                atsOnboardingAccess &&
                                                <Link to="/onboarding">
                                                    <ListItem
                                                        key={"onboarding-item"}
                                                        disablePadding
                                                        sx={{ display: 'block', fontSize: "12px", marginLeft: `${isMobile ? "-4px" : ''}`, pl: open ? 2 : 0 }}
                                                        onClick={(e) => (handleMenuClick("Employee Onboarding"))}
                                                    >
                                                        <ListItemButton
                                                            key={"onboarding-button"}
                                                            selected={selectedMenu === "Employee Onboarding"}
                                                            sx={{
                                                                minHeight: 10,
                                                                justifyContent: open ? 'initial' : 'center',
                                                                borderRadius: "8px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <ListItemIcon
                                                                key={"onboarding-button"}
                                                                sx={{
                                                                    minWidth: 0,
                                                                    mr: open ? 2 : 'auto',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <span style={{ color: selectedMenu === "Employee Onboarding" && `${theme?.palette?.primary?.main}` }}><PersonAddIcon fontSize='small' /></span>
                                                            </ListItemIcon>
                                                            <ListItemText key={"onboarding-text"}
                                                                primaryTypographyProps={{
                                                                    variant: "body2",
                                                                    fontWeight: selectedMenu === "Employee Onboarding" ? 600 : 550,
                                                                    fontSize: "0.8rem",
                                                                    color: selectedMenu === "Employee Onboarding" ? "primary" : grey[600]
                                                                }} primary="Employee Onboarding" sx={{ opacity: open ? 1 : 0 }} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Link>
                                            }
                                        </Collapse >
                                    </>
                                </React.Fragment>
                            )
                        })
                    }
                </List >
            </Collapse >
        </React.Fragment>
    );
}