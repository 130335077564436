import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  AddAtsInterviewDetaiilsAction,
  UpdateAtsInterviewDetaiilsAction,
  DeleteAtsInterviewDetaiilsAction,
} from "../../../../../store/ATS/InterviewDetails/Action";
import { GetAtsJobPostAction } from "../../../../../store/ATS/JobPost/Action";
import CommonDateTimePicker from "../../../Base/views/CommonDateTimePicker";
import EditIcon from "@mui/icons-material/Edit";
import { gapi } from "gapi-script";
import "./calendarstyle.css";
import { Mention, MentionsInput } from "react-mentions";
import { GetAtsUsersEmailHelper } from "../../helper/AtsUserHelper";
import MentionInputStyles from "./MentionStyles/MentionInputStyles";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";

const InterviewScheduleForm = (props) => {
  const {
    currentData,
    setOpenPopup,
    date,
    getDashboard,
    gapiInitialized,
    orgname,
    jobdata,
    title,
  } = props;
  const dispatch = useDispatch();
  const initialFValues = {
    candidate_job_id: "",
    ats_jobpost_id: "",
    start_date: "",
    end_date: "",
    postponed_date: "",
    venue: "",
    modeof_interview: "",
    scheduled_by: "",
    interviewer: "",
    comment: "",
    description: "",
    event_name: jobdata ? `Schedule Interview for ${jobdata?.title} Role` : "",
  };
  const [mentionTo, setMentionTo] = useState(jobdata ? jobdata?.email_id : "");
  const [eventDetails, setEventDetails] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [mentionUsers, setMentionUsers] = useState([]);
  const [disableEdit, setdisableEdit] = useState(
    currentData?.ats_interview_details_id ? false : true
  );
  const [errors, setErrors] = useState({});
  const [inputData, setInputData] = useState(initialFValues);
  const [openDialog, setOpenDialog] = useState(false);
  const formatDate = (dateObj) => {
    const optionsDate = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric"
    };
  
    const optionsDateTime = {
      ...optionsDate,
      hour: "numeric",
      minute: "numeric"
    };
    if (dateObj.dateTime) {
      return new Date(dateObj.dateTime).toLocaleString("en-US", optionsDateTime);
    }
    if (dateObj.date) {
      return new Date(dateObj.date).toLocaleString("en-US", optionsDate);
    }
    return "";
  };
  
  useEffect(() => {
    if (currentData?.ats_interview_details_id) {  
      setInputData({
        ...currentData,
        ats_jobpost_id: currentData?.ats_jobpost_id
          ? {
              ats_jobpost_id: currentData?.ats_jobpost_id,
              title: currentData?.ats_jobpost,
            }
          : null,
        candidate_job_id: currentData?.candidate_job_id
          ? {
              candidate_job_id: currentData?.candidate_job_id,
              first_name: currentData?.first_name,
            }
          : null,
        start_date: new Date(currentData?.start_date),
        end_date: new Date(currentData?.end_date),
        description: currentData?.event_description,
      });
      setMentionTo(currentData?.candidate_email);
      getEventDetails(currentData?.response_id);
    } else {
      setInputData({ ...initialFValues, start_date: new Date(date), end_date: new Date(date + 30 * 60000) });
    }
    dispatch(GetAtsJobPostAction());
    GetAtsUsersEmailHelper().then((res) => {
      let arr = [];
      res?.data?.data?.forEach((temp) =>
        arr.push({ ...temp, id: temp?.email_id, display: temp?.email_id })
      );
      setMentionUsers(arr);
    });
    //eslint-disable-next-line
  }, []);
  const handleEdit = () => {
    setdisableEdit(true);
  };
  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };
  const handleConfirmDelete = (event) => {
    event.stopPropagation();
    if (!gapiInitialized) {
      console.error("GAPI is not initialized");
      return;
    }
    gapi.client.calendar.events
      .delete({
        calendarId: "primary",
        eventId: currentData?.response_id,
      })
      .then(() => {
        dispatch(
          DeleteAtsInterviewDetaiilsAction({
            ...inputData,
            month_year: title,
          })
        );
        toast.success("Event deleted successfully!");
      })
      .catch((error) => {
        toast.error("Can't delete event");
      });
    setOpenDialog(false);
    setOpenPopup(false);
    setTimeout(() => {
      getDashboard();
    }, 2000);
  };

  const getEventDetails = (eventId) => {
    gapi.client.calendar.events
      .get({
        calendarId: "primary",
        eventId: eventId,
      })
      .then((response) => {
        const event = response.result;
        setEventDetails(event);
        setStart(formatDate(event?.start));
        setEnd(formatDate(event?.end));
      })
      .catch((error) => {
        console.error("Error retrieving event details: ", error);
        toast.error("Can't retrieve event details");
      });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    if ("mentionTo" in fieldValues) {
      const emails = fieldValues.mentionTo
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email !== "");
      const invalidEmails = emails.filter((email) => !validateEmail(email));

      if (emails.length === 0) {
        temp.mentionTo = "Participants is required";
      } else if (invalidEmails.length > 0) {
        temp.mentionTo = `Invalid email(s): ${invalidEmails.join(", ")}`;
      } else {
        temp.mentionTo = "";
      }
    }
    if ("start_date" in fieldValues) {
      temp.start_date = ["", null].includes(fieldValues.start_date)
        ? "Start Date is required"
        : "";
    }
    if ("end_date" in fieldValues) {
      temp.end_date = ["", null].includes(fieldValues.end_date)
        ? "End Date is required"
        : "";

        if (fieldValues.end_date && fieldValues.start_date) {
          const start = new Date(fieldValues.start_date);
          const end = new Date(fieldValues.end_date);
          if (end < start) {
            temp.end_date = "End Date cannot be earlier than Start Date";
          } else {
            temp.end_date = "";
          }
        }
    }
    if ("event_name" in fieldValues) {
      temp.event_name =
        fieldValues.event_name === "" ? "EventName is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleInputChange = (event, value) => {
    if ("candidate_job_id" === event) {
      setInputData({ ...inputData, [event]: value ? value : "" });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value ? value : "" });
    } else if ("ats_jobpost_id" === event) {
      setInputData({
        ...inputData,
        [event]: value ? value : "",
        candidate_job_id: "",
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value ? value : "" });
    } else if ("start_date" === event) {
      setInputData({ ...inputData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value });
    } else if ("end_date" === event) {
      setInputData({ ...inputData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event]: value });
    } else {
      setInputData({ ...inputData, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputData, [event.target.name]: event.target.value });
    }
  };
  const handleChange = (e, name) => {
    const inputValue = e.target.value;
    const endsWithSpace = inputValue.endsWith(" ");
    if (endsWithSpace) {
      const trimmedValue = inputValue.trim();
      const updatedValue = trimmedValue + ",";
      setMentionTo(updatedValue);
    } else {
      setMentionTo(inputValue);
    }
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    if (validate({ ...inputData, mentionTo })) {
      const processEmails = (emailString) => {
        const emailArray = emailString.split(",");
        const uniqueEmailSet = new Set(emailArray);
        const uniqueEmailArray = Array.from(uniqueEmailSet);
        return uniqueEmailArray.join(",");
      };
      const to = processEmails(mentionTo);
      let emailArray = to.split(",").filter((email) => email.trim() !== "");
      const attendees = emailArray.map((email) => ({
        email: email.trim(),
        responseStatus: "needsAction",
      }));
      if (currentData?.ats_interview_details_id) {
        if (!gapiInitialized) {
          console.error("GAPI is not initialized");
          return;
        }

        const startTime = new Date(inputData.start_date);
        const endTime = new Date(inputData.end_date);
        const event = {
          summary: inputData?.event_name,
          description: inputData?.description,
          start: {
            dateTime: startTime.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end: {
            dateTime: endTime.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          attendees: attendees,
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
          visibility: "default",
          conferenceData: eventDetails?.conferenceData,
        };
        gapi.client.calendar.events
          .update({
            calendarId: "primary",
            eventId: currentData?.response_id,
            resource: event,
            conferenceDataVersion: 1,
            sendUpdates: "all",
          })
          .then((response) => {
            let Startdate = inputData?.start_date;
            let Enddate = inputData?.end_date;
            dispatch(
              UpdateAtsInterviewDetaiilsAction({
                ...inputData,
                start_date: `${
                  Startdate?.getFullYear() +
                  "-" +
                  ("0" + (Startdate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + Startdate.getDate()).slice(-2) +
                  " " +
                  ("0" + Startdate.getHours()).slice(-2) +
                  ":" +
                  ("0" + Startdate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + Startdate.getSeconds()).slice(-2)
                }`,
                end_date: `${
                  Enddate?.getFullYear() +
                  "-" +
                  ("0" + (Enddate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + Enddate.getDate()).slice(-2) +
                  " " +
                  ("0" + Enddate.getHours()).slice(-2) +
                  ":" +
                  ("0" + Enddate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + Enddate.getSeconds()).slice(-2)
                }`,
                candidate_email: to,
              })
            );
            toast.success("Event updated, check your Google Calendar!");
          })
          .catch((error) => {
            toast.error("Can't Update event");
          });
      } else {
        if (!gapiInitialized) {
          console.error("GAPI is not initialized");
          return;
        }

        const startTime = new Date(inputData.start_date);
        const endTime = new Date(inputData?.end_date);
        const event = {
          summary: inputData?.event_name,
          description: inputData?.description,
          start: {
            dateTime: startTime.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          end: {
            dateTime: endTime.toISOString(),
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          attendees: attendees,
          reminders: {
            useDefault: false,
            overrides: [
              { method: "email", minutes: 24 * 60 },
              { method: "popup", minutes: 10 },
            ],
          },
          visibility: "default",
          conferenceData: {
            createRequest: {
              requestId: "sample123",
              conferenceSolutionKey: {
                type: "hangoutsMeet",
              },
              status: {
                statusCode: "pending",
              },
            },
          },
        };
        gapi.client.calendar.events
          .insert({
            calendarId: "primary",
            resource: event,
            conferenceDataVersion: 1,
            sendUpdates: "all",
          })
          .then((response) => {
            const eventId = response.result.id;
            const Status = response.result.status;
            let Startdate = inputData?.start_date;
            let Enddate = inputData?.end_date;
            dispatch(
              AddAtsInterviewDetaiilsAction({
                ...inputData,
                start_date: `${
                  Startdate?.getFullYear() +
                  "-" +
                  ("0" + (Startdate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + Startdate.getDate()).slice(-2) +
                  " " +
                  ("0" + Startdate.getHours()).slice(-2) +
                  ":" +
                  ("0" + Startdate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + Startdate.getSeconds()).slice(-2)
                }`,
                end_date: `${
                  Enddate?.getFullYear() +
                  "-" +
                  ("0" + (Enddate.getMonth() + 1)).slice(-2) +
                  "-" +
                  ("0" + Enddate.getDate()).slice(-2) +
                  " " +
                  ("0" + Enddate.getHours()).slice(-2) +
                  ":" +
                  ("0" + Enddate.getMinutes()).slice(-2) +
                  ":" +
                  ("0" + Enddate.getSeconds()).slice(-2)
                }`,
                candidate_email: to,
                response_id: eventId,
                status: Status
              })
            );
            toast.success("Event created, check your Google Calendar!");
          })
          .catch((error) => {
            toast.error("Can't Create event");
          });
      }
      resetForm();
      setTimeout(() => {
        !jobdata && getDashboard();
      }, 2000);
    }
  };

  const resetForm = () => {
    setInputData(initialFValues);
    setErrors({});
    setOpenPopup(false);
  };

  return (
    <>
      <>
        <ConfirmationDialog
          title="Delete Event"
          content="Are you sure want to delete?"
          openDialog={openDialog}
          closeDialog={setOpenDialog}
          popupTitle="Delete Event"
          OkButtonText="Yes"
          onSubmit={handleConfirmDelete}
          reason={true}
          handleInputChange={handleInputChange}
          inputData={inputData}
        />
        <Grid container spacing={2}>
          {disableEdit && (
            <>
              <Grid key="event_name" item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    value={inputData.event_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="event_name"
                    label="Event Name"
                    name="event_name"
                    autoFocus={true}
                    autoComplete="event_name"
                    {...(errors.event_name && {
                      error: true,
                      helperText: errors.event_name,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid key="participants" item xs={12} md={12}>
                <div
                  style={{
                    position: "relative",
                    zIndex: 2,
                    margin: "1px 0px 0px 3px",
                  }}
                >
                  <MentionsInput
                    fullWidth
                    style={MentionInputStyles}
                    value={mentionTo}
                    name="mentionTo"
                    onChange={(e, name) => handleChange(e, "mentionTo")}
                    placeholder={"Participants"}
                    autoFocus={false}
                  >
                    <Mention
                      markup="__id__,"
                      displayTransform={(email_id) => `${email_id}, `}
                      data={mentionUsers}
                      displayName="email_id"
                    />
                  </MentionsInput>
                  <Typography color="error">{errors.mentionTo}</Typography>
                </div>
              </Grid>
              <Grid key="start_date" item xs={12} md={12}>
                <div style={{ position: "relative", zIndex: 1 }}>
                  <FormControl fullWidth>
                    <CommonDateTimePicker
                      size="small"
                      name="start_date"
                      label="Start Date"
                      pickerType="datetime"
                      pickerFormat="dd/MM/yyyy hh:mm a"
                      DefaultDTvalue={inputData?.start_date}
                      minDate={new Date()}
                      handleChange={(value) =>
                        handleInputChange("start_date", value)
                      }
                      {...(errors.start_date && {
                        error: true,
                        helperText: errors.start_date,
                      })}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid key="end_date" item xs={12} md={12}>
                <div style={{ position: "relative", zIndex: 1 }}>
                  <FormControl fullWidth>
                    <CommonDateTimePicker
                      size="small"
                      name="end_date"
                      label="End Date"
                      pickerType="datetime"
                      minDate={new Date()}
                      pickerFormat="dd/MM/yyyy hh:mm a"
                      DefaultDTvalue={inputData?.end_date}
                      handleChange={(value) =>
                        handleInputChange("end_date", value)
                      }
                      {...(errors.end_date && {
                        error: true,
                        helperText: errors.end_date,
                      })}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid key="description" item xs={12} md={12}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    rows={4}
                    size="small"
                    value={inputData.description}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete="description"
                  />
                </FormControl>
              </Grid>
            </>
          )}

          {currentData?.ats_interview_details_id && (
            <>
              <Grid
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                container
              >
                <Typography sx={{ margin: "15px 15px 0px 15px" }} variant="h5">
                  Event Details
                </Typography>
                {!disableEdit && (
                  <EditIcon
                    onClick={(e) => handleEdit(e)}
                    sx={{ margin: "15px 15px 0px 15px", cursor: "pointer" }}
                    fontSize="small"
                  />
                )}
              </Grid>
              <Grid
                sx={{
                  border: "1px solid black",
                  marginLeft: "15px",
                  marginTop: "10px",
                  padding: "10px",
                  borderRadius: "8px",
                }}
                display="flex"
                container
              >
                <Typography sx={{ padding: "10px 10px 10px 0px" }} variant="h5">
                  {eventDetails?.summary}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="p">
                      {eventDetails?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <a
                      href={eventDetails?.hangoutLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="join_meet">
                        Join with Google Meet
                      </button>
                    </a>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="p">
                      <strong>When</strong>
                      <br></br>
                      <br></br>
                      {start} – {end} {eventDetails?.start?.timeZone ? `(${eventDetails.start.timeZone})` : ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <strong>Meeting link</strong>
                    <br></br>
                    <br></br>
                    <a
                      href={eventDetails?.hangoutLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography variant="p">
                        {eventDetails?.hangoutLink}
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography variant="p">
                      <strong>Organizer</strong>
                      <br></br>
                      <br></br>
                      {orgname}
                      <br></br>
                      {eventDetails?.organizer?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <button onClick={handleDeleteClick} className="delete_meet">
                      Delete Event
                    </button>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="p">
                      <strong>Guest</strong>
                      <br></br>
                      <br></br>
                      {eventDetails?.attendees?.map((guest, index) => (
                        <React.Fragment key={index}>
                          {guest.email} -{" "}
                          {guest.responseStatus === "accepted"
                            ? "Accepted"
                            : guest.responseStatus === "declined"
                            ? "Declined"
                            : guest.responseStatus === "tentative"
                            ? "Tentative"
                            : "No response"}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {disableEdit && (
            <Grid display="flex" justifyContent="flex-end" container>
              <Button
                className="mpl-secondary-btn"
                sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                variant="outlined"
                onClick={() => setOpenPopup(false)}
              >
                cancel
              </Button>
              <Button
                onClick={HandleSubmit}
                variant="contained"
                color="primary"
                type="submit"
                className="mpl-primary-btn"
                sx={{ marginTop: "1.75rem" }}
              >
                {currentData?.ats_interview_details_id
                  ? "Update"
                  : "Send Invite"}
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    </>
  );
};

export default InterviewScheduleForm;
