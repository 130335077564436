import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import YearType from '../../../Leave/views/YearType/YearType';
import MyTime from '../../../Leave/views/MyTime/MyTime';
import { useTheme } from "@mui/material/styles";
import CompanyDetail from '../../../Ancillary/CompanyDetails/views/CompanyDetail';

const Others = ({ accordian }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState(accordian);
    const { leaveYearTypeData } = useSelector(state => state?.LeaveReducer?.LeaveYearTypeReducer);
    const { mytime_options } = useSelector(state => state.LeaveReducer?.MyTimeConfigureReducer);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const values = [
        { name: "Company Logo", comp: <CompanyDetail />},
        { name: "Business Year Type", comp: <YearType />, count: leaveYearTypeData?.length || 0 },
        { name: "Projects", comp: <MyTime />, count: mytime_options?.length || 0 },
    ]

    const actionSummaryStyle = {
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        // backgroundColor: '#a5a5a5',
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
            backgroundColor: theme?.palette?.primary?.dark,
            color: "white",
        }
    }

    return (
        <div style={{ padding: "10px 20px" }}>
            {values.map((value, id) => {
                return (
                    <Accordion key={id} expanded={expanded === `${value.name}`} onChange={handleChange(`${value.name}`)} sx={{ borderRadius: "10px" }}>
                        <AccordionSummary sx={actionSummaryStyle}
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                        >
                            <Typography sx={{color:theme.palette.primary.contrastText}} variant='body1' fontWeight={600}>{value.name} {value.count && `(${value.count})`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {value.comp}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}

export default Others;
