// ** React Imports
import { Fragment, useState } from 'react'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import DescriptionIcon from '@mui/icons-material/Description';
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { makeStyles } from "@mui/styles";
import { useDropzone } from 'react-dropzone'
import { convertBase64, getUserData, } from '../../../Base/helper/baseFunctions';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { CircularProgress, FormControl, FormLabel } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { UploadResumeAction } from '../../../../../store/ATS/Resume/Action';
import Warning from "../../images/report.png";
import Popup from "../../../Base/views/Popup";


const uploadButtonStyles = {
  display: "inline-block",
  padding: "10px 20px",
  backgroundColor: "#8b44ff",
  color: "#fff",
  borderRadius: "5px",
  cursor: "pointer",
  marginRight: "10px",
};

const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const ResumeUploader = ({ id}) => {
  const useStyles = makeStyles({
    files: {
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '6px',
      padding: '0.625rem 0.6rem 0.625rem 1.5rem',
      border: '1px solid rgba(93, 89, 98, 0.14)',
      marginTop: '1.5625rem'
    },
    button: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1.5625rem',
      paddingBottom: '1.25rem'
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isParsing} = useSelector(state => state.AtsReducer?.AtsPythonApiReducer)
  const { isUploading } = useSelector(state => state.AtsReducer?.AtsUploadReducer)
  const [files, setFiles] = useState([])
  const [show, setShow] = useState(false);
  const userData = getUserData();

  const handleUpload = async () => {
    setFiles([])
    let b64Files = []
    let fileNames = []
    let contentTypes = []
    let b64string = ""
    let filename = ""
    let content_type = ""
    for (let i = 0; i < files.length; i++) {
      b64string = await convertBase64(files[i])
      let ext = files?.[i]?.name?.split(".").at(-1)
      content_type = content_type = ext === "pdf" ? "application/pdf" : "application/msword"
      filename = `${userData?.domain}/Staging/ATS/Resumes/` + uuidv4() + "." + ext
      b64Files.push(b64string)
      fileNames.push(filename)
      contentTypes.push(content_type)
    }

    const data = {
      files: fileNames,
      workspace_name: [userData?.domain],
      user_id: [userData?.employee_id],
      job_id: [id],
      consultancy_id: [userData?.user_type === "Consultancy" ? userData?.consultancy_id : "0"]
    };
    dispatch(UploadResumeAction({ b64Files, fileNames, data, contentTypes }, dispatch))
    (!isUploading && !isParsing) && setFiles([])
    
  }
  
  const handleFolderChange = async (event) => {

    setFiles([]);
    event.preventDefault();
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);
    filesArray?.forEach((file) => {
      if (
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setFiles((prevFiles) => [...prevFiles, file]);
      } else {
       
        setShow(true);
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: isParsing,
    maxFiles: 15,
    maxSize: 20000000,
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        if(!id) {
          if (file.type === 'application/pdf' ||
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          setFiles(files => [...files, Object.assign(file)])
        }
        else {
         
          setShow(true);
        }
        }
        else{
          if (file.type === 'application/pdf' ||
          file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          
            setFiles(acceptedFiles.map(file => Object.assign(file)));
        }
        else {
        
          setShow(true);
        }
        }
        
      });
    },
    onDropRejected: () => {
      toast.error('Limit exceeded', {
        duration: 2000
      })
    }
  })
  const handleRemoveFile = file => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter(i => i.name !== file.name)
    setFiles([...filtered])
  }
  const handleRemoveAllFiles = () => {
    setFiles([])
  }
  const HandleClose = () => {
    setShow(false);
    setFiles([]);
  };
  return (
    <Fragment>
    <Popup
        maxWidth="xs"
        openPopup={show}
        setOpenPopup={setShow}
        close={false}
        hideDividers={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Warning} alt="UnSupport File" width={"25%"} />
          </Box>
          <FormControl>
            <FormLabel id="gender-label">
              <Typography variant="h5" className="blink">
              Invalid File Type, Accepted file types are pdf and Docx
              </Typography>
            </FormLabel>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Button
              onClick={HandleClose}
              variant="contained"
              type="submit"
              className="mpl-primary-btn profile-btn"
              size="small"
              sx={{
                margin: "10px",
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Popup>
      {
        (isParsing || isUploading) &&
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '10px'
        }}>
          <span>Your files are uploading, we'll notify you once it's completed.</span>
          <CircularProgress />
        </div>
      }
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], alignItems: 'center', justifyContent: 'center' }}>
          <Img alt='Upload img' src='https://demos.themeselection.com//marketplace/materio-mui-react-nextjs-admin-template/demo-2/images/misc/upload.png' />
          <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
            <HeadingTypography variant='h5'>Drop files here or click to upload.</HeadingTypography>
            <Typography color='textSecondary' sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}>
              Drop files here or click{' '}
              <a href='/' onClick={e => e.preventDefault()}>
                browse
              </a>{' '}
              through your machine
            </Typography>
          </Box>
        </Box>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "column", "row"],
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <input
          type="file"
          id="folderInput"
          webkitdirectory=""
          directory=""
          onChange={handleFolderChange}
          style={{ display: "none" }}
        />
        <label htmlFor="folderInput" style={uploadButtonStyles}>
          Upload Folder
        </label>
        </Box>
      {files.length ? (
        <Fragment>
          <Typography sx={{ display: 'flex', justifyContent: 'end', pr: '25px', pt: '26px', fontSize: '1.1rem', fontWeight: 700, color: 'black' }}>No of resumes : {files.length}</Typography>
          <List>
            {files.map(file => (
              <ListItem className={classes.files} key={file.name}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center'
                }} >
                  <div><DescriptionIcon /></div>
                  <div style={{ marginLeft: '5px' }}>
                    <Typography >{file.name}</Typography>
                    <Typography variant='body2'>
                      {Math.round(file.size / 100) / 10 > 1000
                        ? (Math.round(file.size / 100) / 10000).toFixed(1) + 'mb'
                        : (Math.round(file.size / 100) / 10).toFixed(1) + 'kb'}
                    </Typography>
                  </div>
                </div>
                <IconButton onClick={() => handleRemoveFile(file)}>
                  <CloseSharpIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <div className={classes.button}>
            <Button style={{ marginRight: '0.85rem' }} color='error' variant='outlined' onClick={handleRemoveAllFiles}>
              Remove All
            </Button>
            <Button variant='contained' onClick={handleUpload}>Upload Files</Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  )
}
export default ResumeUploader
