/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, Grid, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import FileUploader from "../../../ATS/views/ATSCandidate/FileUploader";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { convertBase64, getUserData, s3Client, } from "../../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { toast } from "react-toastify";
import CommonDatePicker from '../../../Base/views/CommonDatePicker';
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { useDispatch, useSelector } from "react-redux";
import { AddEmployeePersonalAction, UpdateEmployeePersonalAction } from '../../../../../store/Employee/Personal/Action';
import { GetProofTypeAction } from '../../../../../store/Ancillary/ProofType/Action';

const IdentityProofModal = ({
    editedRecord,
    setOpenPopup,
    addMode,
}) => {
    const dispatch = useDispatch();
    const initialValues = {
        proof_type: "",
        name: "",
        number: "",
        surname: "",
        givenname: "",
        issued_place: "",
        expiry_date: null,
        proof: "",
        domain: editedRecord?.domain,
        employee_id: editedRecord?.employee_id,
        emp_idproof_id: editedRecord?.emp_idproof_id,
    }
    const [errors, setErrors] = useState({});
    const userData = getUserData();
    const [proofData, setProofData] = useState({ load: true, values: [] });
    const [files, setFiles] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [identityData, setIdentityData] = useState(initialValues);
    const { proof_type_data } = useSelector(state => state?.AncillaryReducer?.ProofTypeReducer)
    useEffect(() => {
        dispatch(GetProofTypeAction())
        if (editedRecord) {
            setIdentityData({
                ...editedRecord, proof_type: editedRecord?.identity_id ? {
                    identity_id: editedRecord?.identity_id, name: editedRecord?.identity_name
                } : ""
            });
        } else {
            setIdentityData({ ...editedRecord });
        }
    }, [editedRecord]);
    useEffect(() => {
        identityData?.proof && getResumeSignedUrl();
    }, [identityData]);

    const validate = (fieldValues) => {
        let temp = { ...errors };

        if ("proof_type" in fieldValues) {
            temp.proof_type = fieldValues.proof_type === "" ? "Proof Type is required" : "";
        }
        if ('name' in fieldValues) {
            temp.name = fieldValues.name === "" ? "Name is required" :
                !/^[a-zA-Z\s]+$/.test(fieldValues.name) ? "Name must contain only letters" : "";
        }
        if ('number' in fieldValues) {
            const proofType = identityData?.proof_type;
            const number = fieldValues.number;
            temp.number = number === "" ? "Number is required" : "";

            if (proof_type_data?.length > 0) {
                const matchingProof = proof_type_data.find(
                    (item) => item.identity_id === proofType?.identity_id
                );

                
                if (matchingProof && matchingProof.identity_length) {
                    const length = matchingProof.identity_length;
                    const isAlphaNumeric = /^[a-zA-Z0-9]+$/.test(number);
                    temp.number = !isAlphaNumeric 
                        ? "Must be alphanumeric" 
                        : number.length !== length 
                        ? `Must be ${length} characters` 
                        : "";
                }
            }
        }
        if (identityData?.proof_type?.document_required || editedRecord?.proof) {
            if (!files?.length && !fileUrl) {
                temp.proof = "Proof upload is required";
            } else {
                temp.proof = "";
            }
        } else {
            temp.proof = "";
        }

        setErrors({
            ...temp,
            number: temp.number === false ? "" : temp.number
        });
        const temp1 = { ...temp, number: temp.number === false ? "" : temp.number };
        return Object.values(temp1).every((x) => x === "");
    };
    const fileUploaded = ()=>{
        let temp = { ...errors };
        temp.proof = "";
        setErrors({
            ...temp,
        });
    }

    const handleInputChange = (event, value) => {
        if (["proof_type"].includes(event)) {
            if (value) {
                setIdentityData({ ...initialValues, [event]: value });
                setErrors({});
            } else {
                setIdentityData({ [event]: "" });
                setErrors({});
            }
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...identityData, [event]: value ? value : "" })
        } else {
            setIdentityData({ ...identityData, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...identityData, [event.target.name]: event.target.value })
        }
    }
    const shouldRenderFileUploader = React.useMemo(() => 
        identityData?.proof_type?.document_required || editedRecord?.proof, 
        [identityData?.proof_type?.document_required, editedRecord?.proof]
      );

    const getResumeSignedUrl = async () => {
        let filename = identityData?.proof;
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: filename,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          );
          setFileUrl(url);
          return url;
        } catch (err) {
          toast.error(err);
        }
      };
      const handleDownloadResume = async (event, data) => {
        event.stopPropagation();
        let filename = data?.proof;
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: filename,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          );
          window.open(url, "_blank");
          return url;
        } catch (err) {
          toast.error(err);
        }
      };
    //   const resetForm = () => {
    //     setIdentityData(initialValues);
    //     setErrors({});
    //   };
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate(identityData)) {
            let b64string = "";
            let filename = "";
            let old_proof = "";
            let content_type = "";
            if (files?.[0]) {
                b64string = await convertBase64(files?.[0]);
                let ext = files?.[0]?.name?.split(".").at(-1);
                content_type = ext === "pdf" ? "application/pdf" : "application/msword"
                filename = `${userData?.domain}/Employee/IdentityProof/` + uuidv4() + "." + ext;
                if (identityData?.proof) {
                    old_proof = identityData?.proof;
                }
            } else {
                filename = identityData?.proof;
            }
            if (identityData?.emp_idproof_id) {
                dispatch(UpdateEmployeePersonalAction({
                    ...identityData,
                    proof: filename,
                    proof_name: b64string,
                    old_proof: old_proof,
                    content_type: content_type,
                }))
            } else {
                dispatch(AddEmployeePersonalAction(
                    {
                        ...identityData,
                        proof: filename,
                        proof_name: b64string,
                        content_type: content_type,
                    }))
            }
            setOpenPopup(false)
        }
    };

    return (
        <Grid container>
            <Grid container>
                <Grid key="proof_type" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                    <FormControl fullWidth >
                        <SelectableSearch
                            label="Proof Type"
                            fullWidth
                            value={identityData?.proof_type}
                            loading={() => setProofData({ load: false, values: proof_type_data })}
                            fieldLabel="name"
                            // autoFocus={true}
                            required
                            variant={"outlined"}
                            name="proof_type"
                            size="small"
                            onChangeEvent={handleInputChange}
                            data={proofData}
                            {...(errors.proof_type && {
                                error: true,
                                helperText: errors.proof_type,
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid key="name" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                    <FormControl fullWidth >
                        <TextField
                            size="small"
                            value={identityData?.name || ""}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            required
                            id="name"
                            label="Name"
                            name="name"
                            {...(errors.name && {
                                error: true,
                                helperText: errors.name,
                            })}
                        />
                    </FormControl>
                </Grid>
                <Grid key="number" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                    <FormControl fullWidth >
                        <TextField
                            size="small"
                            value={identityData?.number || ""}
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            fullWidth
                            id="number"
                            label="Number"
                            name="number"
                            onKeyDown={(e) => e.keyCode === 32 && e.preventDefault()}
                            {...(errors.number && {
                                error: true,
                                helperText: errors.number,
                            })}
                        />
                    </FormControl>
                </Grid>
                {(shouldRenderFileUploader) && (
                <Grid key="number" item xs={12} md={5} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                    <Grid onChange={fileUploaded} sx={{ textAlign:"center", border: "2px", padding: "20px", borderRadius: "10px", borderStyle: "dashed", backgroundColor: "primary", borderColor: grey[500] }}>
                        <FileUploader
                            fileUrl={fileUrl}
                            setFileUrl={setFileUrl}
                            files={files}
                            setFiles={setFiles}
                            filename={`IdentityProof_${APP_NAME}`}
                            filepath={identityData?.proof}
                            handleDownloadResume={handleDownloadResume}
                        />
                    </Grid>
                    {errors.proof&&<div style={{color:'red', paddingTop:'5px'}}>{errors.proof}</div>}
                </Grid>)}
                {
                    identityData?.proof_type?.id === 3 &&
                    <>
                        <Grid key="surname" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={identityData?.surname || ""}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    id="surname"
                                    label="Surname"
                                    name="surname"
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="givenname" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={identityData?.givenname || ""}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    id="givenname"
                                    label="Given Name"
                                    name="givenname"
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="issued_place" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <TextField
                                    size="small"
                                    value={identityData?.issued_place || ""}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    fullWidth
                                    id="issued_place"
                                    label="Issued Place"
                                    name="issued_place"
                                />
                            </FormControl>
                        </Grid>
                        <Grid key="expiry_date" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <CommonDatePicker
                                    size="small"
                                    name="expiry_date"
                                    minDate={new Date()}
                                    label="Expiry date"
                                    pickerType="date"
                                    pickerFormat="dd/MM/yyyy"
                                    DefaultDTvalue={identityData?.expiry_date}
                                    handleChange={(value) => handleInputChange({ target: { name: "expiry_date", value: value ? `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` : null } })}
                                />
                            </FormControl>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container sx={{
                display: "flex",
                justifyContent: "flex-end",
            }}>
                <Button
                    sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                    variant="outlined"
                    className="mpl-secondary-btn"
                    onClick={() => setOpenPopup(false)}
                >
                    cancel
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary" className="mpl-primary-btn" type="submit" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                    {editedRecord?.emp_idproof_id ? "Update" : "Save"}
                </Button>
            </Grid>
        </Grid>
    );
};

export default IdentityProofModal;