import UnauthorizedBase from "../../Base/views/UnauthorizedBase";
import {
  TextField,
  FormControl,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  InputAdornment,
  Link,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AdminSigninHelper } from "../../SignIn/helper/AdminSigninHelper";
import RequestSvg from "../../../../svg/RequestSvg";
import history from "../../../../history.js";

const MeeplAdminSiginPage = () => {
  const initialValues = {
    emailid: "",
    password: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [signUpData, setSignUpData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = (e, value) => {
    if (e === "teamsize") {
      if (value) {
        setSignUpData({ ...signUpData, [e]: value });
      } else setSignUpData({ ...signUpData, [e]: "" });
    } else setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  };

  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("emailid" in fieldValues) {
      temp.emailid =
        fieldValues.emailid === ""
          ? "Email is required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
            fieldValues.emailid
          )
            ? ""
            : "Invalid Email.";
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password === "" ? "Password is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
  const handleSubmit = () => {
    if (validate(signUpData)) {
      AdminSigninHelper({
        emailid: signUpData?.emailid,
        password: signUpData?.password,
      }).then((res) => {
        setSignUpData(initialValues);
      });
    }
  };
  const handleForgotPasswordClick = () => {
    history.push({
      pathname: "/forgotPassword",
      state: { defaultValue: "meepladmin" },
    });
  };

  return (
    <>
      <UnauthorizedBase>
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <Card
            sx={{
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
              p: 5,
              width: "28rem",
            }}
          >
            <CardMedia sx={{ p: 2, textAlign: "center" }}>
              <div style={{ marginBottom: "40px", height: "126px" }}>
                <RequestSvg />
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h3">Admin</Typography>
              </Box>
            </CardMedia>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    sx={{ mt: 2 }}
                    type="email"
                    required
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter the Email"
                    autoFocus={true}
                    label="Email"
                    name="emailid"
                    autoComplete="emailid"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={signUpData.emailid}
                    {...(errors.emailid && {
                      error: true,
                      helperText: errors.emailid,
                    })}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    sx={{
                      mt: 2,
                    }}
                    autoFocus={false}
                    InputLabelProps={{ shrink: true }}
                    label="Password"
                    placeholder="Enter the Password"
                    type={showPassword ? "text" : "password"}
                    required
                    onKeyDown={handleKeyDown}
                    name="password"
                    autoComplete="password"
                    {...(errors.password && {
                      error: true,
                      helperText: errors.password,
                    })}
                    onChange={handleChange}
                    value={signUpData.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            tabIndex={-1}
                            aria-label="toggle password visibility"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Link
                    className="forget-sec"
                    onClick={handleForgotPasswordClick}
                    variant="body2"
                    sx={{
                      cursor: "pointer",
                      fontSize: "15px",
                      color: "black",
                      paddingTop: "10px",
                    }}
                  >
                    Forgot Password
                  </Link>
                </Box>
                <Button
                  className="mpl-primary-btn"
                  variant="contained"
                  sx={{ mt: 3, height: "3rem" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </UnauthorizedBase>
    </>
  );
};

export default MeeplAdminSiginPage;
