import { Autocomplete, Box, Button, Divider, FormControl, IconButton, TextField, Tooltip, Typography } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsCandidateAction, ProposeCandidateConfirmationAction } from "../../../../../store/ATS/Candidate/Action";
import ArchiveIcon from '@mui/icons-material/Archive';
import { makeStyles } from "@mui/styles";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { UpdateStatusHelper } from "../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper";
import { ATS_JOBPOST_PK } from "../../../../Constants/baseConstant";
import { AddAtsJobKanbanHelper } from "../../helper/AtsJobKanbanHelper";
import { GetAtsJobPostAction } from "../../../../../store/ATS/JobPost/Action";
import { matchSorter } from "match-sorter";


const AtsProposeCandidateForm = ({ toggleDrawer, currentData, setCurrentData }) => {
    const useStyles = makeStyles({
        row: {
            display: "flex", flexDirection: "row"
        },
        label: {
            fontSize: "0.75rem", fontWeight: 400, width: "120px", flexShrink: 0,
            color: "rgb(99, 115, 129)", height: "30px", lineHeight: "30px", margin: "4px 0px 4px 0px"
        },
        value: {
            display: "flex", flexFlow: "row wrap", alignItems: "center"
        }
    });
    const classes = useStyles()
    const dispatch = useDispatch();
    const initialFValues = {
        candidate_id: "",
        first_name: "",
        last_name: "",
        dob: "",
        mail_id: "",
        contact_no: "",
        applicant_id: "",
        country_id: "",
        state_id: "",
        city_id: "",
        zipcode: "",
        website: "",
        user: "",
    };
    const [proposeCandidateData, setProposeCandidateData] = useState(initialFValues);
    const [dialogType, setDialogType] = useState();
    const { atsCandidateData } = useSelector(state => state.AtsReducer?.AtsCandidateReducer);

    useEffect(() => {
        dispatch(GetAtsCandidateAction())
        //eslint-disable-next-line
    }, []);

    const [openDialog, setOpenDialog] = useState(false);

    const handleInputChange = (e, value) => {
        if (value) {
            setProposeCandidateData({ ...proposeCandidateData, ...value })
        } else {
            setProposeCandidateData(initialFValues)
        }
    }

    const handleArchive = () => {
        setDialogType("archive")
        setOpenDialog(true);
    }
    const handleConfirmArchive = () => {
        setOpenDialog(false);
        setCurrentData();
        toggleDrawer(false);
        UpdateStatusHelper({ update_id: currentData?.ats_jobpost_id, status: "Inactive", column_name: ATS_JOBPOST_PK, successMessage: "Job Post Archived Successfully" }).then(res => {
            dispatch(GetAtsJobPostAction({ company_id: 1, consultancy_id: null }))
        }
        )
    }

    const handleAddCandidate = () => {
        setDialogType("add")
        setOpenDialog(true);
    }
    const handleConfirmAddCandidate = () => {
        setOpenDialog(false);
        setCurrentData();
        toggleDrawer(false);
        AddAtsJobKanbanHelper({
            kanban_data: JSON.stringify([{
                "candidate_id": proposeCandidateData?.candidate_id, "ats_jobpost_id": currentData?.ats_jobpost_id,
                "comment": null, "ats_job_stages_id": null, "priority": 5, "ats_status_id": null
            }]),
        }).then((res) => {
            if (res?.data?.statuscode === 200) {
                dispatch(ProposeCandidateConfirmationAction({ candidate_data: proposeCandidateData, job_data: currentData, kanban_data:res?.data?.data?.[0] }))
            }
        })
    }

    const filterOptions = (options, { inputValue }) => {
        let vals = matchSorter(options, inputValue, { keys: ["first_name", "email_id"] });
        vals = vals.map((item) => ({
            ...item,
            full_name: `${item.first_name} ${item.last_name}`
        }));

        return vals || [];
    };


    return (
        <Box>
            <div style={{ display: "flex", flexDirection: "row", padding: "20px", alignItems: "center" }}>
                <Typography variant='h4'>{currentData?.title}</Typography>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexGrow: 1 }}>
                    <Tooltip title="Archive Job">
                        <IconButton onClick={handleArchive}>
                            <ArchiveIcon fontSize='small' color='error' />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <Divider />
            <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px 10px 20px" }}>
                <div className={classes.row}>
                    <span className={classes.label}>Job ID</span>
                    <div className={classes.value}>{currentData?.job_id}</div>
                </div>
                <div className={classes.row}>
                    <span className={classes.label}>Client</span>
                    <div className={classes.value}>{currentData?.company}</div>
                </div>
                <div className={classes.row}>
                    <span className={classes.label}>Location</span>
                    <div className={classes.value}>{currentData?.job_location}</div>
                </div>
                <div className={classes.row}>
                    <span className={classes.label}>Primary Recruiter</span>
                    <div className={classes.value}>{currentData?.primary_recruiter}</div>
                </div>
                <div className={classes.row}>
                    <span className={classes.label}>Vacancies</span>
                    <div className={classes.value}>{currentData?.vaccancies}</div>
                </div>
                <div className={classes.row}>
                    <span className={classes.label}>Remaining Position</span>
                    <div className={classes.value}>{currentData?.remaining_cand}</div>
                </div>
            </div>
            <Divider />
            <div style={{ display: "flex", flexDirection: "column", padding: "10px 20px 10px 20px" }}>
                <div>
                    <Typography variant='h4'>Propose Candidate</Typography>
                </div>

                <Box display={"flex"} justifyContent="space-between" pt={1}>
                    <FormControl fullWidth sx={{ pr: 1 }}>
                        <Autocomplete
                            options={atsCandidateData || []}
                            value={proposeCandidateData}
                            size="small"
                            name="candidate_id"
                            filterOptions={filterOptions}
                            getOptionLabel={(option) => {
                                if (option && option["first_name"]) {
                                    const fullName = `${option.first_name} ${option.last_name}`;
                                    return fullName.trim(); // Trim to remove extra spaces
                                } else {
                                    return "";
                                }
                            }}
                            onChange={handleInputChange}
                            renderInput={(params) => <TextField {...params} label="Candidate" placeholder="Search candidate by E-mail" />}
                        />
                    </FormControl>
                    <Button className="mpl-secondary-btn" size="small" onClick={handleAddCandidate} disabled={!proposeCandidateData?.candidate_id} variant="outlined">
                        Propose
                    </Button>
                </Box>

                {
                    proposeCandidateData?.candidate_id &&
                    <>
                        <div className={classes.row}>
                            <span className={classes.label}>First Name</span>
                            <div className={classes.value}>{proposeCandidateData?.first_name}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Last Name</span>
                            <div className={classes.value}>{proposeCandidateData?.last_name}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Date of Birth</span>
                            <div className={classes.value}>{proposeCandidateData?.dob}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Email</span>
                            <div className={classes.value}>{proposeCandidateData?.email_id}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Contact</span>
                            <div className={classes.value}>{proposeCandidateData?.contact_no}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Notice Period</span>
                            <div className={classes.value}>{proposeCandidateData?.notice_period}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Expected Pay</span>
                            <div className={classes.value}>{proposeCandidateData?.expected_pay}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Year of Experience</span>
                            <div className={classes.value}>{proposeCandidateData?.years_of_experience}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Resume</span>
                            <div className={classes.value}>{proposeCandidateData?.resume}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Skills</span>
                            <div className={classes.value}>{proposeCandidateData?.skills}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Current CTC</span>
                            <div className={classes.value}>{proposeCandidateData?.current_ctc}</div>
                        </div>
                        <div className={classes.row}>
                            <span className={classes.label}>Current Employer</span>
                            <div className={classes.value}>{proposeCandidateData?.current_employer}</div>
                        </div>
                    </>
                }
            </div>

            <ConfirmationDialog
                title={dialogType === "add" ? "Propose Candidate" : "Archive"}
                content={dialogType === "add" ? `Do you wish to propose ${proposeCandidateData?.first_name} to ${currentData?.title} ?` : "Are you sure want to archive?"}
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Job"
                OkButtonText={dialogType === "add" ? "Yes, propose" : "Yes, Archive"}
                onSubmit={dialogType === "add" ? handleConfirmAddCandidate : handleConfirmArchive}
            />
        </Box>
    )
}

export default AtsProposeCandidateForm