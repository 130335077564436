import * as ActionTypes from '../ActionTypes';

export const GetAtsEmployeesAction = (params) => {
    return {
        type: ActionTypes.ATS_EMPLOYEES_GET_REQUEST,
        params
    }
}

export const GetEmployeesAction = (params) => {
    return {
        type: ActionTypes.EMPLOYEES_GET_REQUEST,
        params
    }
}