import { Box, Button, FormControl, FormGroup, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ATS_CONTACT_PK } from '../../../../Constants/baseConstant'
// import { GetCityHelper } from '../../../Ancillary/City/helper/CityHelper'
// import { GetCountryHelper } from '../../../Ancillary/Country/helper/CountryHelper'
// import { GetStateHelper } from '../../../Ancillary/State/helper/StateHelper'
// import SelectableSearch from '../../../Base/views/SelectableSearch'
import { UpdateStatusHelper } from '../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper'
import { AddAtsContactHelper, GetAtsContactHelper, UpdateAtsContactHelper } from '../../helper/AtsContactHelper'
import AtsContact from '../ATSConsultancy/AtsContact'
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackButton from '../../../Base/views/ArrowBackButton'
import { GetAtsGroupCompanyAction, UpdateAtsGroupCompanyAction } from '../../../../../store/ATS/GroupCompany/Action'
import { AddAtsGroupCompanyHelper } from '../../helper/AtsGroupCompanyHelper'

const AtsGroupCompanyForm = ({ setCurrentMode, currentData, setAtsCompanyData }) => {
  const dispatch = useDispatch();
  const initialFValues = {
    name: "",
    address: "",
    contact_person: "",
    mobile_number: "",
    office_number: "",
    country_id: "",
    state_id: "",
    city_id: "",
    zip_code: "",
    industry: "",
    about_grouporg: "",
    website: "",
    primary_owner: "",
    grouporg_lead_id: "",
    business_unit_id: "",
    email_id: "",
    billing_address: "",
    billing_name: "",
    gst_applicable: false,
    gst: "",
    sgst: "",
    cgst: "",
    igst: "",
    sac_code: "",
    status: "Active",
    is_same: false,
  };
  const contactValues = {
    first_name: "",
    last_name: "",
    mobile_number: null,
    email_id: "",
    status: "Active",
  };
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [contactErrors, setContactErrors] = useState([]);
  const [companyData, setCompanyData] = useState(initialFValues);
  const [contactData, setContactData] = useState([]);
  // const [CountryData, setCountryData] = useState({ load: true, values: [] });
  // const [StateData, setStateData] = useState({ load: true, values: [] });
  // const [CityData, setCityData] = useState({ load: true, values: [] });
  const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
  const handleDisabled = (permission) => {
    const foundPermission = featuresData?.find((item) => item.permission === permission);
    return foundPermission ? true : false;
  };

  const handleChange = (event, value) => {
    if (event.target.value === 'true') {
      setCompanyData({
        ...companyData,
        [event.target.name]: event.target.value === 'true',
        sgst: "9",
        cgst: "9",
        igst: "",
        sac_code: "",
      });
    }
    else {
      setCompanyData({
        ...companyData,
        [event.target.name]: event.target.value === 'true',
        sgst: "",
        cgst: "",
        igst: "",
        sac_code: "",
        gst: ""
      });
    }
  };
  useEffect(() => {
    if (currentData != null) {
      currentData?.ats_grouporg_id && GetAtsContactHelper({ workspace: "altiio", type: "Company", company_id: +currentData?.ats_grouporg_id, consultancy_id: null }).then(res => {
        let tdata = []
        if (res?.data?.data?.[0]?.contacts?.length > 0) {
          res?.data?.data?.[0]?.contacts?.forEach(resp => tdata.push({ ...resp }));
          setContactData(tdata);
        }
        setLoading(false)
      })
      setCompanyData({
        ...currentData, country_id: { country_id: currentData?.country_id, country_name: currentData?.country },
        state_id: { state_id: currentData?.state_id, state_name: currentData?.state },
        city_id: { city_id: currentData?.city_id, city_name: currentData?.city },
      });
    }
  }, [currentData]);
  // const handleCountryLoad = () => {
  //   GetCountryHelper().then((res) => {
  //     setCountryData({ load: false, values: res?.data?.data });
  //   });
  // };
  // const handleStateLoad = () => {
  //   GetStateHelper(companyData?.country_id?.country_id).then((res) => {
  //     setStateData({ load: false, values: res?.data?.data });
  //   });
  // };
  // const handleCityLoad = () => {
  //   GetCityHelper(companyData?.state_id?.state_id).then((res) => {
  //     setCityData({ load: false, values: res?.data?.data });
  //   });
  // };
  const contactValidate = () => {
    let ret_value = [];
    contactData?.forEach((store, index) => {
      const error = {};
      if (store?.status === "Active") {
        if (!store.first_name) error.first_name = "First Name is required";
        if ("email_id" in store) {
          error.email_id =
            store.email_id === ""
              ? "Email is required"
              : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(store.email_id)
                ? ""
                : "Invalid Email.";
        }
        if ("mobile_number" in store) {
          error.mobile_number =
            store?.mobile_number?.toString() === "" ||
              store?.mobile_number === null
              ? "Mobile Number is required"
              : !store?.mobile_number
                ?.toString()
                ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
                ? "Invalid Mobile Number."
                : "";
        }
        const temp = contactErrors;
        temp[index] = error;
        setContactErrors([...temp]);
        ret_value.push(Object.values(error).every((e) => e === ""));
      }
    });
    return ret_value;
  };
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("name" in fieldValues) {
      temp.name = fieldValues.name?.trim() === "" ? "Org Name is required" : "";
    }
    if ("address" in fieldValues) {
      temp.address = fieldValues.address?.trim() === "" ? "Address is required" : "";
    }
    if ("contact_person" in fieldValues) {
      temp.contact_person = fieldValues.contact_person?.trim() === "" ? "Contact Person is required" : "";
    }
    if ("mobile_number" in fieldValues) {
      temp.mobile_number = (fieldValues?.mobile_number.toString()) === "" ? "Mobile Number is required" :
        !fieldValues?.mobile_number?.toString()?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? "Invalid Mobile Number." : "";
    }
    if ("email_id" in fieldValues) {
      temp.email_id =
        fieldValues.email_id === ""
          ? "Email ID is Required"
          : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValues.email_id)
            ? ""
            : "Invalid Email.";
    }
    if (fieldValues.gst_applicable && "gst" in fieldValues) {
      temp.gst = fieldValues.gst === ""
        ? "GST is required"
        : /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(fieldValues.gst) ? "" : "Enter valid gst"
    }
    if ("billing_address" in fieldValues) {
      temp.billing_address = fieldValues.billing_address === "" ? "Billing Address is required" : "";
    }
    if ("billing_name" in fieldValues) {
      temp.billing_name = fieldValues.billing_name === "" ? "Billing Name is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleBillInfo = (event, value) => {
    if (event.target.checked) {
      setCompanyData({ ...companyData, billing_address: companyData.address, billing_name: companyData.name,[event.target.name]: event.target.checked, })
      Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({ ...companyData, 'billing_name': companyData.name, 'billing_address': companyData.address });
    }
    else {
      setCompanyData({ ...companyData, 'billing_address': '', 'billing_name': '',[event.target.name]: event.target.checked, })
      Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({ ...companyData, billing_address: '', billing_name: '' });
    }
  }
  const handleInputChange = (event, value) => {
    if (["city_id"].includes(event)) {
      if (value) {
        setCompanyData({ ...companyData, [event]: value })
      } else {
        setCompanyData({ ...companyData, [event]: "" })
      }
      Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({ ...companyData, [event]: value ? value : "" });
    } else if (["country_id"].includes(event)) {
      // setStateData({ load: true, values: [] });
      // setCityData({ load: true, values: [] });
      setCompanyData({ ...companyData, [event]: value ? value : "", "state_id": "", "city_id": "" });
      Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({ ...companyData, [event]: value ? value : "" })
    } else if (["state_id"].includes(event)) {
      // setCityData({ load: true, values: [] })
      setCompanyData({ ...companyData, [event]: value ? value : "", "city_id": "" });
      Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({ ...companyData, [event]: value ? value : "" })
    }
    else {
      setCompanyData({ ...companyData, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
        validate({ ...companyData, [event.target.name]: event.target.value });
    }
  }
  const HandleSubmit = (e) => {
    e.preventDefault();
    if (validate(companyData)) {
      if (!(contactValidate().includes(false))) {
        if (companyData?.ats_grouporg_id) {
          dispatch(UpdateAtsGroupCompanyAction(companyData))
          if (contactData?.length > 0) UpdateAtsContactHelper({ type: "Company", company_id: currentData?.ats_grouporg_id, consultancy_id: null, contact_data: JSON.stringify(contactData) })
          else AddAtsContactHelper({ type: "Company", company_id: currentData?.ats_grouporg_id, consultancy_id: null, contact_data: JSON.stringify(contactData) })
        } else {
          AddAtsGroupCompanyHelper(companyData).then(res => {
            if (res?.data?.statuscode === 200) {
              dispatch(GetAtsGroupCompanyAction());
              setAtsCompanyData(res?.data?.data)
            }
          })
        }
        resetForm();
      } else if (contactData?.length === 0) {
        if (companyData?.ats_grouporg_id) {
          dispatch(UpdateAtsGroupCompanyAction(companyData))
        }
        else {
          AddAtsGroupCompanyHelper(companyData).then(res => {
            if (res?.data?.statuscode === 200) {
              setAtsCompanyData(res?.data?.data)
            }
          })
        }
        resetForm();
      }
    }
  };
  const handleContactDelete = (id) => {
    UpdateStatusHelper({ update_id: id, status: "Inactive", column_name: ATS_CONTACT_PK, successMessage: "Contact Archived Successfully" })
  }
  const resetForm = () => {
    setCompanyData(initialFValues);
    setErrors({});
    setCurrentMode("grid")
    dispatch({ type: "EMPTY_CONTACT" })
  };
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          background: "#fff",
          zIndex: 1000,
          display: "flex",
          justifyContent: "space-between",
          pl: 2,
          pt: 2,
          pr: 2,
          pb: 3
        }}
      >
        <ArrowBackButton onClick={resetForm} />
        <Button onClick={HandleSubmit} disabled={handleDisabled("client_update_client")} variant="contained" size="small" color="primary" type="submit" className="mpl-primary-btn" sx={{ ml: 2 }}>
          {currentData?.ats_grouporg_id ? "Update" : "Save"}
        </Button>
      </Box >

      <Grid container>
        <Grid container>
          <Grid
            key="name"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                autoFocus={true}
                required
                size="small"
                value={companyData.name}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="name"
                onKeyPress={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                label="Org Name"
                name="name"
                autoComplete="name"
                {...(errors.name && {
                  error: true,
                  helperText: errors.name,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="address"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                value={companyData.address}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="address"
                label="Address"
                onKeyPress={(e) =>
                  e.target.value?.length >= 254 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 254 && e.preventDefault()
                }
                name="address"
                autoComplete="address"
                {...(errors.address && {
                  error: true,
                  helperText: errors.address,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="contact_person"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                value={companyData.contact_person}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="contact_person"
                label="Contact Person"
                onKeyPress={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                name="contact_person"
                autoComplete="contact_person"
                {...(errors.contact_person && {
                  error: true,
                  helperText: errors.contact_person,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="mobile_number"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                value={companyData.mobile_number}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="mobile_number"
                type="number"
                label="Mobile"
                name="mobile_number"
                autoComplete="mobile_number"
                {...(errors.mobile_number && {
                  error: true,
                  helperText: errors.mobile_number,
                })}
              />
            </FormControl>
          </Grid>
          {/* <Grid
            key="office_number"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                value={companyData.office_number}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="office_number"
                label="Office Number"
                type="number"
                name="office_number"
                autoComplete="office_number"
                {...(errors.office_number && {
                  error: true,
                  helperText: errors.office_number,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="country_id"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                  +option.country_id === +value.country_id
                }
                label="Country"
                value={companyData?.country_id}
                loading={() => handleCountryLoad()}
                fieldLabel="country_name"
                required
                variant={"contained"}
                name="country_id"
                size="small"
                data={CountryData}
                onChangeEvent={handleInputChange}
                {...(errors.country_id && {
                  error: true,
                  helperText: errors.country_id,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="state_id"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                  +option.state_id === +value.state_id
                }
                label="State"
                value={companyData?.state_id}
                fieldLabel="state_name"
                required
                variant={"contained"}
                name="state_id"
                size="small"
                loading={() => handleStateLoad()}
                data={StateData}
                onChangeEvent={handleInputChange}
                {...(errors.state_id && {
                  error: true,
                  helperText: errors.state_id,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="city_id"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                  +option.city_id === +value.city_id
                }
                label="City"
                value={companyData?.city_id}
                fieldLabel="city_name"
                required
                loading={() => handleCityLoad()}
                variant={"contained"}
                name="city_id"
                size="small"
                data={CityData}
                onChangeEvent={handleInputChange}
                {...(errors.city_id && {
                  error: true,
                  helperText: errors.city_id,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="zip_code"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                value={companyData.zip_code}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="zip_code"
                label="Zip Code"
                name="zip_code"
                autoComplete="zip_code"
                {...(errors.zip_code && {
                  error: true,
                  helperText: errors.zip_code,
                })}
              />
            </FormControl>
          </Grid> */}
          <Grid
            key="industry"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                value={companyData.industry}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="industry"
                label="Industry"
                onKeyPress={(e) =>
                  e.target.value?.length >= 100 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 100 && e.preventDefault()
                }
                name="industry"
                autoComplete="industry"
                {...(errors.industry && {
                  error: true,
                  helperText: errors.industry,
                })}
              />
            </FormControl>
          </Grid>
          {/* <Grid
            key="about_grouporg"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                value={companyData.about_grouporg}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="about_grouporg"
                label="About Client"
                onKeyPress={(e) =>
                  e.target.value?.length >= 254 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 254 && e.preventDefault()
                }
                name="about_grouporg"
                autoComplete="about_grouporg"
                {...(errors.about_grouporg && {
                  error: true,
                  helperText: errors.about_grouporg,
                })}
              />
            </FormControl>
          </Grid>
          <Grid
            key="website"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                value={companyData.website}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="website"
                label="Website"
                name="website"
                autoComplete="website"
                onKeyPress={(e) =>
                  e.target.value?.length >= 254 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 254 && e.preventDefault()
                }
                {...(errors.website && {
                  error: true,
                  helperText: errors.website,
                })}
              />
            </FormControl>
          </Grid> */}
          <Grid
            key="primary_owner"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                value={companyData.primary_owner}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="primary_owner"
                label="Primary Owner"
                name="primary_owner"
                onKeyPress={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                autoComplete="primary_owner"
                {...(errors.primary_owner && {
                  error: true,
                  helperText: errors.primary_owner,
                })}
              />
            </FormControl>
          </Grid>
          {/* <Grid
            key="company_lead_id"
            item
            xs={12}
            md={4}
            sx={{
              marginTop: "1rem",
              paddingRight: "15px",
              paddingLeft: "15px",
            }}
          >
            <FormControl fullWidth>
              <TextField
                size="small"
                value={companyData.company_lead_id}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="company_lead_id"
                label="Lead"
                onKeyPress={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                onPaste={(e) =>
                  e.target.value?.length >= 35 && e.preventDefault()
                }
                name="company_lead_id"
                autoComplete="company_lead_id"
                {...(errors.company_lead_id && {
                  error: true,
                  helperText: errors.company_lead_id,
                })}
              />
            </FormControl>
          </Grid> */}
          <Grid key="email_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
            <FormControl fullWidth >
              <TextField
                size="small"
                value={companyData.email_id}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                required
                id="email_id"
                label="Contact Email"
                name="email_id"
                autoComplete="email_id"
                {...(errors.email_id && {
                  error: true,
                  helperText: errors.email_id,
                })}
              />
            </FormControl>
          </Grid>
        </Grid>
        {currentData?.ats_grouporg_id &&
          <Grid container>
            <Grid item xs={12}>
              <AtsContact
                loading={loading}
                contactData={contactData}
                setContactData={setContactData}
                contactValues={contactValues}
                errors={contactErrors}
                setErrors={setContactErrors}
                contactValidate={contactValidate}
                handleContactDelete={handleContactDelete}
                idField="ats_contacts_id"
              />
            </Grid>
          </Grid>
        }
        <Grid sx={{ display: 'flex' }}>
          <Typography variant='h6' sx={{ ml: 2, mr: 2, mt: 4 }}>Bill To </Typography>
          <FormGroup sx={{ ml: 2, mr: 2, mt: 3 }}>
            <FormControlLabel  disabled={!(companyData.name && companyData.address)}
              control={<Checkbox name="is_same" checked={companyData?.is_same} onChange={handleBillInfo}/>} label="Same as Above" />
          </FormGroup>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
            <FormControl fullWidth >
              <TextField
                required
                size="small"
                value={companyData.billing_name}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="billingName"
                onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                label="Billing Name"
                name="billing_name"
                autoComplete="billingName"
                {...(errors.billing_name && {
                  error: true,
                  helperText: errors.billing_name,
                })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
            <FormControl fullWidth>
              <TextField
                size="small"
                required
                multiline
                rows={3}
                value={companyData.billing_address}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="billing_address"
                label="Billing Address"
                onKeyPress={(e) => e.target.value?.length >= 254 && e.preventDefault()}
                onPaste={(e) => e.target.value?.length >= 254 && e.preventDefault()}
                name="billing_address"
                autoComplete="billing_address"
                {...(errors.billing_address && {
                  error: true,
                  helperText: errors.billing_address,
                })}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid >
          <Typography variant='h6' sx={{ ml: 2, mr: 2, mt: 4 }}>GST applicable</Typography>
          {
            <FormGroup row sx={{ ml: 2, mr: 2, mt: 2 }}>
              <RadioGroup name='gst_applicable' row onChange={handleChange} value={companyData.gst_applicable}>
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormGroup>
          }
        </Grid>
        {
          companyData?.gst_applicable === true &&
          <>
            <Grid container>
              <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                <FormControl fullWidth >
                  <TextField
                    required
                    size="small"
                    value={companyData.gst}
                    onChange={(e) => {
                      const uppercaseValue = e.target.value.toUpperCase();
                      handleInputChange({ target: { name: e.target.name, value: uppercaseValue } });
                    }}
                    variant="outlined"
                    fullWidth
                    id="gst"
                    onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    label="GST Number"
                    name="gst"
                    autoComplete="gst"
                    {...(errors.gst && {
                      error: true,
                      helperText: errors.gst,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                <FormControl fullWidth >
                  <TextField
                    size="small"
                    value={companyData.sac_code}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="sac_code"
                    onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    label="SAC Code"
                    name="sac_code"
                    autoComplete="sac_code"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    value={companyData.cgst}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="cgst"
                    onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    label="CGST"
                    name="cgst"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    value={companyData.sgst}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="sgst"
                    onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    label="SGST"
                    name="sgst"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    value={companyData.igst}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="igst"
                    onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                    label="IGST"
                    name="igst"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        }

      </Grid>
    </>
  )
}
export default AtsGroupCompanyForm;