import { Box, Card, CardContent, CardHeader, Divider, Grid, IconButton, LinearProgress, Pagination, Paper, Skeleton, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import PsychologyIcon from '@mui/icons-material/Psychology';
import EmailIcon from '@mui/icons-material/Email';
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import PropTypes from 'prop-types';
import AtsCandidateFilters from '../../ATSCandidate/CandidateFilter/AtsCandidateFilters';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetAtsProfileScoreAction } from '../../../../../../store/ATS/ProfileScore/Action';
import { getUserData } from '../../../../Base/helper/baseFunctions';
import { useParams } from "react-router-dom";
import ConfirmationDialog from '../../../../Base/views/ConfirmationDialog';
import { AddAtsJobKanbanHelper } from '../../../helper/AtsJobKanbanHelper';
import Base from '../../../../Base/views/Base';
import ArrowBackButton from '../../../../Base/views/ArrowBackButton';
import history from '../../../../../../history';
import { AtsJobPostModeAction } from '../../../../../../store/ATS/JobPost/Action';
import AtsCandidateForm from '../../ATSCandidate/AtsCandidateForm';
import { ProposeCandidateConfirmationAction } from '../../../../../../store/ATS/Candidate/Action';
import ResumeUploader from '../../ATSCandidate/ResumeUploader';


const AISuggestion = () => {
    const dispatch = useDispatch();
    const userData = getUserData();
    const [value, setValue] = useState(0);
    const { getsuggestions, isLoading } = useSelector((state) => state.AtsReducer?.AtsProfileScoreReducer);
    const { currentJobData } = useSelector((state) => state.AtsReducer?.AtsJobPostReducer);
    const showCandidate = getsuggestions.length === 0;
    const { job_id } = useParams()
    const id = atob(job_id);
    
    const [anchorE, setAnchorE] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [candidatePreview, setCandidatePreview] = useState(false)
    const [currentData, setCurrentData] = useState()
    const [data, setData] = useState()
    const [page, setPage] = useState(1);

    useEffect(() => {
        getData();
        //eslint-disable-next-line
    }, [])

    const getData = (e) => {
        dispatch(GetAtsProfileScoreAction({
            domain: userData?.domain,
            ats_jobpost_id: id,
            page_number: typeof e === "number" ? e : 1,
            page_size: 12,
        }));
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", overflowY: "auto" }}>
                        {children}
                    </Paper>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const proposeCandidate = (value) => {
        setData(value)
        setAnchorE(null);
        setOpenDialog(true);
    }
    const handleClickPreview = (value) => {
        setCurrentData(value)
        setCandidatePreview(true)
    }
    const handleConfirmPropose = () => {
        AddAtsJobKanbanHelper({
            kanban_data: JSON.stringify([{
                "candidate_id": data?.candidate_id, "ats_jobpost_id": id,
                "comment": null, "ats_job_stages_id": null, "priority": 5, "ats_status_id": null
            }]),
        }).then((res) => {
            if (res?.data?.statuscode === 200) {
                dispatch(ProposeCandidateConfirmationAction({ candidate_data: data, job_data: currentJobData, kanban_data:res?.data?.data?.[0] }))
            }
            getData()
        })
        setOpenDialog(false);
    }
    const handlePagination = (event, value) => {
        setPage(value)
        getData(value);
    };
    const handleBack = () => {
        dispatch(AtsJobPostModeAction({ job_mode: "edit", job_id: id, currentJobData: currentJobData }))
        history.push("/ats/manage-job")
    }
    return (
        <Base>
            {
                candidatePreview === true ? <AtsCandidateForm setCandidatePreview={setCandidatePreview} currentData={currentData} currentJobData={currentJobData} isProfile={true} ai={true} editedRecord={currentData} /> :
                    <>
                        <Box sx={{ borderRadius: "10px", overflowY: "auto" }}>
                            <ArrowBackButton onClick={handleBack} />
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                <Tab label="Manual Search" {...a11yProps(0)} />
                                <Tab label="AI Search" {...a11yProps(1)} />
                                <Tab label="Local Upload" {...a11yProps(2)} />

                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Box >
                                    <AtsCandidateFilters ai={true} currentJobData={currentJobData} />
                                </Box>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <>
                                    {isLoading ? (
                                        <>
                                            <Grid container alignItems="center">
                                                {Array(8)
                                                    .fill()
                                                    .map((data, index) => (
                                                        <Grid key={index} item p={1.5} lg={6} md={6} sm={6} xs={12}>
                                                            <Card>
                                                                <CardHeader
                                                                    action={null}
                                                                    title={
                                                                        <Skeleton
                                                                            animation="wave"
                                                                            height={10}
                                                                            width="80%"
                                                                            style={{ marginBottom: 6 }}
                                                                        />
                                                                    }
                                                                    subheader={
                                                                        <Skeleton
                                                                            animation="wave"
                                                                            height={10}
                                                                            width="40%"
                                                                            style={{ marginBottom: 6 }}
                                                                        />
                                                                    }
                                                                />
                                                                <Skeleton
                                                                    sx={{ height: 130 }}
                                                                    animation="wave"
                                                                    variant="rectangular"
                                                                />
                                                                <CardContent>
                                                                    <>
                                                                        <Skeleton
                                                                            animation="wave"
                                                                            height={10}
                                                                            style={{ marginBottom: 6 }}
                                                                        />
                                                                        <Skeleton
                                                                            animation="wave"
                                                                            height={10}
                                                                            width="80%"
                                                                        />
                                                                    </>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Box sx={{ p: 2, mt: 1, backgroundColor: "white", borderRadius: "10px", maxHeight: "68vh", overflowY: "auto" }}>
                                                <Typography variant='h5' sx={{ ml: 1, pb: 1 }}>Suggested Candidates</Typography>
                                                {showCandidate > 0 && <Typography sx={{ mb: 2 }} display="flex" justifyContent="center">  Candidates Not Available </Typography>}
                                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                                    {getsuggestions?.map((su, index) => (
                                                        <Grid sx={{ padding: "10px" }} key={index} item xs={12} sm={6} md={6} lg={6}>
                                                            <Card sx={{ padding: "14px", }}>
                                                                <Box sx={{ paddingRight: "8px" }}>
                                                                    <Box sx={{ display: "flex", WebkitBoxPack: "justify", justifyContent: "space-between", alignItems: "center", width: "100%", }}>
                                                                        <Tooltip placement="bottom-start" title="Name"><Typography sx={{ mb: 0 }} variant='subtitle2' gutterBottom>{su?.first_name} {su?.last_name || ""}</Typography></Tooltip>
                                                                        <IconButton onClick={() => proposeCandidate(su)}><Tooltip title="Propose"><PersonAddAlt1Icon color="success" fontSize='small' /></Tooltip></IconButton>
                                                                    </Box>
                                                                    <Divider sx={{ mb: 2 }} variant="middle" />
                                                                    <Grid sx={{ cursor: "Pointer" }} onClick={() => handleClickPreview(su)} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                            <Tooltip placement="bottom-start" title="Email"><span style={{ display: "flex" }} ><EmailIcon fontSize='small' /><Typography pl={1} variant='body2' gutterBottom>{su?.email_id}</Typography></span></Tooltip>
                                                                            <Tooltip placement="bottom-start" title="Location"><span style={{ display: "flex" }} ><LocationOnRoundedIcon fontSize='small' /><Typography pl={1} variant='body2'>{su?.location || "-"}</Typography></span></Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                                                            <Tooltip placement="bottom-start" title="Primary Skills"><span style={{ display: "flex" }} ><PsychologyIcon fontSize='small' /><Typography pl={1} variant='body2' gutterBottom>{su?.primary_skill}</Typography></span></Tooltip>
                                                                            <Tooltip placement="bottom-start" title="Secondary Skills"><span style={{ display: "flex" }} ><PsychologyIcon fontSize='small' /><Typography pl={1} variant='body2'>{su?.secondary_skill || "-"}</Typography></span></Tooltip>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                            <LinearProgress className='progress-value' variant="determinate" value={su?.profile_score ? su?.profile_score : 0} /><span style={{ display: "flex", justifyContent: "center" }}><Typography pl={1} variant='body2'>{su?.profile_score ? `${su?.profile_score}%` : "0%"}</Typography></span>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            </Card>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                            {getsuggestions?.[0]?.total_count > 12 && (
                                                <Pagination
                                                    sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                                                    showFirstButton
                                                    showLastButton
                                                    count={
                                                        Number.isInteger(getsuggestions?.[0]?.total_count / 12)
                                                            ? getsuggestions?.[0]?.total_count / 12
                                                            : Math.round(getsuggestions?.[0]?.total_count / 12 + 0.5) ||
                                                            0
                                                    }
                                                    page={page}
                                                    color="secondary"
                                                    onChange={(event, value) => handlePagination(event, value)}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            </TabPanel>
                            <TabPanel value={value} index={2} >
                    <ResumeUploader id={ id }/>
                </TabPanel>
                        </Box>
                        <ConfirmationDialog
                            title="Propose Candidate"
                            content={`Do you wish to Propose ${data?.first_name} to ${currentJobData.title}?`}
                            anchorE={anchorE}
                            openDialog={openDialog}
                            closeDialog={setOpenDialog}
                            popupTitle="Job"
                            OkButtonText="Yes Propose"
                            onSubmit={handleConfirmPropose}
                        />
                    </>}
        </Base >
    );
}
export default AISuggestion;