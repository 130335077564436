import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    ClickAwayListener,
    Divider,
    Fade,
    Grid,
    IconButton,
    Pagination,
    Skeleton,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { NoData } from "../../../Base/views/NotAuthorized";
import { AddAtsJobPostFillterAction, GetAtsJobPostAction } from "../../../../../store/ATS/JobPost/Action";
import { PRIMARY } from "../../../theme/palette";
import CustomToggle from "../../../Base/views/CustomToggle";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { UpdateAtsJobPostStatusHelper } from "../../helper/JobPostHelper";
import { toast } from "react-toastify";
import { ValidateAtsCompConsulStatusHelper } from "../../helper/AtsUserHelper";

const AtsArchivedJobPost = ({ jobPostFormMode, setCurrentData, setCurrentMode, archived, handleArchive }) => {
    const dispatch = useDispatch();
    const { atsJobPostData, isLoading, atsJobPostMode, currentJobData } = useSelector(
        (state) => state.AtsReducer?.AtsJobPostReducer
    );
    const { atsCompanyData } = useSelector(state => state.AtsReducer?.AtsCompanyReducer);
    const { atsJobFilterData } = useSelector(state => state.AtsReducer?.AtsJobPostReducer);
    // Filter
    const [filterApplied, setFilterApplied] = useState(false);
    const [companyValue, setCompanyValue] = useState(atsJobFilterData?.company_data || []);
    const [consultancyValue, setConsultancyValue] = useState(atsJobFilterData?.consultancy_data || []);
    const [primaryRecruiterValue, setPrimaryRecruiterValue] = useState(atsJobFilterData?.primary_recruiter || []);
    const [secondaryRecruiterValue, setSecondaryRecruiterValue] = useState(atsJobFilterData?.secondary_recruiter || []);
    const [severityValue, setSeverityValue] = useState(atsJobFilterData?.severity_data || []);
    const [searchValue, setSearchValue] = useState("");
    const [search, setSearch] = useState(false);
    const [company_full_name, setcompany_full_name] = useState("");
    const [page, setPage] = useState(1);
    const [copyLink, setCopyLink] = useState(false);
    const [currentjobData, setCurrentJobData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    useEffect(() => {
        const CompanyFullName = sessionStorage.getItem("company_full_name");
        setcompany_full_name(CompanyFullName || "NA");
        getJobData();
        JobFilters();
        //eslint-disable-next-line
    }, [searchValue, companyValue, consultancyValue, severityValue, primaryRecruiterValue, secondaryRecruiterValue]);

    useEffect(() => {
        if (atsJobPostData.length > 0) {
            if (atsJobPostMode === "edit") {
                setCurrentData(currentJobData);
                setCurrentMode(atsJobPostMode);
            } else {
                setCurrentMode("grid");
            }
        }
        //eslint-disable-next-line
    }, [atsJobPostData, atsJobPostMode, currentJobData]);


    const handleSearch = () => {
        setSearch(!search)
    }

    const construcData = (value, label, key) => {
        var temp = [];
        value?.forEach((res) => {
            temp.push({ [label]: res[key] });
        });
        return JSON.stringify(temp);
    };

    const JobFilters = (e) => {
        dispatch(AddAtsJobPostFillterAction({
            company_data: e?.companyValue === null ? null : companyValue?.length > 0 ? companyValue : null,
            consultancy_data: e?.consultancyValue === null ? null : consultancyValue?.length > 0 ? consultancyValue : null,
            severity_data: e?.severityValue === null ? null : severityValue?.length > 0 ? severityValue : null,
            primary_recruiter: e?.primaryRecruiterValue === null ? null : primaryRecruiterValue?.length > 0 ? primaryRecruiterValue : null,
            secondary_recruiter: e?.secondaryRecruiterValue === null ? null : secondaryRecruiterValue?.length > 0 ? secondaryRecruiterValue : null,
            search: e?.target?.value || null,
            filter_by: null,
            pagenumber: typeof e === "number" ? e : 1,
            pagesize: 12,
        }))
    }

    const getJobData = (e, sortby = false) => {
        if (
            companyValue?.length > 0 ||
            consultancyValue?.length > 0 ||
            severityValue?.length > 0 ||
            primaryRecruiterValue?.length > 0 ||
            secondaryRecruiterValue?.length > 0
        )
            setFilterApplied(true);
        else setFilterApplied(false);

        dispatch(
            GetAtsJobPostAction({
                company_data:
                    companyValue?.length > 0 ?
                        construcData(companyValue, "ats_company_id", "ats_company_id") : null,
                consultancy_data:
                    consultancyValue?.length > 0 ?
                        construcData(consultancyValue, "consultancy_id", "consultancy_id") : null,
                severity_data:
                    severityValue?.length > 0 ?
                        construcData(severityValue, "severity", "name") : null,
                primary_recruiter:
                    primaryRecruiterValue?.length > 0 ?
                        construcData(
                            primaryRecruiterValue,
                            "primary_recruiter_id",
                            "recruiter_id"
                        )
                        : null,
                secondary_recruiter:
                    secondaryRecruiterValue?.length > 0 ?
                        construcData(
                            secondaryRecruiterValue,
                            "secondary_recruiter_id",
                            "recruiter_id"
                        )
                        : null,
                search: e?.target?.value || searchValue,
                filter_by: null,
                pagenumber: typeof e === "number" ? e : 1,
                pagesize: 12,
                sort_column: null,
                status: "Inactive"
            })
        );

    };

    const handleCardClick = (e, value) => {
        setCurrentData(value);
        setCurrentMode("archive");
    };

    const handleCompanyChange = (data, event) => {
        if (event?.target?.checked) {
            const isDuplicate = companyValue.some(element => element.name === data.name);
            if (!isDuplicate) {
                setCompanyValue((res) => [...res, data]);
            }
            else {
                const updatedSeverityValue = companyValue.filter(element => element.name !== data.name);
                setCompanyValue(updatedSeverityValue);
            }
        } else {
            let comp = companyValue?.findIndex(
                (element) => element?.ats_company_id === data?.ats_company_id
            );
            companyValue.splice(comp, 1);
            setCompanyValue((res) => [...res]);
        }

    };
    const handleConsultancyChange = (data, event) => {
        if (event?.target?.checked) {
            setConsultancyValue((res) => [...res, data]);
        } else {
            let cons = consultancyValue?.findIndex(
                (element) => element?.consultancy_id === data?.consultancy_id
            );
            consultancyValue.splice(cons, 1);
            setConsultancyValue((res) => [...res]);
        }

    };
    const handlePrimaryRecruiterChange = (
        data,
        event,
    ) => {
        if (event?.target?.checked) {
            if (data?.recruiter_filter) {
                const isDuplicate = primaryRecruiterValue.some(element => element.name === data.name);
                if (!isDuplicate) {
                    setPrimaryRecruiterValue((res) => [...res, data?.recruiter_filter]);
                }
                else {
                    const updatedSeverityValue = primaryRecruiterValue.filter(element => element.name !== data.name);
                    setPrimaryRecruiterValue(updatedSeverityValue);
                }
            }
            else {
                setPrimaryRecruiterValue((res) => [...res, data]);
            }
        } else {
            let cons = primaryRecruiterValue?.findIndex(
                (element) => element?.recruiter_id === data?.recruiter_id
            );
            primaryRecruiterValue.splice(cons, 1);
            setPrimaryRecruiterValue((res) => [...res]);
        }

    };
    const handleSecondaryRecruiterChange = (
        data,
        event,
    ) => {
        if (event?.target?.checked) {
            setSecondaryRecruiterValue((res) => [...res, data]);
        } else {
            let pr = secondaryRecruiterValue?.findIndex(
                (element) => element?.recruiter_id === data?.recruiter_id
            );
            secondaryRecruiterValue.splice(pr, 1);
            setSecondaryRecruiterValue((res) => [...res]);
        }

    };

    const handleSeverityChange = (data, event) => {
        if (event?.target?.checked) {
            const isDuplicate = severityValue.some(element => element.name === data.name);
            if (!isDuplicate) {
                setSeverityValue((res) => [...res, data]);
            }
            else {
                const updatedSeverityValue = severityValue.filter(element => element.name !== data.name);
                setSeverityValue(updatedSeverityValue);
            }
        } else {
            let sr = severityValue?.findIndex(
                (element) => element?.name === data?.name
            );
            severityValue.splice(sr, 1);
            setSeverityValue((res) => [...res]);
        }

    };

    const handleClearFilter = () => {
        dispatch(AddAtsJobPostFillterAction({}))
        setFilterApplied(false);
        setCompanyValue([]);
        setConsultancyValue([]);
        setSeverityValue([]);
        setPrimaryRecruiterValue([]);
        setSecondaryRecruiterValue([]);
    };

    const handlePagination = (event, value) => {
        setPage(value)
        getJobData(value);
    };



    const CardFilter = (e, value) => {
        e.stopPropagation();
        if (['High', 'Medium', 'Low'].includes(value)) {
            handleSeverityChange({ 'name': value }, { 'target': { 'checked': true } })
        }
        if (value?.primary_recruiter) {
            handlePrimaryRecruiterChange({ 'recruiter_filter': { 'recruiter': value?.primary_recruiter?.[0]?.recruiter, 'recruiter_id': `${value?.primary_recruiter?.[0]?.recruiter_id}` } }, { 'target': { 'checked': true } })
        }
        if (value?.company) {
            const company = value?.company
            if (company === "Internal Job") {
                setSearchValue("internal")
            }
            const foundCompany = Object.values(atsCompanyData).find(obj => obj.name === company);
            if (foundCompany) {
                handleCompanyChange(foundCompany, { 'target': { 'checked': true } })
            } else {
                handleCompanyChange('', { 'target': { 'checked': false } })
            }
        }
    }
    const handleActive = (data) => {
        setCurrentJobData(data);
        setOpenDialog(!openDialog)
    }
    const handleConfirmArchive = () => {
        if(currentjobData?.ats_company_id){
            ValidateAtsCompConsulStatusHelper({company_id: currentjobData?.ats_company_id,consul_id: null}).then((res)=>{
                if(res?.data?.statuscode === 200 && res?.data?.data?.[0].fn_ats_comp_consul_status !== "Inactive" ){
                    UpdateAtsJobPostStatusHelper({
                        update_id: currentjobData?.ats_jobpost_id,
                        status: "Active",
                    }).then((res) => {
                        getJobData()
                    });
                }
                else{
                    toast.dismiss()
                    toast.error("Job is associated with an inactive Client. Please make the Client active first.")
                }

            })
        }
        else {
        UpdateAtsJobPostStatusHelper({
            update_id: currentjobData?.ats_jobpost_id,
            status: "Active",
        }).then((res) => {
            getJobData()
        });
    }
        setOpenDialog(false);
    };
    return (
        <>
            <Snackbar
                open={copyLink}
                onClose={() => setCopyLink(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {jobPostFormMode === "Card" && (
                    <Typography variant="h4">{company_full_name}</Typography>
                )}
            </Box>
            <>

                <Box sx={{ maxHeight: "85vh", overflowY: "auto", pb: 2 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid p={1.5} item md={6} sm={6}>
                            <Box sx={{ display: "flex" }}>
                                <Tooltip title="InActive Jobs">
                                    <Box>
                                        <CustomToggle
                                            isChecked={archived}
                                            setIsChecked={handleArchive}
                                            handleToggleChange={handleArchive}
                                            Yestext={'Inactive'}
                                            NoText={'Active'}
                                            style={{ height: '32px', padding: '0px', width: '145px', labelPadding: '1px' }}
                                        />
                                    </Box>
                                </Tooltip>
                                <Box sx={{ mt: -0.8 }}>
                                    {search ?
                                        <>
                                            <ClickAwayListener onClickAway={() => { searchValue === '' && handleSearch() }}>
                                                <Fade in={search} >
                                                    <TextField
                                                        variant="outlined"
                                                        type={"text"}
                                                        value={searchValue}
                                                        onChange={(e) => { getJobData(e); setSearchValue(e.target.value) }}
                                                        id="note"
                                                        size="small"
                                                        label="Search Jobs"
                                                    />
                                                </Fade>
                                            </ClickAwayListener>
                                        </>
                                        :
                                        <Tooltip title="Search Jobs">
                                            <IconButton onClick={handleSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {filterApplied && (
                        <>
                            <Typography>
                                {atsJobPostData?.[0]?.totalcount} Job Found
                            </Typography>
                            <AppliedFilter
                                companyValue={companyValue}
                                handleCompanyChange={handleCompanyChange}
                                consultancyValue={consultancyValue}
                                handleConsultancyChange={handleConsultancyChange}
                                severityValue={severityValue}
                                handleSeverityChange={handleSeverityChange}
                                handleClearFilter={handleClearFilter}
                                primaryRecruiterValue={primaryRecruiterValue}
                                secondaryRecruiterValue={secondaryRecruiterValue}
                                handlePrimaryRecruiterChange={handlePrimaryRecruiterChange}
                                JobFilters={JobFilters}
                                handleSecondaryRecruiterChange={
                                    handleSecondaryRecruiterChange
                                }
                            />
                        </>
                    )}

                    {isLoading ? (
                        <Grid container direction="row" alignItems="center">
                            {Array(8)
                                .fill()
                                .map((data, index) => (
                                    <Grid key={index} item p={1.5} lg={3} md={6} sm={6} xs={12}>
                                        <Card>
                                            <CardHeader
                                                action={null}
                                                title={
                                                    <Skeleton
                                                        animation="wave"
                                                        height={10}
                                                        width="80%"
                                                        style={{ marginBottom: 6 }}
                                                    />
                                                }
                                                subheader={
                                                    <Skeleton
                                                        animation="wave"
                                                        height={10}
                                                        width="40%"
                                                        style={{ marginBottom: 6 }}
                                                    />
                                                }
                                            />
                                            <Skeleton
                                                sx={{ height: 130 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                            <CardContent>
                                                <>
                                                    <Skeleton
                                                        animation="wave"
                                                        height={10}
                                                        style={{ marginBottom: 6 }}
                                                    />
                                                    <Skeleton
                                                        animation="wave"
                                                        height={10}
                                                        width="80%"
                                                    />
                                                </>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                        </Grid>
                    ) : atsJobPostData?.length > 0 ? (
                        <Grid container direction="row" alignItems="center">
                            {atsJobPostData?.map((job, index) => (
                                <Grid key={index} item p={1.5} lg={3} md={6} sm={6} xs={12}>
                                    <Card key={index}>
                                        <CardActionArea
                                            sx={{ ":hover": { backgroundColor: "#f1e3f3" } }}
                                            onClick={(e) => handleCardClick(e, job)}
                                        >
                                            <CardHeader
                                                sx={{ p: 2 }}
                                                action={
                                                    job?.priority && (
                                                        <Tooltip title="Severity" onClick={(e) => CardFilter(e, job?.priority)}>
                                                            {job?.priority === "High" ? (
                                                                <Chip
                                                                    label="High"
                                                                    name="Severity"
                                                                    color="error"
                                                                    size="small"
                                                                    icon={<KeyboardArrowUpIcon />}
                                                                />
                                                            ) : job?.priority === "Low" ? (
                                                                <Chip
                                                                    label="Low"
                                                                    name="Severity"
                                                                    color="info"
                                                                    size="small"
                                                                    icon={<KeyboardArrowDownIcon />}
                                                                />
                                                            ) : job?.priority === "Medium" ? (
                                                                <Chip
                                                                    label="Medium"
                                                                    name="Severity"
                                                                    color="warning"
                                                                    size="small"
                                                                    icon={<DragHandleIcon />}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Tooltip>
                                                    )
                                                }
                                                title={
                                                    <Tooltip title={job?.title}>
                                                        <Typography className="truncate" variant="h6">
                                                            {job?.title}
                                                        </Typography>
                                                    </Tooltip>
                                                }
                                                subheader={
                                                    <Tooltip title="Job ID">
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            {job?.job_id}
                                                        </Typography>
                                                    </Tooltip>
                                                }
                                            />
                                            <CardContent sx={{ padding: "0px 10px 10px 10px" }}>
                                                <Grid container sx={{ textAlign: "center" }}>
                                                    <Grid item xs={3} md={6} lg={3}>
                                                        <Typography variant="h4" ml={1}>
                                                            {job?.vaccancies || 0}
                                                        </Typography>
                                                        <Typography
                                                            ml={1}
                                                            fontWeight={600}
                                                            fontSize="11px"
                                                        >
                                                            Required
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} md={6} lg={3}>
                                                        <Typography variant="h4" ml={1}>
                                                            {job?.proposed_cand || 0}
                                                        </Typography>
                                                        <Typography
                                                            ml={1}
                                                            fontWeight={600}
                                                            fontSize="11px"
                                                        >
                                                            Proposed
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} md={6} lg={3}>
                                                        <Typography variant="h4" ml={1}>
                                                            {job?.selected_cand || 0}
                                                        </Typography>
                                                        <Typography
                                                            ml={1}
                                                            fontWeight={600}
                                                            fontSize="11px"
                                                        >
                                                            Selected
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3} md={6} lg={3}>
                                                        <Typography variant="h4" ml={1}>
                                                            {job?.remaining_cand || 0}
                                                        </Typography>
                                                        <Typography
                                                            ml={1}
                                                            fontWeight={600}
                                                            fontSize="11px"
                                                        >
                                                            Remaining
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Box
                                                    mt={1}
                                                    mr={1}
                                                    display="flex"
                                                    justifyContent="space-between"
                                                >
                                                    <Box
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        <AccountBalanceIcon fontSize="small" />
                                                        <Tooltip
                                                            title={
                                                                job?.is_internal
                                                                    ? "Internal Job"
                                                                    : job?.company
                                                            }
                                                            onClick={(e) => CardFilter(e, { 'company': job?.is_internal ? "Internal Job" : job?.company })}
                                                        >
                                                            <Typography
                                                                color="#637381"
                                                                variant="body2"
                                                                pl={1}
                                                            >
                                                                {job?.is_internal
                                                                    ? "Internal Job"
                                                                    : job?.company}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                    {job?.primary_recruiter && (
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <PersonIcon fontSize="small" />
                                                            <Tooltip title={job?.primary_recruiter || ""}>
                                                                <Typography
                                                                    className="truncate"
                                                                    color="#637381"
                                                                    variant="body2"
                                                                    pl={1}
                                                                >
                                                                    {job?.primary_recruiter}
                                                                </Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                                </Box>
                                                {job?.precruiter && (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                        }}
                                                    >
                                                        <PersonIcon fontSize="small" sx={{ mt: 1 }} />
                                                        <Tooltip onClick={(e) => CardFilter(e, { 'primary_recruiter': job?.precruiter })} title={"Primary Recruiters"}>
                                                            <Typography
                                                                color="#637381"
                                                                variant="body2"
                                                                sx={{ mt: 1 }}
                                                                pl={1}
                                                            >
                                                                {job?.precruiter
                                                                    .map((row) => row.recruiter)
                                                                    ?.join(", ")}
                                                            </Typography>
                                                        </Tooltip>
                                                    </Box>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                        <Divider variant="middle" />
                                        <CardActions sx={{ padding: "0px" }}>
                                            <Box
                                                sx={{
                                                    padding: "0px 20px 8px 20px",
                                                    display: "flex",
                                                    WebkitBoxPack: "justify",
                                                    justifyContent: "center",
                                                    width: "100%",
                                                }}
                                            >
                                                <Tooltip title="Enable Job">
                                                    <IconButton onClick={() => handleActive(job)}>
                                                        <CheckCircleOutlineIcon color="success" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <NoData />
                    )}
                    {atsJobPostData?.[0]?.totalcount > 12 && (
                        <Pagination
                            sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                            showFirstButton
                            showLastButton
                            count={
                                Number.isInteger(atsJobPostData?.[0]?.totalcount / 12)
                                    ? atsJobPostData?.[0]?.totalcount / 12
                                    : Math.round(atsJobPostData?.[0]?.totalcount / 12 + 0.5) ||
                                    0
                            }
                            page={page}
                            color="secondary"
                            onChange={(event, value) => handlePagination(event, value)}
                        />
                    )}

                </Box>
            </>
            <ConfirmationDialog
                title="Unarchive"
                content="Are you sure want to Activate this Job?"
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Job"
                OkButtonText="Unarchive"
                onSubmit={handleConfirmArchive}
            />
        </>
    );
};

const AppliedFilter = (props) => {
    const {
        companyValue,
        handleCompanyChange,
        consultancyValue,
        handleConsultancyChange,
        severityValue,
        handleSeverityChange,
        primaryRecruiterValue,
        secondaryRecruiterValue,
        handlePrimaryRecruiterChange,
        handleSecondaryRecruiterChange,
        handleClearFilter,
        JobFilters,
    } = props;
    let filterStyle = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "8px",
        paddingRight: "8px",
        lineHeight: 1.57143,
        fontSize: "0.875rem",
        color: " #000000",
        backgroundColor: PRIMARY.dark,
        borderRight: "1px solid rgba(145, 158, 171, 0.24)",
    };
    let filterStyle1 = {
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        margin: "4px",
        borderRadius: "8px",
        overflow: "hidden",
        border: "1px solid rgba(145, 158, 171, 0.24)",
    };

    return (
        <div
            style={{
                display: "flex",
                flexFlow: "row wrap",
                flexGrow: 1,
                alignItems: "center",
            }}
        >
            {companyValue?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Client</Typography>
                    <div
                        style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
                    >
                        {companyValue?.map((comp, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={comp?.name}
                                size="small"
                                onDelete={(e) => { handleCompanyChange(comp, e); JobFilters() }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {consultancyValue?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Hiring Partner</Typography>
                    <div
                        style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
                    >
                        {consultancyValue?.map((cons, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={cons?.name}
                                size="small"
                                onDelete={(e) => { handleConsultancyChange(cons, e); JobFilters() }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {primaryRecruiterValue?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Primary</Typography>
                    <div
                        style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
                    >
                        {primaryRecruiterValue?.map((pr, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={pr?.recruiter}
                                size="small"

                                onDelete={(e) => { handlePrimaryRecruiterChange(pr, e); JobFilters() }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {secondaryRecruiterValue?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Secondary</Typography>
                    <div
                        style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
                    >
                        {secondaryRecruiterValue?.map((sr, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={sr?.recruiter}
                                size="small"
                                onDelete={(e) => { handleSecondaryRecruiterChange(sr, e); JobFilters() }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {severityValue?.length > 0 && (
                <div style={filterStyle1}>
                    <Typography style={filterStyle}>Severity</Typography>
                    <div
                        style={{ display: "flex", flexFlow: "row wrap", padding: "6px" }}
                    >
                        {severityValue?.map((s, index) => (
                            <Chip
                                key={index}
                                sx={{ margin: "4px" }}
                                label={s?.name}
                                size="small"
                                onDelete={(e) => { handleSeverityChange(s, e); JobFilters() }}
                            />
                        ))}
                    </div>
                </div>
            )}
            {(companyValue?.length > 0 ||
                consultancyValue?.length > 0 ||
                severityValue?.length > 0 ||
                primaryRecruiterValue?.length > 0 ||
                secondaryRecruiterValue?.length > 0) && (
                    <Button
                        color="error"
                        variant="text"
                        startIcon={<DeleteIcon />}
                        onClick={handleClearFilter}
                    >
                        Delete
                    </Button>
                )}
        </div>
    );
};
export default AtsArchivedJobPost;
