import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import Employee from "./app/Components/Employee/views/Employee";
import MyEmployeeAccess from "./app/Components/Employee/views/MyEmployeeAccess"; 
// import Home from "./app/Components/Home/views/Home";
import AdminSettings from "./app/Components/AdminSettings/views/AdminSettings";
import LandingPage from "./app/Components/LandingPage/views/LandingPage";
//Website
import WebsiteHomePage from "./app/Components/Website/pages/LandingPage";
import Contactus from "./app/Components/Website/pages/Contact";
import DownTime from "./app/Components/Website/pages/DownTime";

//End
// import LeaveLandingPage from "./app/Components/Leave/views/LeaveLandingPage";
import MyEmployee from "./app/Components/MyEmployee/views/MyEmployee";

import { signOut } from "./app/Components/SignIn/helper/SigninHelper";
import PrivateRoute from "./PrivateRoute";
import RoleAndAccess from "./app/Components/RoleAndAccess/views/RoleAndAccess";
import Onboarding from "./app/Components/Onboarding/views/Onboarding";
import PayroleLandingPage from "./app/Components/Finance/views/Payrole/PayroleLandingPage";
import ReportsLandingPage from "./app/Components/Finance/views/Reports/ReportsLandingPage";
import TaxLandingPage from "./app/Components/Finance/views/TaxDeclaration/TaxLandingPage";
import OnboardingLandingPage from "./app/Components/OnboardingSettings/views/OnboardingLandingPage";
import AdminLandingPage from "./app/Components/MeeplAdmin1/views/AdminLandingPage";

import MeeplAdminLandingPage from "./app/Components/MeeplAdmin/views/MeeplAdminLandingPage";
import MeeplAdminSiginPage from "./app/Components/MeeplAdmin/views/MeeplAdminSiginPage";

import FinanceSettingsLandingPage from "./app/Components/FinanceSettings/views/FinanceSettingsLandingPage";
import AtsDashboard from "./app/Components/ATS/views/Dashboard/AtsDashboard";
import AtsCompanyLandingPage from "./app/Components/ATS/views/ATSCompany/AtsCompanyLandingPage";
import AtsCompanyGroupLandingPage from "./app/Components/ATS/views/ATSGroupCompany/AtsGroupCompanyLandingPage";
import AtsCandidateLandingPage from "./app/Components/ATS/views/ATSCandidate/AtsCandidateLandingPage";
import AtsConsultancyLandingPage from "./app/Components/ATS/views/ATSConsultancy/AtsConsultancyLandingPage";
import AtsJobPostLandingPage from "./app/Components/ATS/views/ATSJobPost/AtsJobPostLandingPage";
import AtsUserLandingPage from "./app/Components/ATS/views/ATSUser/AtsUserLandingPage";
import ForgotPassword from "./app/Components/SignIn/views/ForgotPassword";
import ChangePassword from "./app/Components/SignIn/views/ChangePassword";
import AtsCandidatePortal from "./app/Components/ATS/views/ATSCandidate/AtsCandidatePortal";
import SignIn from "./app/Components/SignIn/views/SignIn";
import ForgotWorkspace from "./app/Components/SignIn/views/Forgotworkspace";
import AtsCareerPortalCandidateForm from "./app/Components/ATS/views/AtsCareerPortal/AtsCareerPortalCandidate";
import CareePortalJob from "./app/Components/ATS/views/AtsCareerPortal/AtsCareerPortalJob";
import CareePortalJobs from "./app/Components/ATS/views/AtsCareerPortal/AtsCareerPortalJobs";
import ATSGenerateInvoice from "./app/Components/ATS/views/ATSInvoice/ATSGenerateInvoice";
import AtsSwitchWorkspace from "./app/Components/ATS/views/Dashboard/AtsSwitchWorkSpace";
import AtsReportLandingPage from "./app/Components/ATS/views/AtsReports/AtsReportLandingPage";
import AtsReportDashboardPage from "./app/Components/ATS/views/AtsReports/Dashboard/AtsReportDashboardPage";
import AtsReportRevenuePage from "./app/Components/ATS/views/AtsReports/Revenue/AtsReportRevenuePage";
import AtsCandidateExportLandingPage from "./app/Components/ATS/views/AtsCandidateExport/AtsCandidateExportLandingPage";
import TaskLandingPage from "./app/Components/ATS/views/ATSTask/TaskLandingPage";
import AtsSettings from "./app/Components/ATSSettings/views/AtsSettingsLandingPage";
import RequestDemo from "./app/Components/SignUp/views/RequestDemo";
import AtsCandidateFilters from "./app/Components/ATS/views/ATSCandidate/CandidateFilter/AtsCandidateFilters";
import AISuggestion from "./app/Components/ATS/views/ATSJobPost/Kanban/AISuggestion";
import AtsJobPostForm from "./app/Components/ATS/views/ATSJobPost/AtsJobPostForm";
import FormResponse from "./app/Components/ATSSettings/views/FormBuilder/FormResponse";



//Leave
import DashboardLandingPage from "./app/Components/MyTime/views/DashboardLandingPage";
import TeamLandingPage from "./app/Components/MyTime/views/Team/TeamLandingPage";
import MyTimesheetLandingPage from "./app/Components/MyTime/views/MyTimeSheet/MyTimeSheetLandingPage";


import ConfigureLandingPage from "./app/Components/Configure/views/ConfigureLandingPage";
import Onboardemployee from "./app/Components/ConfigureEmployee/views/ConfigureEmployeeLandingPage";
import PreScreenResponse from "./app/Components/ATSSettings/views/PreScreen/PreScreenResponse";
import PreScreenResponses from "./app/Components/ATS/views/ATSJobPost/PreScreenResponses";
import FeedBackResponse from "./app/Components/ATS/views/ATSJobPost/FeedBackResponse";
import GiveFeedback from "./app/Components/ATSSettings/views/FormBuilder/GiveFeedback";
import MyProfile from "./app/Components/Home/views/MyProfile";
import ViewOffer from "./app/Components/ATS/views/OfferLetter/ViewOffer";
import OfferApproval from "./app/Components/ATS/views/OfferLetter/OfferApproval";
import OfferAccept from "./app/Components/ATS/views/OfferLetter/OfferAccept";


// import ComingSoon from "./app/Components/Base/views/ComingSoon";




function Routes() {
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      signOut();
    });
    return () => window.removeEventListener("beforeunload", () => { });
  });
  return (
    <Switch>
      <React.Fragment>
        {/* Authentication */}
        <Route exact path="/signup" component={LandingPage} />

        <Route exact path="/forgotWorkspace" component={ForgotWorkspace} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/changePassword/:token" component={ChangePassword} />
        <Route exact path="/signin/:info" component={SignIn} />
        <Route exact path="/requestDemo" component={RequestDemo} />

        {/* Website */}
        <Route exact path="/" component={WebsiteHomePage} />
        <Route exact path="/ContactUs" component={Contactus} />
        <Route exact path="/DownTime" component={DownTime} />

        {/* Core HR */}
        {/* <PrivateRoute exact path="/home" component={Home} /> */}
        <PrivateRoute exact path="/home" component={MyProfile} />
        {/* <PrivateRoute exact path="/leave" component={LeaveLandingPage} /> */}
        {/* <PrivateRoute exact path="/leave" component={ComingSoon} /> */}
        <PrivateRoute exact path="/employee" component={Employee} />
        <PrivateRoute exact path="/Onboardemployee" component={MyEmployee} />
        <PrivateRoute exact path="/myemployee" component={MyEmployee} />
        <PrivateRoute exact path="/onboarding" component={Onboarding} />
        <PrivateRoute exact path="/myemployee-access" component={MyEmployeeAccess} />
        <PrivateRoute exact path="/employee/Onboardemployee/" component={Onboardemployee} />


          {/* Leave */}
        <PrivateRoute exact path="/lms/manage-dashboard" component={DashboardLandingPage} />
        <PrivateRoute exact path="/lms/manage-timesheet" component={MyTimesheetLandingPage} />
        <PrivateRoute exact path="/lms/manage-team" component={TeamLandingPage} />

        <PrivateRoute exact path="/configure-setting" component={ConfigureLandingPage} />
        {/* Finance */}
        <PrivateRoute exact path="/payrole" component={PayroleLandingPage} />
        <PrivateRoute exact path="/finance-report" component={ReportsLandingPage} />
        <PrivateRoute exact path="/tax-declaration" component={TaxLandingPage} />

        {/* Settings */}
        <PrivateRoute exact path="/settings" component={AdminSettings} />
        <PrivateRoute exact path="/role-access" component={RoleAndAccess} />
        <PrivateRoute exact path="/onboarding-settings" component={OnboardingLandingPage} />
        <PrivateRoute exact path="/finance-settings" component={FinanceSettingsLandingPage} />

        {/* Admin */}
        <PrivateRoute exact path="/admin" component={AdminLandingPage} />
        <PrivateRoute exact path="/meepl-admin" component={MeeplAdminLandingPage} />
        <Route exact path="/admin-signin" component={MeeplAdminSiginPage} />
        {/* ATS */}
        <PrivateRoute exact path="/ats/dashboard" component={AtsDashboard} />
        <PrivateRoute exact path="/ats/manage-job" component={AtsJobPostLandingPage} />
        <PrivateRoute exact path="/ats/add-job/" component={AtsJobPostForm} />
        <PrivateRoute exact path="/ats/manage-company" component={AtsCompanyLandingPage} />
        <PrivateRoute exact path="/ats/manage-group-company" component={AtsCompanyGroupLandingPage} />
        <PrivateRoute exact path="/ats/manage-candidate" component={AtsCandidateLandingPage} />
        <PrivateRoute exact path="/ats/filter-candidate" component={AtsCandidateFilters} />
        <PrivateRoute exact path="/ats/manage-user" component={AtsUserLandingPage} />
        <PrivateRoute exact path="/ats/manage-consultancy" component={AtsConsultancyLandingPage} />
        <PrivateRoute exact path="/ats/invoice" component={ATSGenerateInvoice} />
        <PrivateRoute exact path="/ats/switchworkspace/" component={AtsSwitchWorkspace} />
        <PrivateRoute exact path="/ats/manage-reports/" component={AtsReportDashboardPage} />
        <PrivateRoute exact path="/ats/reports/" component={AtsReportLandingPage} />
        <PrivateRoute exact path="/ats/candidate-export/" component={AtsCandidateExportLandingPage} />
        <PrivateRoute exact path="/ats/revenue/" component={AtsReportRevenuePage} />
        <PrivateRoute exact path="/ats/tasks/" component={TaskLandingPage} />
        <PrivateRoute exact path="/ats/ats-settings/" component={AtsSettings} />
        <PrivateRoute exact path="/ats/suggest-candidate/:job_id" component={AISuggestion} />


        <Route exact path="/ats/add-candidate/:guid" component={AtsCandidatePortal} />
        <Route exact path="/ats/career-portal/candidate/:domain" component={AtsCareerPortalCandidateForm} />
        <Route exact path="/ats/career-portal-job/:jobid/:domain" component={CareePortalJob} />
        <Route exact path="/ats/career-portal-jobs/:jobid/:domain" component={CareePortalJobs} />
        <Route exact path="/ats/feedback-response/:guid" component={FormResponse} />
        <Route exact path="/ats/give-feedback/" component={GiveFeedback} />
        <Route exact path="/ats/feedback-responses/:guid" component={FeedBackResponse} />
        <Route exact path="/ats/prescreen-response/:guid" component={PreScreenResponse} />
        <Route exact path="/ats/prescreen-responses/:guid" component={PreScreenResponses} />
        <Route exact path="/ats/view-offer/:guid" component={ViewOffer} />
        <Route exact path="/ats/offer-approval/:guid" component={OfferApproval} />
        <Route exact path="/ats/offer-accept/:guid" component={OfferAccept} />
       
      </React.Fragment>
    </Switch>
  );
}

export default Routes;
