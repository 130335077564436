import { useEffect } from "react";
import MaterialTable from "material-table";
import {
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from "react";
import { useDispatch } from "react-redux";
import Base from "../../Base/views/Base";
import { GetEmployeesAction } from "../../../../store/Employee/Employee/Action";
import RoleandAccess from "./RoleandAccess/RoleandAccess";

const MyEmployeeAccess= ({ }) => {
    const dispatch = useDispatch();
    const [currentMode, setCurrentMode] = useState("grid");
    const [currentData, setCurrentData] = useState("")
    const { employees_options } = useSelector(
        (state) => state?.EmployeeReducer.EmployeesReducer
      );
  useEffect(() => {
    dispatch(GetEmployeesAction({ is_active: true }));
  }, []);
 
  const hanldeEdit = (data) => {
    setCurrentMode("edit");
    setCurrentData(data);
  };
  return (
    <>
    <Base>
      <Box sx={{ maxHeight: "87vh", overflowY: "auto" }}>

      {
                    currentMode === "grid" ?
                    <>
      <MaterialTable
        onRowClick={(event, rowData) => hanldeEdit(rowData)}
          columns={[
            {
              title: "Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "name",
              grouping: false,
            },
            {
              title: "Email",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "office_email",
              grouping: false,
            },
            {
              title: "Designation",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "designation",
            },
            {
              title: "User Type",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "core_hr_user_type",
            },
            {
              title: "Core HR & LMS", field: "enable_corehr",headerStyle:{ textAlign:"center"}, cellStyle: { padding: "10px 10px 10px 13px", textAlign:"center" },
              render: rowData => {
                  return rowData.enable_corehr ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                      : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
              }
          },
          {
            title: "ATS", field: "enable_ats", headerStyle:{ textAlign:"center"}, cellStyle: { padding: "10px 10px 10px 13px",textAlign:"center" },
            render: rowData => {
                return rowData.enable_ats ? <TaskAltIcon sx={{ color: "rgb(86, 202, 0)" }} />
                    : <HighlightOffIcon sx={{ color: "rgb(255, 76, 81)" }} />
            }
        },
        
          ]}
          data={employees_options}
          options={{
            maxBodyHeight: "65vh",
            minBodyHeight: "65vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: { padding: "10px 10px 10px 13px", fontWeight: "bold" },
            sorting: false,
            search: true,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: false,
            exportFileName: "Employees",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
          }}
          
          title="Active Employees"
        />
        </> : <>
        <RoleandAccess setCurrentMode={setCurrentMode} currentMode={currentMode} OnboardingEmpData={currentData} />
        </>
}
      </Box>
      </Base>
    </>
  );
};

export default MyEmployeeAccess;