import { Button, FormControl, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "../../../Base/views/ModalPopUpForm"
import CommonDatePicker from "../../../Base/views/CommonDatePicker"
import { useDispatch } from "react-redux";
import FileUploader from "../../../ATS/views/ATSCandidate/FileUploader";
import { APP_NAME } from "../../../../Constants/baseConstant";
import { convertBase64, getUserData, s3Client, } from "../../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { toast } from "react-toastify";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { AddEmployeeWorkExperienceAction, UpdateEmployeeWorkExperienceAction } from "../../../../../store/Employee/ProfessionalSummary/Action";

export default function WorkExperienceModalForm(props) {
    const dispatch = useDispatch()
    const initialFValues = {
        previous_employer: "",
        dojoining: "",
        doleaving: "",
        role_played: "",
        proof:""
    };
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState([]);
    const [fileUrl, setFileUrl] = useState("");
    const [workExperienceModalData, setWorkExperienceModalData] = useState(initialFValues);
    const { addMode, editedRecord, setOpenPopup } = props;
    const userData = getUserData();

    useEffect(() => {
        if (editedRecord != null)
            setWorkExperienceModalData({
                ...editedRecord,
            });
        //eslint-disable-next-line
    }, [editedRecord]);
    useEffect(() => {
        workExperienceModalData?.proof && getResumeSignedUrl();
    }, [workExperienceModalData]);

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("previous_employer" in fieldValues) {
            temp.previous_employer = fieldValues.previous_employer === "" ? "Name of the Previous Employer is required" : "";
        }
        if ("role_played" in fieldValues) {
            temp.role_played = fieldValues.role_played === "" ? "Role Played is required" : "";
        }
        if ("dojoining" in fieldValues) {
            temp.dojoining = fieldValues.dojoining === "" ? "Date of Joining is required" : "";
        }
        if ("doleaving" in fieldValues) {
            temp.doleaving = fieldValues.doleaving === "" ? "Date of Leaving is required" : "";
        }
        if ("proof" in fieldValues) {
            temp.proof = (files.length === 0 && !workExperienceModalData.proof) ? "Document proof is required" : "";
        }
        if (!files?.length && !fileUrl) {
            temp.proof = "Proof upload is required";
        } else {
            temp.proof = "";
        }
        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };
    const fileUploaded = ()=>{
        let temp = { ...errors };
        temp.proof = "";
        setErrors({
            ...temp,
        });
    }

    const handleInputChange = (event, value) => {
        setWorkExperienceModalData({ ...workExperienceModalData, [event.target.name]: event.target.value });
        Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
            validate({ ...workExperienceModalData, [event.target.name]: event.target.value })
    }

    const HandleSubmit = async (e) => {
        e.preventDefault();
        if (validate(workExperienceModalData)) {
            addMode(resetForm);
            let b64string = "";
            let filename = "";
            let old_proof = "";
            let content_type = "";
    
            if (files?.[0]) {
                b64string = await convertBase64(files?.[0]);
                let ext = files?.[0]?.name?.split(".").at(-1);
                content_type = ext === "pdf" ? "application/pdf" : "application/msword";
                filename = `${userData?.domain}/Employee/WorkExpDocument/` + uuidv4() + "." + ext;
                if (workExperienceModalData?.proof) {
                    old_proof = workExperienceModalData?.proof;
                }
            } else {
                filename = workExperienceModalData?.proof;
            }
    
            if (workExperienceModalData?.emp_workexp_id) {
                dispatch(UpdateEmployeeWorkExperienceAction({
                    ...workExperienceModalData,
                    proof: filename,
                    proof_name: b64string,
                    old_proof: old_proof,
                    content_type: content_type,
                }));
            } else {
                dispatch(AddEmployeeWorkExperienceAction({
                    ...workExperienceModalData,
                    proof: filename,
                    proof_name: b64string,
                    content_type: content_type,
                }));
            }
    
            setOpenPopup(false);
        }
    };
    
    const getResumeSignedUrl = async () => {
        let filename = workExperienceModalData?.proof;
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: filename,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          );
          setFileUrl(url);
          return url;
        } catch (err) {
          toast.error(err);
        }
      };
    const handleDownloadResume = async (event, data) => {
        event.stopPropagation();
        let filename = data?.proof;
        const bucketParams = {
          Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
          Key: filename,
        };
        try {
          const url = await getSignedUrl(
            s3Client,
            new GetObjectCommand(bucketParams),
            { expiresIn: 3 * 60 }
          ); // Adjustable expiration.
          window.open(url, "_blank");
          return url;
        } catch (err) {
          toast.error(err);
        }
      };

    const resetForm = () => {
        setWorkExperienceModalData(initialFValues);
        setErrors({});
    };

    return (
        <Form onSubmit={HandleSubmit}>
            <Grid container>
                <Grid container>
                    <Grid key="previous_employer" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                autoFocus={true}
                                required
                                size="small"
                                value={workExperienceModalData.previous_employer}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="previous_employer"
                                label="Name of the Previous Employer"
                                name="previous_employer"
                                autoComplete="previous_employer"
                                {...(errors.previous_employer && {
                                    error: true,
                                    helperText: errors.previous_employer,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="dojoining" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <CommonDatePicker
                                size="small"
                                required
                                name="dojoining"
                                label="Date of Joining"
                                pickerType="date"
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={workExperienceModalData?.dojoining}
                                handleChange={(value) => handleInputChange({ target: { name: "dojoining", value: `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` } })}
                                {...(errors.dojoining && {
                                    error: true,
                                    helperText: errors.dojoining,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="doleaving" item xs={12} md={6} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <CommonDatePicker
                                minDate={workExperienceModalData?.dojoining ? new Date(workExperienceModalData.dojoining) : ""}
                                maxDate={new Date()}
                                size="small"
                                required
                                name="doleaving"
                                label="Last Working Day"
                                pickerType="date"
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={workExperienceModalData?.doleaving}
                                handleChange={(value) => handleInputChange({ target: { name: "doleaving", value: `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` } })}
                                {...(errors.doleaving && {
                                    error: true,
                                    helperText: errors.doleaving,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="role_played" item xs={12} md={6} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                required
                                value={workExperienceModalData.role_played}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="role_played"
                                label="Role Played"
                                name="role_played"
                                autoComplete="role_played"
                                {...(errors.role_played && {
                                    error: true,
                                    helperText: errors.role_played,
                                })}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                
                <Grid key="expDocument" item xs={12} md={5} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                    <Grid onChange={fileUploaded} sx={{ textAlign:"center",width:"155%", border: "2px", padding: "20px", borderRadius: "10px", borderStyle: "dashed", backgroundColor: "primary", borderColor: "grey[500]" }}>
                        <FileUploader
                            fileUrl={fileUrl}
                            setFileUrl={setFileUrl}
                            files={files}
                            setFiles={setFiles}
                            filename={`WorkExperience_${APP_NAME}`}
                            filepath={workExperienceModalData?.proof}
                            handleDownloadResume={handleDownloadResume}
                        />
                    </Grid>
                    {errors.proof&&<div style={{color:'red', paddingTop:'5px'}}>{errors.proof}</div>}
                </Grid>
                <Grid container sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button
                        sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                        variant="outlined"
                        onClick={() => setOpenPopup(false)}
                        className="mpl-secondary-btn"
                    >
                        cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                        {editedRecord?.emp_workexp_id ? "Update" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}
