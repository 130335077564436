import { Box, Button, Card, CardContent, CardHeader, Collapse, FormControl, Grid, IconButton, MenuItem, Pagination, Select, Skeleton, Tooltip, Typography } from "@mui/material";
import SelectableSearch from "../../../../Base/views/SelectableSearch";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from "react";
import Base from "../../../../Base/views/Base";
import { GetAtsCandidateAction, ProposeCandidateConfirmationAction } from "../../../../../../store/ATS/Candidate/Action";
import { useDispatch, useSelector } from "react-redux";
import { DownloadAttachment } from "../../../../Base/helper/baseFunctions";
import history from "../../../../../../history";
import { NoData } from "../../../../Base/views/NotAuthorized";
import AtsCandidateForm from "../AtsCandidateForm";
import AppliedFilter from "./AppliedFilter";
import { GetCandidateFilterHelper } from "../../../helper/Ancillary/CandidateFilterHelper";
import { GetATSModuleFeaturesAction } from "../../../../../../store/RoleAccess/ATSModuleFeatures/Action";
import ArrowBackButton from "../../../../Base/views/ArrowBackButton";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { AddAtsJobKanbanHelper } from "../../../helper/AtsJobKanbanHelper";
import ProposeCandidateForm from "../ProposeCandidateForm";
import Popup from "../../../../Base/views/Popup";


const AtsCandidateFilters = ({ ai, currentJobData }) => {
    const initialValues = {
        primary_skill: [],
        secondary_skill: [],
        location: [],
        notice_period: [],
        yoe: [],

    }
    const dispatch = useDispatch()
    const { atsCandidateData, isLoading } = useSelector(state => state.AtsReducer?.AtsCandidateReducer);
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    const [filteredData, setFilteredData] = useState(initialValues);
    const [candidateData, setCandidateData] = useState("");
    const [primaryskilldata, setPrimarySkillData] = useState({ load: false, values: [] });
    const [secondaryskilldata, setSecondarySkillData] = useState({ load: false, values: [] });
    const [location, setLocation] = useState({ load: false, values: [] });
    const [openPopup, setOpenPopup] = useState(false);
    const [view, setView] = useState(true);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(GetAtsCandidateAction({
            pagenumber: 1,
            pagesize: 12,
            job_post_id:currentJobData?.ats_jobpost_id
        }));
        dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Candidate" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getCandidate = (value) => {
        dispatch(
            GetAtsCandidateAction({
                location: filteredData?.location?.length > 0 && filteredData?.location?.[0].location !== "Pan India" ? JSON.stringify(filteredData?.location) : null,
                yoe: filteredData?.yoe?.length > 0 ? filteredData?.yoe : null,
                notice_period: filteredData?.notice_period?.length > 0 ? filteredData?.notice_period : null,
                primary_skill: filteredData?.primary_skill?.length > 0 ? JSON.stringify(filteredData?.primary_skill) : null,
                secondary_skill: filteredData?.secondary_skill?.length > 0 ? JSON.stringify(filteredData?.secondary_skill) : null,
                pagenumber: typeof value === "number" ? value : 1,
                pagesize: 12,
                job_post_id:currentJobData?.ats_jobpost_id
            }));
    }

    useEffect(() => {
        if (isDeleted) {
            getCandidate();
            setIsDeleted(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleted]);

    const handlePagination = (event, value) => {
        setPage(value)
        getCandidate(value);
    };

    const toggleDrawer = (open, data) => {
        setShowForm(true);
        setFormData(data);
    };

    const handlePrimarySkillLoad = () => {
        GetCandidateFilterHelper({ data: "primary_skill" }).then((res) => {
            if (res?.data?.statuscode === 200) {
                setPrimarySkillData({ load: false, values: res?.data?.data?.[0]?.filterdata })
            }
        });
    };

    const handleSecondarySkillLoad = () => {
        GetCandidateFilterHelper({ data: "secondary_skill" }).then((res) => {
            if (res?.data?.statuscode === 200) {
                setSecondarySkillData({ load: false, values: res?.data?.data?.[0]?.filterdata })
            }
        });
    };

    const handleLocationLoad = () => {
        GetCandidateFilterHelper({ data: "location" }).then((res) => {
            if (res?.data?.statuscode === 200) {
                const locationArray = res?.data?.data?.[0]?.filterdata;
                const updatedLocationArray = [{ location: 'Pan India' }, ...locationArray];
                setLocation({ load: false, values: updatedLocationArray });
            }
        });
    };

    const handleViewFilter = () => {
        setView(!view)
    }

    const handleFilter = (event, value, deleteFilter) => {
        if (["primary_skill", "secondary_skill"].includes(event)) {
            setFilteredData({ ...filteredData, [event]: value });
        } if (event === "location") {
            if (value.length > 0 && value?.at(-1).location === "Pan India") {
                setFilteredData({ ...filteredData, [event]: [{ location: "Pan India" }] });
            } else if (value?.length > 0 && value?.filter(res => res.location === "Pan India")?.length > 0) {
                let finalValue = value?.splice(value?.findIndex(item => item?.location !== "Pan India"), 1)
                setFilteredData({ ...filteredData, [event]: finalValue });
            } else {
                setFilteredData({ ...filteredData, [event]: value });
            }
            setIsDeleted(true);
        }
        if (["notice_period", "yoe"].includes(event?.target?.name)) {
            setFilteredData({ ...filteredData, [event?.target?.name]: event?.target?.value });
            setIsDeleted(true);
        }
        if (deleteFilter === "delete") {
            setIsDeleted(true);
        }
    };

    const handleApplyFilter = () => {
        setView(false)
        getCandidate()
    }

    const handleClearFilter = () => {
        setFilteredData(initialValues)
        setIsDeleted(true);
    }

    const handleAddCandidate = (candidate) => {
        if(ai){
            AddAtsJobKanbanHelper({
                kanban_data: JSON.stringify([{
                    "candidate_id": candidate?.candidate_id, "ats_jobpost_id": currentJobData?.ats_jobpost_id,
                    "comment": "", "ats_job_stages_id": null, "priority": 5, "ats_status_id": null
                }]),
            }).then((res) => {
                if (res?.data?.statuscode === 200) {
                    dispatch(ProposeCandidateConfirmationAction({ candidate_data: candidate, job_data: currentJobData, kanban_data:res?.data?.data?.[0] }))
                    dispatch(GetAtsCandidateAction({
                        pagenumber: page,
                        pagesize: 12,
                        job_post_id:currentJobData?.ats_jobpost_id
                    }));
                }
            })
        }else{
            setOpenPopup(true)
            setCandidateData(candidate)
        }
        
    }

    function Candidatecomponent() {
        return (
            <>
                {showForm ? (
                    <AtsCandidateForm ai={ai} featuresData={featuresData} filter={true} currentData={formData} currentJobData={currentJobData} setShowForm={setShowForm} />) :
                    <Box sx={{ maxHeight: "77vh", overflowY: "auto" }}>
                        <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#ffffff" }}>
                            {!ai && <ArrowBackButton onClick={() => history.push("/ats/manage-candidate")} />}
                            <Box sx={{ display: "flex", padding: 1, justifyContent: "flex-end", mb: 3 }}>
                                <IconButton component="span" variant="contained" className="mpl-primary-btn" onClick={handleViewFilter}>
                                    {view ? <KeyboardControlKeyIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                                {
                                    view &&
                                    <Button sx={{ ml: 2 }} size="small" className="mpl-primary-btn" onClick={handleApplyFilter} >
                                        Apply
                                    </Button>
                                }
                            </Box>
                            <Collapse in={view}>
                                <Box>
                                    <Grid container spacing={2} direction="column" mb={1} pl={2}>
                                        <Grid container alignItems="center" mb={2}>
                                            <Grid item xs={2} md={2}>
                                                <Typography variant="subtitle1">Primary Skill</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <SelectableSearch
                                                    isOptionEqualToValue={(option, value) => +option.id === +value.id}
                                                    value={filteredData.primary_skill}
                                                    loading={handlePrimarySkillLoad}
                                                    fieldLabel="primary_skill"
                                                    required={false}
                                                    multiple={true}
                                                    variant="outlined"
                                                    name="primary_skill"
                                                    size="small"
                                                    onChangeEvent={handleFilter}
                                                    data={primaryskilldata}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ display: "flex", alignItems: "center" }} mb={2}>
                                            <Grid item xs={2} md={2}>
                                                <Typography variant="subtitle1">Secondary Skill</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <SelectableSearch
                                                    isOptionEqualToValue={(option, value) => +option.id === +value.id}
                                                    value={filteredData.secondary_skill}
                                                    loading={handleSecondarySkillLoad}
                                                    fieldLabel="secondary_skill"
                                                    required={false}
                                                    multiple={true}
                                                    variant="outlined"
                                                    name="secondary_skill"
                                                    size="small"
                                                    onChangeEvent={handleFilter}
                                                    data={secondaryskilldata}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ display: "flex", alignItems: "center" }} mb={2}>
                                            <Grid item xs={2} md={2}>
                                                <Typography variant="subtitle1">Location</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <SelectableSearch
                                                    isOptionEqualToValue={(option, value) =>
                                                        +option.location === +value.location
                                                    }
                                                    value={filteredData.location}
                                                    loading={handleLocationLoad}
                                                    fieldLabel="location"
                                                    required={false}
                                                    multiple={true}
                                                    variant="outlined"
                                                    name="location"
                                                    size="small"
                                                    onChangeEvent={handleFilter}
                                                    data={location}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ display: "flex", alignItems: "center" }} mb={2}>
                                            <Grid item xs={2} md={2}>
                                                <Typography variant="subtitle1">Notice Period</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <FormControl size="small" fullWidth>
                                                    <Select
                                                        name='notice_period'
                                                        labelId="notice_period"
                                                        id="notice_period"
                                                        value={filteredData?.notice_period}
                                                        onChange={handleFilter}
                                                    >
                                                        <MenuItem value={"Below 30 Days"}>Below 30 Days</MenuItem>
                                                        <MenuItem value={"30 to 60 Days"}>30 to 60 Days</MenuItem>
                                                        <MenuItem value={"More than 60 Days"}>More than 60 Days</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{ display: "flex", alignItems: "center" }} mb={2}>
                                            <Grid item xs={2} md={2} >
                                                <Typography variant="subtitle1">Years of Experience</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <FormControl size="small" fullWidth>
                                                    <Select
                                                        name='yoe'
                                                        labelId="yoe"
                                                        id="yoe"
                                                        value={filteredData?.yoe}
                                                        onChange={handleFilter}
                                                    >
                                                        <MenuItem value={"Below 5 years"}>Below 5 years</MenuItem>
                                                        <MenuItem value={"5 to 10 years"}>5 to 10 years</MenuItem>
                                                        <MenuItem value={"More than 10 years"}>More than 10 years</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                            <AppliedFilter filteredData={filteredData} handleFilter={handleFilter} handleClearFilter={handleClearFilter} getCandidate={getCandidate} />
                        </Box>
                        <Box>
                            {isLoading ?
                                <Grid container direction="row" alignItems="center">
                                    {Array(8)
                                        .fill()
                                        .map((data, index) => (
                                            <Grid key={index} item p={1.5} lg={4} md={6} sm={6} xs={12}>
                                                <Card>
                                                    <CardHeader
                                                        action={null}
                                                        title={
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                width="80%"
                                                                style={{ marginBottom: 6 }}
                                                            />
                                                        }
                                                        subheader={
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                width="40%"
                                                                style={{ marginBottom: 6 }}
                                                            />
                                                        }
                                                    />
                                                    <Skeleton
                                                        sx={{ height: 130 }}
                                                        animation="wave"
                                                        variant="rectangular"
                                                    />
                                                    <CardContent>
                                                        <>
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                style={{ marginBottom: 6 }}
                                                            />
                                                            <Skeleton
                                                                animation="wave"
                                                                height={10}
                                                                width="80%"
                                                            />
                                                        </>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                </Grid> :
                                atsCandidateData?.length > 0 ?
                                    <>
                                        <Grid container direction="row" alignItems="center">
                                            {atsCandidateData?.map((candidate, index) => (
                                                <Grid key={index} item p={1.5} lg={4} md={6} sm={6} xs={12}>
                                                    <Card sx={{ ":hover": { cursor: "pointer" } }} key={index}>
                                                        <CardContent>
                                                            <Box>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: -1 }}>
                                                                    <Tooltip title={"Candidate"}>
                                                                        <Typography variant="h6">
                                                                            {candidate?.first_name}
                                                                        </Typography>
                                                                    </Tooltip>
                                                                    <Tooltip title={candidate.is_proposed ? "Candidate Already Proposed this Job" : "Propose Candidate"}>
                                                                        <IconButton sx={{ mt: -1, mr: -2 }} onClick={(e) => {if (!candidate?.is_proposed) {handleAddCandidate(candidate);} }} >
                                                                            <PersonAddAltIcon color={candidate?.is_proposed ? "disabled" : "success"} fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Box>
                                                                <Box  onClick={() => toggleDrawer(true, candidate)}>
                                                                <Tooltip title={"Email"}>
                                                                    <Box sx={{ display: "flex" }}>
                                                                        <EmailOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                                                                        <Typography color="#637381" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                                                            {candidate?.email_id ? candidate?.email_id : "NA"}
                                                                        </Typography>
                                                                    </Box>
                                                                </Tooltip>
                                                                <Tooltip title={"Primary Skills"}>
                                                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                                                        <StickyNote2OutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                                                                        <Box sx={{ maxWidth: "150px" }}>
                                                                            <Typography
                                                                                color="#637381"
                                                                                sx={{
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                    whiteSpace: "nowrap",
                                                                                }}
                                                                            >
                                                                                {candidate?.primary_skill ? candidate?.primary_skill : "NA"}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Tooltip>
                                                                <Tooltip title={"Notice Period"}>
                                                                    <Box sx={{ display: "flex" }}>
                                                                        <LibraryBooksOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                                                                        <Typography color="#637381" >
                                                                            {candidate?.notice_period && candidate?.notice_period < 30 ? "Below 30 Days" : candidate?.notice_period > 30 && candidate?.notice_period < 60 ? "30 - 60 Days" : candidate?.notice_period > 61 ? "More than 60 Days" : "NA"}
                                                                        </Typography>
                                                                    </Box>
                                                                </Tooltip>
                                                                <Tooltip title={"Location"}>
                                                                    <Box sx={{ display: "flex" }}>
                                                                        <PlaceOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                                                                        <Typography color="#637381" >
                                                                            {candidate?.location ? candidate?.location : "NA"}
                                                                        </Typography>
                                                                    </Box>
                                                                </Tooltip>
                                                                {
                                                                    candidate?.resume &&
                                                                    <Box sx={{ display: "flex" }} onClick={(e) => { e.stopPropagation(); DownloadAttachment(candidate?.resume); }}>
                                                                        <CloudDownloadOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
                                                                        <Typography color="#637381">
                                                                            Download Resume
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>         
                                                        </CardContent>
                                                    </Card>
                                                </Grid>))}

                                        </Grid>
                                        {atsCandidateData?.[0]?.total_count > 12 && (
                                            <Pagination
                                                sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                                                showFirstButton
                                                showLastButton
                                                count={
                                                    Number.isInteger(atsCandidateData?.[0]?.total_count / 12)
                                                        ? atsCandidateData?.[0]?.total_count / 12
                                                        : Math.round(atsCandidateData?.[0]?.total_count / 12 + 0.5) ||
                                                        0
                                                }
                                                page={page}
                                                color="secondary"
                                                onChange={(event, value) => handlePagination(event, value)}
                                            />
                                        )}
                                    </>
                                    : <NoData title={"No Candidates Found"} />}
                        </Box>
                    </Box>
                }
                 <Popup title="Propose for a Job" openPopup={openPopup} setOpenPopup={setOpenPopup} > <ProposeCandidateForm currentData={candidateData} setOpenPopup={setOpenPopup}/>
                 </Popup>
            </>
        )
    };

    return (
        <>
            {
                !ai ?
                    <Base>
                        <Candidatecomponent />
                    </Base>
                    :
                    <Candidatecomponent />
            }
        </>
    )
};


export default AtsCandidateFilters;