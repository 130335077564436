import React, { useEffect } from "react";
import MaterialTable from "material-table";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@mui/material";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SendIcon from "@mui/icons-material/Send";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/material/styles";
import { ConvertDateTime } from "../../Base/helper/baseFunctions";
import { GetSchemaOwnerUser } from "../helper/AdminUserHelper";
import {
  CreatedSchemasGetAction,
  DisableWorkspaceAction,
  EnableWorkspaceAction,
  updatePlanAction
} from "../../../../store/MeeplAdmin/Workspace/Action";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { PlansAction } from "../../../../store/Plans/Action";
import Popup from "../../Base/views/Popup";
import CommonDatePicker from "../../Base/views/CommonDatePicker";
import CustomToggle from "../../Base/views/CustomToggle";
import ConfirmationDialog from "../../Base/views/ConfirmationDialog";

const CreatedSchemas = ({ setworkspaceManage }) => {
  const initialValues = {
    disable_reason: "",
  };
  const enable_initialValues = {
    expiry_date: "",
  };
  const initialValue = {
    planid:""
  };
  const dispatch = useDispatch();
  const theme = useTheme();
  const { CreatedSchemasdata, Loading } = useSelector(
    (state) => state?.MeeplAdminReducer?.WorkspaceReducer
  );
  const { plansdata } = useSelector((state) => state?.PlansReducer);
  const [plansData, setplansData] = useState({ load: true, values: [] });
  const [updateplan, setUpdatePlan] = useState(initialValue);
  const [enableplan, setEnablePlan] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disableReason, setDisableReason] = useState(initialValues);
  const [enable, setEnable] = useState(false);
  const [enableData, setEnableData] = useState(enable_initialValues);
  const [currentData, setCurrentData] = useState([]);
  const [inactive, setInactive] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState({ show: false });

  useEffect(() => {
    if (inactive === true) {
      dispatch(CreatedSchemasGetAction({ is_active: "false" }));
    } else dispatch(CreatedSchemasGetAction({ is_active: "true" }));
    dispatch(PlansAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactive]);
  
  useEffect(() => {
    currentData !== null && setUpdatePlan({...currentData,planid: currentData?.planid
      ? {
        planid: currentData?.planid,
        planname: currentData?.planname,
        }
      : "",})
    // eslint-disable-next-line
}, [currentData]);

  const handleChange = (event, value) => {
    setDisableReason({ [event.target.name]: event.target.value });
  };
  const handleEnableChange = (event, value) => {
    setEnableData({ [event.target.name]: event.target.value });
  };
  const handlePlan = (event, value) => {
    if (["planid"].includes(event)) {
      if (value) {
        setUpdatePlan({ ...updateplan, [event]: value });
      } else {
        setUpdatePlan({ ...updateplan, [event]: "" });
      }
    }else{
      setUpdatePlan({ ...updateplan,[event.target.name]: event.target.value });
    }
    
  };
  const handleSubmitPlan = () => {
    if (inactive === true) {
      dispatch(
        updatePlanAction({
          ...updateplan,is_active: "false" 
        })
      );
      setUpdatePlan(initialValue);
      setEnablePlan(false)
    }else{
      dispatch(
        updatePlanAction({
          ...updateplan,is_active: "true" 
        })
      );
      setUpdatePlan(initialValue);
      setEnablePlan(false)
    }
  };
  const handleDisableWorkspace = () => {
    if (currentData?.company_id) {
      dispatch(
        DisableWorkspaceAction({
          company_id: currentData?.company_id,
          disable_reason: disableReason?.disable_reason,
        })
      );
      setDisable(false);
      setCurrentData([]);
      setDisableReason(initialValues);
    }
  };
  const handleEnableWorkspace = () => {
    if (currentData?.company_id) {
      dispatch(
        EnableWorkspaceAction({
          company_id: currentData?.company_id,
          expiry_date: enableData?.expiry_date,
        })
      );
      setEnable(false);
      setCurrentData([]);
      setEnableData(enable_initialValues);
    }
  };
  const handleToggle = (e) => {
    setInactive(!inactive);
  };
  const handleDisableclose = () => {
    setDisable(false);
    setCurrentData([]);
    setDisableReason(initialValues);
  };
  const handleEnableclose = () => {
    setEnable(false);
    setCurrentData([]);
    setEnableData(enable_initialValues);
  };
  const setEnablePlanClose = () => {
    setEnablePlan(false);
    setCurrentData([]);
    setUpdatePlan(initialValue);
  };
  

  return (
    <>
      {Loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        sx={{
          display: "flex",
          WebkitBoxPack: "justify",
          justifyContent: "flex-end",
          p: 1,
        }}
      >
        <CustomToggle
          isChecked={inactive}
          setIsChecked={handleToggle}
          handleToggleChange={handleToggle}
          Yestext={"Inactive"}
          NoText={"Active"}
          style={{
            height: "32px",
            padding: "0px",
            width: "145px",
            labelPadding: "1px",
          }}
        />
      </Box>
      <Box
        sx={{
          margin: "0px 16px 0px 16px",
          boxShadow:
            "0px 2px 4px -1px rgba(158, 158, 158, 0.2), 0px 4px 5px 0px rgba(158, 158, 158, 0.14), 0px 1px 10px 0px rgba(158, 158, 158, 0.12)",
        }}
      >
        <MaterialTable
          columns={[
            {
              title: "Company Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "company_full_name",
              grouping: false,
            },
            {
              title: "Workspace",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "domainname",
              grouping: false,
            },
            {
              title: "Email ID",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "email_id",
              grouping: false,
            },
            {
              title: "Team Size",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "team_size",
              grouping: false,
            },
            {
              title: "Plan Name",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "planname",
              grouping: false,
            },
            {
              title: "Expiry Date",
              cellStyle: { padding: "10px 10px 10px 13px" },
              field: "expiry_date",
              grouping: false,
              render: (rowData) => {
                return rowData?.expiry_date
                  ? ConvertDateTime(rowData?.expiry_date)
                  : "";
              },
            },
          ]}
          data={CreatedSchemasdata || []}
          options={{
            maxBodyHeight: "58vh",
            minBodyHeight: "58vh",
            rowStyle: { fontSize: "13px" },
            headerStyle: {
              backgroundColor:
                theme?.palette?.mode === "light"
                  ? "#fff"
                  : theme.palette.grey[500],
              color:
                theme?.palette?.mode === "light"
                  ? "#000"
                  : theme.palette.grey[800],
              fontWeight: "bold",
              padding: "10px 10px 10px 13px",
            },
            sorting: true,
            search: false,
            searchText: "",
            searchFieldAlignment: "right",
            searchAutoFocus: true,
            searchFieldVariant: "standard",
            filtering: false,
            paging: true,
            pageSizeOptions: [25, 50],
            pageSize: 25,
            paginationType: "stepped",
            showFirstLastPageButtons: false,
            paginationPosition: "bottom",
            exportButton: false,
            exportAllData: true,
            exportFileName: "Client",
            addRowPosition: "first",
            selection: false,
            actionsColumnIndex: -1,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,
            grouping: false,
            columnsButton: false,
            toolbar: false,
          }}
          actions={[
            (rowData) => {
              if (rowData?.isactive === "0") {
                return {
                  icon: () => null,
                };
              }

              return {
                icon: () => <SendIcon fontSize="small" color="primary" />,
                tooltip: "Reset Password",
                onClick: (_event, rowData) => {
                  setShowResetPassword({
                    show: true,
                    data: rowData?.domainname,
                  });
                },
              };
            },
            (rowData) => {
              if (rowData?.isactive === "0") {
                return {
                  icon: () => null,
                };
              }

              return {
                icon: () => <BlockIcon fontSize="small" color="error" />,
                tooltip: "Block Workspace",
                onClick: (_event, rowData) => {
                  setCurrentData(rowData);
                  setDisable(true);
                },
              };
            },
            (rowData) => {
              if (rowData?.isactive === "1") {
                return {
                  icon: () => null,
                };
              }

              return {
                icon: () => (
                  <CheckCircleOutlineIcon fontSize="small" color="success" />
                ),
                tooltip: "Enable Workspace",
                onClick: (_event, rowData) => {
                  setCurrentData(rowData);
                  setEnable(true);
                },
              };
            },
            (rowData) => ({
              icon: () => {
                return <ImportExportIcon fontSize="small" color="success" />;
              },
              tooltip: "Update Plan",
              onClick: (_event, rowData) => {
                setCurrentData(rowData);
                setEnablePlan(true);
              },
            }),
          ]}
          title="Created Schemas"
        />
      </Box>
      <Popup
        maxWidth="sm"
        title="Disable Workspace"
        isMasterProduct={true}
        setOpenPopup={handleDisableclose}
        openPopup={disable}
        fullWidth={true}
      >
        <Box>
          <TextField
            size="small"
            value={disableReason.disable_reason}
            name="disable_reason"
            onChange={handleChange}
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
            id="disable_reason"
            label="Reason"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: -2,
            }}
          >
            <Button
              disabled={disableReason.disable_reason === ""}
              onClick={handleDisableWorkspace}
              variant="contained"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popup>
      <Popup
        maxWidth="sm"
        title="Enable Workspace"
        isMasterProduct={true}
        setOpenPopup={handleEnableclose}
        openPopup={enable}
        fullWidth={true}
      >
        <Box>
          <FormControl fullWidth>
            <CommonDatePicker
              size="small"
              name="expiry_date"
              label="Expiry Date"
              pickerType="date"
              onKeyPress={(e) => e.preventDefault()}
              pickerFormat="dd/MM/yyyy"
              DefaultDTvalue={enableData?.expiry_date}
              required
              minDate={new Date()}
              handleChange={(value) =>
                handleEnableChange({
                  target: {
                    name: "expiry_date",
                    value: value
                      ? `${
                          value?.getFullYear() +
                          "-" +
                          ("0" + (value?.getMonth() + 1)).slice(-2) +
                          "-" +
                          ("0" + value?.getDate()).slice(-2)
                        }`
                      : "",
                  },
                })
              }
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: -2,
            }}
          >
            <Button
              disabled={enableData.expiry_date === ""}
              onClick={handleEnableWorkspace}
              variant="contained"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popup>
      <Popup
        maxWidth="sm"
        title="Update Plan"
        isMasterProduct={true}
        setOpenPopup={setEnablePlanClose}
        openPopup={enableplan}
        fullWidth={true}
      >
        <Box>
        <FormControl fullWidth sx={{ pt: 1 }}>
                <SelectableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option.planid === +value.planid
                  }
                  filterOptions="planid"
                  keys={["planid", "planname"]}
                  label="Plans"
                  required
                  value={updateplan?.planid}
                  loading={() =>
                    setplansData({ load: false, values: plansdata || [] })
                  }
                  fieldLabel="planname"
                  variant={"contained"}
                  name="planid"
                  size="small"
                  data={plansData}
                  onChangeEvent={handlePlan}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.planname} - &#8377; {option.plancost}
                    </Box>
                  )}
                />
              </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: -2,
            }}
          >
            <Button
              disabled={updateplan.planid === ""}
              onClick={handleSubmitPlan}
              variant="contained"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Popup>
      <ConfirmationDialog
        title="Reset Password"
        content="Are you sure want to sent reset password email ?"
        openDialog={showResetPassword?.show}
        closeDialog={setShowResetPassword}
        popupTitle="Users"
        CancelButtonText="Cancel"
        OkButtonText="Send Email"
        onCancel={() => setShowResetPassword({ show: false })}
        onSubmit={() => {
          setShowResetPassword({ show: false });
          GetSchemaOwnerUser({ domain_name: showResetPassword?.data });
        }}
      />
    </>
  );
};

export default CreatedSchemas;
