import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField } from "@mui/material";
import MaterialTable from "material-table"
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddJobAction, DeleteJobAction, UpdateJobAction } from '../../../../../store/Ancillary/Job/Action';

const Job = () => {

    const dispatch = useDispatch();
    const { job_options } = useSelector(state => state.AncillaryReducer.JobReducer)

    return (
        <>
            <Box>
                <MaterialTable
                    columns={[
                        {
                            title: "Name", cellStyle: { padding: "0px 10px" }, field: "name", validate: rowData => rowData?.name === '' || rowData?.name === undefined ? 'Name cannot be empty' : '',
                            editComponent: props => (
                                <TextField
                                    required={true}
                                    variant="standard"
                                    value={props?.value}
                                    onChange={e => props.onChange(e.target.value)}
                                    error={props?.error}
                                    helperText={props?.helperText}
                                />
                            )
                        },
                    ]}
                    data={job_options || []}
                    icons={{
                        Add: () => <AddIcon />,
                        Edit: () => <EditIcon />,
                        Delete: () => <DeleteIcon />
                    }}
                    editable={{
                        onRowAdd: (newRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(AddJobAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowUpdate: (newRow, oldRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(UpdateJobAction(newRow))
                                setTimeout(() => resolve(), 500);
                            }),
                        onRowDelete: (selectedRow) =>
                            new Promise((resolve, reject) => {
                                dispatch(DeleteJobAction({ job_id: `{${selectedRow?.job_id}}` }))
                                setTimeout(() => resolve(), 1000);
                            }),
                    }}
                    options={{
                        maxBodyHeight: 220,
                        rowStyle: { fontSize: "13px" },
                        headerStyle: { padding: "0px 10px", fontWeight: "bold" },
                        sorting: true,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        paging: false,
                        selection: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                    }}
                    title=""
                />
            </Box>
        </>
    );
}

export default Job;
