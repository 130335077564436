import NProgress from 'nprogress';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../../axiosInstance';
import { HRMS_LMS_API_URL } from '../../../../Constants/baseConstant';
import { getUserData } from '../../../Base/helper/baseFunctions';

export const TimeOffHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/lms/getTimeOff`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.employee_id,
            month_year: params?.month_year || null,
            manager_emp_id: params?.my_time_flag ? null : user?.employee_id,
            approval_screen: params?.approval_screen || false
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const TimeOffBookingsHelper = async (report) => {
    var data = getUserData();
    toast.info("Loading...")
    return await axiosInstance(`/lms/getTimeOffBooking`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            domain_name: data.domain,
            emp_id: data.employee_id,
            leavetype_id: +(report?.leavetype_id?.leavetype_id) || 0,
            status: report?.status?.name
        }
    })
        .then((res) => {
            toast.dismiss();
            res?.data?.data?.length === 0 && toast.warning("No Records Found")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const TeamTimeOffHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/lms/getTeamTimeOff`, {
        method: "POST",
        baseURL: HRMS_LMS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: user.employee_id,
            month_year: params?.month_year
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}