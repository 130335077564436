import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "../../../Base/views/ModalPopUpForm"
import CommonDatePicker from "../../../Base/views/CommonDatePicker"
import SelectableSearch from '../../../Base/views/SelectableSearch';
import { useDispatch, useSelector } from "react-redux";
import { GetRelationshipAction } from "../../../../../store/Ancillary/Relationship/Action"
import { AddEmployeeFamilyAction, UpdateEmployeeFamilyAction } from "../../../../../store/Employee/Family/Action";
import { grey } from "@mui/material/colors";


export default function FamilyModalForm(props) {
    const dispatch = useDispatch()
    const { relationship_options } = useSelector(state => state.AncillaryReducer.RelationshipReducer)
    const [relationshipData, setRelationshipData] = useState({ load: true, values: [] });
    const initialFValues = {
        name: "",
        relationship_id: "",
        dob: "",
        phone: "",
        gender: "Male",
        marital_status: "",
        marriage_date: null,
    };
    const [maritalStatus,] = useState({
        load: false, values: [
            { id: 1, name: "Married" },
            { id: 2, name: "Unmarried" },
            { id: 3, name: "Separated" },
            { id: 4, name: "Divorced" },
            { id: 5, name: "Widowed" },
        ]
    });
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [famillyModalData, setFamilyModalData] = useState(initialFValues);
    const { addMode, editedRecord, setOpenPopup, OnboardingEmpData, data, currentMode, newdata} = props;
    useEffect(() => {
        if (editedRecord != null) {
            var mar_status = maritalStatus?.values?.filter(item => item.name === editedRecord?.marital_status)
            setFamilyModalData({
                ...editedRecord, relationship_id: { relationship_id: editedRecord?.relationship_id, reship_name: editedRecord?.relationship },  "marital_status": mar_status?.[0] ? mar_status?.[0] : "",
            });
        }
        dispatch(GetRelationshipAction())
        if(editedRecord?.relationship === "Spouse"){
            setShow(true)
        }
        //eslint-disable-next-line
    }, [editedRecord]);

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("name" in fieldValues) {
            temp.name = fieldValues.name === "" ? "Name is required" : "";
        }

        if ("relationship_id" in fieldValues) {
            temp.relationship_id = fieldValues.relationship_id === "" ? "Relationship is required" : "";
        }
        if (famillyModalData?.phone?.length > 0 && famillyModalData?.phone !== "") {
            temp.phone = fieldValues?.phone?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
                ? ""
                : "Invalid Phone Number";
        }
        else {
            temp.phone = ""
        }
        if ("gender" in fieldValues) {
            temp.gender = fieldValues.gender === "" ? "Gender is required" : "";
        }

        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event, value) => {
        if (["relationship_id"].includes(event)) {
            setShow(value?.reship_name === "Spouse" ? true : false);
            if (value) {
                setFamilyModalData({ ...famillyModalData, [event]: value })
            } else {
                setFamilyModalData({ ...famillyModalData, [event]: "" })
            }
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...famillyModalData, [event]: value ? value : "" });
        } 
        else if(["marital_status"].includes(event)){
            if (value) {
                setFamilyModalData({ ...famillyModalData, [event]: value })
            } else {
                setFamilyModalData({ ...famillyModalData, [event]: "" })
            }
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...famillyModalData, [event]: value ? value : "" });

        }
        else {
            setFamilyModalData({ ...famillyModalData, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...famillyModalData, [event.target.name]: event.target.value });
        }
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        if (validate(famillyModalData)) {
            addMode(resetForm);
            if (famillyModalData?.emp_family_id) {
                if(currentMode ==="addemployee"){
                    dispatch(UpdateEmployeeFamilyAction({
                        ...famillyModalData,
                        domain: data?.domain,
                        employee_id: newdata?.employee_id,
                    }))
                }
                else{
                    dispatch(UpdateEmployeeFamilyAction({
                        ...famillyModalData,
                        domain: data?.domain,
                        employee_id: OnboardingEmpData ? OnboardingEmpData?.employee_id : data?.employee_id,
                    }))
                }
               
            } else {
                if(currentMode ==="addemployee"){
                    dispatch(AddEmployeeFamilyAction({
                        ...famillyModalData,
                        domain: data?.domain,
                        employee_id: newdata?.employee_id,
                    }))
                }
                else{
                    dispatch(AddEmployeeFamilyAction({
                        ...famillyModalData,
                        domain: data?.domain,
                        employee_id: OnboardingEmpData ? OnboardingEmpData?.employee_id : data?.employee_id,
                    }))
                }
               
            }
            setOpenPopup(false)
        }

    };

    const resetForm = () => {
        setFamilyModalData(initialFValues);
        setErrors({});
    };

    return (
        <Form onSubmit={HandleSubmit}>
            <Grid container>
                <Grid container>
                    <Grid key="name" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                autoFocus={true}
                                size="small"
                                value={famillyModalData.name}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                required
                                id="name"
                                label="Name"
                                name="name"
                                autoComplete="name"
                                {...(errors.name && {
                                    error: true,
                                    helperText: errors.name,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="relationship" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                label="Relationship"
                                value={famillyModalData?.relationship_id}
                                loading={() => setRelationshipData({ load: false, values: relationship_options })}
                                fieldLabel="reship_name"
                                required
                                variant={"outlined"}
                                name="relationship_id"
                                size="small"
                                onChangeEvent={handleInputChange}
                                data={relationshipData}
                                {...(errors.relationship_id && {
                                    error: true,
                                    helperText: errors.relationship_id,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    {show && (  <>
                            <Grid key="marital_status" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                                    <SelectableSearch
                                        label="Marital Status"
                                        value={famillyModalData?.marital_status}
                                        fieldLabel="name"
                                        required
                                        variant={"contained"}
                                        name="marital_status"
                                        size="small"
                                        data={maritalStatus}
                                        onChangeEvent={handleInputChange}
                                    />
                            </Grid>
                            <Grid key="marriage_date" item xs={12} md={6} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                            <FormControl fullWidth >
                                <CommonDatePicker
                                    size="small"
                                    name="marriage_date"
                                    label="Marriage Date"
                                    pickerType="date"
                                    pickerFormat="dd/MM/yyyy"
                                    DefaultDTvalue={famillyModalData?.marriage_date}
                                    handleChange={(value) => handleInputChange({ target: { name: "marriage_date", value: value ? `${value?.getFullYear() + '-' + ("0" + (value.getMonth() + 1)).slice(-2) + '-' + ("0" + value.getDate()).slice(-2)}` : "" } })}
                                />
                            </FormControl>
                            </Grid>
                            </>
                        )}
                    <Grid key="phone" item xs={12} md={6} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={famillyModalData.phone}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="phone"
                                label="Phone"
                                name="phone"
                                autoComplete="phone"
                                {...(errors.phone && {
                                    error: true,
                                    helperText: errors.phone,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="dob" item xs={12} md={6} sx={{ marginTop: "1.5rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <CommonDatePicker
                                size="small"
                                name="dob"
                                label="Date of Birth"
                                pickerType="date"
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={famillyModalData?.dob}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleInputChange({
                                      target: {
                                        name: "dob",
                                        value: value
                                          ? `${
                                              value?.getFullYear() +
                                              "-" +
                                              ("0" + (value?.getMonth() + 1)).slice(-2) +
                                              "-" +
                                              ("0" + value?.getDate()).slice(-2)
                                            }`
                                          : "",
                                      },
                                    })
                                  }
                                {...(errors.dob && {
                                    error: true,
                                    helperText: errors.dob,
                                })}
                            />
                        </FormControl>
                    </Grid>

                    <Grid key="gender" item xs={12} md={12} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                            <RadioGroup
                                onChange={handleInputChange}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="gender"
                                value={famillyModalData.gender}
                            >
                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                <FormControlLabel value="Transgender" control={<Radio />} label="Transgender" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                </Grid>
                <Grid container sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                    <Button
                        sx={{ ":hover": { color: grey[50] }, marginTop: "1.75rem", marginRight: "1rem" }}
                        variant="outlined"
                        className="mpl-secondary-btn"
                        onClick={() => setOpenPopup(false)}
                    >
                        cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" className="mpl-primary-btn" sx={{ marginTop: "1.75rem", marginRight: "1rem" }}>
                        {editedRecord?.emp_family_id ? "Update" : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    );
}
