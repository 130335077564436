// ** MUI Imports
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
// ** Icons Imports
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react'
import Designation from '../../../Ancillary/Designation/views/Designation'
import LeavingReason from '../../../Ancillary/LeavingReason/views/LeavingReason';
import Job from '../../../Ancillary/Job/views/Job';
import BusinessUnit from '../../../Ancillary/BusinessUnit/views/BusinessUnit';
import SubBusinessUnit from '../../../Ancillary/SubBusinessUnit/views/SubBusinessUnit';
import { useTheme } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import EmploymentType from '../../../Ancillary/EmploymentType/views/EmploymentType';
import CostCenter from '../../../Ancillary/CostCenter/views/CostCenter';
import WorkLocation from '../../../Ancillary/WorkLocation/views/WorkLocation';
import ProofType from '../../../Ancillary/ProofType/views/ProofType';
import StudyLevel from '../../../Ancillary/StudyLevel/views/StudyLevel';
import StudyProgram from '../../../Ancillary/StudyProgram/views/StudyProgram';

const Employee = ({ accordian }) => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState(accordian)

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    const { businessunitData } = useSelector(state => state?.AncillaryReducer?.BusinessUnitReducer);
    const { subBusinessunitData } = useSelector(state => state?.AncillaryReducer?.SubBusinessUnitReducer);
    const { designation_options } = useSelector(state => state?.AncillaryReducer?.DesignationReducer);
    const { job_options } = useSelector(state => state.AncillaryReducer.JobReducer)
    const { leavingreasonData } = useSelector(state => state?.AncillaryReducer?.LeavingReasonReducer);
    const { employment_type_options } = useSelector(state => state?.AncillaryReducer?.EmploymentTypeReducer);
    const { cost_center_options } = useSelector(state => state?.AncillaryReducer?.CostCenterReducer);
    const { workLocation_options } = useSelector(state => state?.AncillaryReducer?.WorkLocationReducer);
    const { proof_type_data } = useSelector(state => state?.AncillaryReducer?.ProofTypeReducer);
    const { studylevel_options } = useSelector(state => state?.AncillaryReducer?.StudyLevelReducer);
    const { studyprogram_options } = useSelector(state => state?.AncillaryReducer?.StudyProgramReducer);

    const values = [

        { name: "Cost Center", comp: <CostCenter />, count: cost_center_options?.length || 0 },
        { name: "Designation", comp: <Designation />, count: designation_options?.length || 0 },
        { name: "Job", comp: <Job />, count: job_options?.length || 0 },
        { name: "Leaving Reason", comp: <LeavingReason />, count: leavingreasonData?.length || 0 },
        { name: "Business Unit", comp: <BusinessUnit />, count: businessunitData?.length || 0 },
        { name: "Sub Business Unit", comp: <SubBusinessUnit />, count: subBusinessunitData?.length || 0 },
        { name: "Employment Type", comp: <EmploymentType />, count: employment_type_options?.length || 0 },
        { name: "Work Location", comp: <WorkLocation />, count: workLocation_options?.length || 0 },
        { name: "Proof Type", comp: <ProofType />, count: proof_type_data?.length || 0 },
        { name: "Study Level", comp: <StudyLevel />, count: studylevel_options?.length || 0 },
        { name: "Study Program", comp: <StudyProgram />, count: studyprogram_options?.length || 0 }
    ]
    const actionSummaryStyle = {
        minHeight: 50,
        maxHeight: 50,
        borderRadius: "10px 10px 0px 0px",
        // backgroundColor: '#a5a5a5',
        '&.Mui-expanded': {
            minHeight: 50,
            maxHeight: 50,
            backgroundColor: theme?.palette?.primary?.dark,
            color: "white",
        }
    }
    return (
        <div style={{ padding: "10px 20px" }}>
            {values.map((value, id) => {
                return (
                    <Accordion key={id} expanded={expanded === `${value.name}`} onChange={handleChange(`${value.name}`)} sx={{ borderRadius: "10px" }}>
                        <AccordionSummary sx={actionSummaryStyle}
                            expandIcon={<KeyboardArrowDownIcon />}
                            id='controlled-panel-header-1'
                            aria-controls='controlled-panel-content-1'
                        >
                            <Typography sx={{color:theme.palette.primary.contrastText}} variant='body1' fontWeight={600}>{value.name} ({value.count})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {value.comp}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}

export default Employee