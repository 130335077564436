import { Box, Button, FormControl, Grid, TextField } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAtsConsultancyAction, UpdateAtsConsultancyAction } from '../../../../../store/ATS/Consultancy/Action';
import { ATS_CONTACT_PK } from '../../../../Constants/baseConstant';
// import { GetCityHelper } from '../../../Ancillary/City/helper/CityHelper';
// import { GetCountryHelper } from '../../../Ancillary/Country/helper/CountryHelper';
// import { GetStateHelper } from '../../../Ancillary/State/helper/StateHelper';
// import SelectableSearch from '../../../Base/views/SelectableSearch';
import { UpdateStatusHelper } from '../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper';
import { AddAtsContactHelper, GetAtsContactHelper, UpdateAtsContactHelper } from '../../helper/AtsContactHelper';
import AtsContact from './AtsContact';
import { AddAtsConsultancyHelper } from '../../helper/AtsConsultancyHelper';
import ArrowBackButton from '../../../Base/views/ArrowBackButton';
import { GetUserRoleAction } from '../../../../../store/RoleAccess/Role/Action';

const AtsConsultancyForm = (props) => {
    const { setCurrentMode, currentData, setConsultancyData } = props;
    const dispatch = useDispatch();
    const initialFValues = {
        name: "",
        address: "",
        contact_person: "",
        mobile_number: "",
        office_number: "",
        country_id: "",
        state_id: "",
        city_id: "",
        zip_code: "",
        website: "",
        primary_owner: "",
        company_lead_id: "",
        business_unit_id: "",
        email_id: "",
        status: "Active"
    };

    const contactValues = {
        first_name: "",
        last_name: "",
        mobile_number: null,
        email_id: "",
        status: "Active",
    };

    const [loading, setLoading] = useState(true);
    const [contactData, setContactData] = useState([]);
    const [errors, setErrors] = useState({});
    const [contactErrors, setContactErrors] = useState([]);
    const [consultancyModalData, setConsultancyModalData] = useState(initialFValues);
    // const [CountryData, setCountryData] = useState({ load: true, values: [] });
    // const [StateData, setStateData] = useState({ load: true, values: [] });
    // const [CityData, setCityData] = useState({ load: true, values: [] });
    const { featuresData } = useSelector(state => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer)
    useEffect(() => {
        dispatch(GetUserRoleAction());
        if (currentData != null) {
            currentData?.consultancy_id && GetAtsContactHelper({ workspace: "altiio", type: "Consultancy", company_id: null, consultancy_id: +currentData?.consultancy_id }).then(res => {
                let tdata = []
                if (res?.data?.data?.[0]?.contacts?.length > 0) {
                    res?.data?.data?.[0]?.contacts?.forEach(resp => tdata.push({ ...resp }));
                    setContactData(tdata);
                }
                setLoading(false)
            })
            setConsultancyModalData({
                ...currentData, country_id: { country_id: currentData?.country_id, country_name: currentData?.country },
                state_id: { state_id: currentData?.state_id, state_name: currentData?.state },
                city_id: { city_id: currentData?.city_id, city_name: currentData?.city },
            });
        }        //eslint-disable-next-line
    }, [currentData]);

    const handleDisabled = (permission) => {
        const foundPermission = featuresData?.find((item) => item.permission === permission);
        return foundPermission ? true : false;
    };

    // const handleCountryLoad = () => {
    //     GetCountryHelper()
    //         .then(res => {
    //             setCountryData({ load: false, values: res?.data?.data });
    //         })
    // }

    // const handleStateLoad = () => {
    //     GetStateHelper(consultancyModalData?.country_id?.country_id)
    //         .then(res => {
    //             setStateData({ load: false, values: res?.data?.data });
    //         })
    // }

    // const handleCityLoad = () => {
    //     GetCityHelper(consultancyModalData?.state_id?.state_id)
    //         .then(res => {
    //             setCityData({ load: false, values: res?.data?.data });
    //         })
    // }

    const contactValidate = () => {
        let ret_value = []
        contactData?.forEach((store, index) => {
            const error = {};
            if (store?.status === "Active") {
                if (!store.first_name) error.first_name = "First Name is required";
                if ("email_id" in store) {
                    error.email_id =
                        store.email_id === ""
                            ? "Email is required"
                            : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(store.email_id)
                                ? ""
                                : "Invalid Email.";
                }
                if ("mobile_number" in store) {
                    error.mobile_number = ((store?.mobile_number?.toString()) === "" || (store?.mobile_number === null)) ? "Mobile Number is required" :
                        !store?.mobile_number?.toString()?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
                            ? "Invalid Mobile Number." : "";
                }
                const temp = contactErrors;
                temp[index] = error;
                setContactErrors([...temp]);
                ret_value.push(Object.values(error).every((e) => e === ""));
            }
        })
        return ret_value
    };

    const validate = (fieldValues) => {
        let temp = { ...errors };
        if ("name" in fieldValues) {
            temp.name = fieldValues.name?.trim() === "" ? "Org Name is required" : "";
        }
        if ("contact_person" in fieldValues) {
            temp.contact_person = fieldValues.contact_person?.trim() === "" ? "Contact Person is required" : "";
        }
        // if ("zip_code" in fieldValues) {
        //     temp.zip_code = fieldValues.zip_code !== "" &&
        //         !fieldValues?.zip_code?.toString().match(/^[0-9]{5,9}$/)
        //         ? "Invalid Zip Code." : "";
        // }
        if ("mobile_number" in fieldValues) {
            temp.mobile_number = ((fieldValues?.mobile_number?.toString()) === "" || (fieldValues?.mobile_number === null)) ? "Mobile Number is required" :
                !fieldValues?.mobile_number?.toString()?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
                    ? "Invalid Mobile Number." : "";
        }
        if ("email_id" in fieldValues) {
            temp.email_id =
                fieldValues.email_id === ""
                    ? "Email is required"
                    : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fieldValues.email_id)
                        ? ""
                        : "Invalid Email.";
        }

        setErrors({
            ...temp,
        });

        return Object.values(temp).every((x) => x === "");
    };

    const handleInputChange = (event, value) => {
        if (["city_id"].includes(event)) {
            if (value) {
                setConsultancyModalData({ ...consultancyModalData, [event]: value })
            } else {
                setConsultancyModalData({ ...consultancyModalData, [event]: "" })
            }
        } else if (["country_id"].includes(event)) {
            // setStateData({ load: true, values: [] });
            // setCityData({ load: true, values: [] });
            setConsultancyModalData({ ...consultancyModalData, [event]: value ? value : "", "state_id": "", "city_id": "" });
        } else if (["state_id"].includes(event)) {
            // setCityData({ load: true, values: [] })
            setConsultancyModalData({ ...consultancyModalData, [event]: value ? value : "", "city_id": "" });
        }
        else {
            setConsultancyModalData({ ...consultancyModalData, [event.target.name]: event.target.value });
            Object.values?.(errors)?.find(res => res !== "")?.length > 0 &&
                validate({ ...consultancyModalData, [event.target.name]: event.target.value });
        }
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        if (validate(consultancyModalData)) {
            if (!(contactValidate().includes(false))) {
                if (consultancyModalData?.consultancy_id) {
                    dispatch(UpdateAtsConsultancyAction(consultancyModalData))
                    if (contactData?.length > 0) {
                        if (contactData?.[0]?.ats_contacts_id) UpdateAtsContactHelper({ type: "Consultancy", company_id: null, consultancy_id: currentData?.consultancy_id, contact_data: JSON.stringify(contactData) })
                        else {
                            AddAtsContactHelper({ type: "Consultancy", company_id: null, consultancy_id: currentData?.consultancy_id, contact_data: JSON.stringify(contactData) })
                        }
                    }
                } else {
                    AddAtsConsultancyHelper(consultancyModalData).then(res => {
                        if (res?.data?.statuscode === 200) {
                            dispatch(GetAtsConsultancyAction());
                            setConsultancyData(res?.data?.data);
                        }
                    })
                }
                resetForm();
            } else if (contactData?.length === 0) {
                if (consultancyModalData?.consultancy_id) dispatch(UpdateAtsConsultancyAction(consultancyModalData))
                else {
                    AddAtsConsultancyHelper(consultancyModalData).then(res => {
                        if (res?.data?.statuscode === 200) {
                            setConsultancyData(res?.data?.data);
                        }
                    })
                }
                resetForm();
            }
        }
    };

    const handleContactDelete = (id) => {
        UpdateStatusHelper({ update_id: id, status: "Inactive", column_name: ATS_CONTACT_PK, successMessage: "Contact Archived Successfully" })
    }

    const resetForm = () => {
        setContactData([])
        setConsultancyModalData(initialFValues);
        setErrors({});
        setCurrentMode("grid");
        dispatch({ type: "EMPTY_CONTACT" })
    };

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", pl: 2, pt: 2, pr: 2, pb: 3 }}>
                <ArrowBackButton onClick={resetForm} />
                <Button onClick={HandleSubmit} disabled={handleDisabled("hiring_partner_update_client")} variant="contained" size="small" color="primary" type="submit" className="mpl-primary-btn" sx={{ ml: 2 }}>
                    {currentData?.consultancy_id ? "Update" : "Save"}
                </Button>
            </Box>
            <Grid container>
                <Grid container>
                    <Grid key="name" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                autoFocus={true}
                                required
                                size="small"
                                value={consultancyModalData.name}
                                onChange={handleInputChange}
                                onKeyPress={(e) => e.target.value?.length >= 100 && e.preventDefault()}
                                onPaste={(e) => e.target.value?.length >= 100 && e.preventDefault()}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Org Name"
                                name="name"
                                autoComplete="name"
                                {...(errors.name && {
                                    error: true,
                                    helperText: errors.name,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="email_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.email_id}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                required
                                id="email_id"
                                label="Contact Email"
                                name="email_id"
                                autoComplete="email_id"
                                {...(errors.email_id && {
                                    error: true,
                                    helperText: errors.email_id,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="contact_person" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth>
                            <TextField
                                size="small"
                                required
                                value={consultancyModalData.contact_person}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="contact_person"
                                label="Contact Person"
                                onKeyPress={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                                onPaste={(e) => e.target.value?.length >= 35 && e.preventDefault()}
                                name="contact_person"
                                autoComplete="contact_person"
                                {...(errors.contact_person && {
                                    error: true,
                                    helperText: errors.contact_person,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="mobile_number" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                required
                                value={consultancyModalData.mobile_number}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="mobile_number"
                                label="Mobile"
                                name="mobile_number"
                                autoComplete="mobile_number"
                                {...(errors.mobile_number && {
                                    error: true,
                                    helperText: errors.mobile_number,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    {/* <Grid key="office_number" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.office_number}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="office_number"
                                label="Office Number"
                                name="office_number"
                                autoComplete="office_number"
                            />
                        </FormControl>
                    </Grid>

                    <Grid key="country_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    +option.country_id === +value.country_id
                                }
                                label="Country"
                                value={consultancyModalData?.country_id}
                                loading={() => handleCountryLoad()}
                                fieldLabel="country_name"
                                variant={"contained"}
                                name="country_id"
                                size="small"
                                data={CountryData}
                                onChangeEvent={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="state_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    +option.state_id === +value.state_id
                                }
                                label="State"
                                value={consultancyModalData?.state_id}
                                fieldLabel="state_name"
                                variant={"contained"}
                                name="state_id"
                                size="small"
                                loading={() => handleStateLoad()}
                                data={StateData}
                                onChangeEvent={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="city_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    +option.city_id === +value.city_id
                                }
                                label="City"
                                value={consultancyModalData?.city_id}
                                fieldLabel="city_name"
                                loading={() => handleCityLoad()}
                                variant={"contained"}
                                name="city_id"
                                size="small"
                                data={CityData}
                                onChangeEvent={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="zip_code" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.zip_code}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="zip_code"
                                label="Zip Code"
                                name="zip_code"
                                autoComplete="zip_code"
                                {...(errors.zip_code && {
                                    error: true,
                                    helperText: errors.zip_code,
                                })}
                            />
                        </FormControl>
                    </Grid>
                    <Grid key="website" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.website}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                onKeyPress={(e) => e.target.value?.length >= 254 && e.preventDefault()}
                                onPaste={(e) => e.target.value?.length >= 254 && e.preventDefault()}
                                id="website"
                                label="Website"
                                name="website"
                                autoComplete="website"
                            />
                        </FormControl>
                    </Grid> */}

                    <Grid key="primary_owner" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.primary_owner}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="primary_owner"
                                label="Primary Owner"
                                onKeyPress={(e) => e.target.value?.length >= 50 && e.preventDefault()}
                                onPaste={(e) => e.target.value?.length >= 50 && e.preventDefault()}
                                name="primary_owner"
                                autoComplete="primary_owner"
                            />
                        </FormControl>
                    </Grid>

                    {/* <Grid key="company_lead_id" item xs={12} md={4} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.company_lead_id}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="company_lead_id"
                                label="Lead"
                                onKeyPress={(e) => e.target.value?.length >= 50 && e.preventDefault()}
                                onPaste={(e) => e.target.value?.length >= 50 && e.preventDefault()}
                                name="company_lead_id"
                                autoComplete="company_lead_id"
                            />
                        </FormControl>
                    </Grid> */}
                    <Grid key="address" item xs={12} md={12} sx={{ marginTop: "1rem", paddingRight: "15px", paddingLeft: "15px" }}>
                        <FormControl fullWidth >
                            <TextField
                                size="small"
                                value={consultancyModalData.address}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                id="address"
                                label="Address"
                                name="address"
                                autoComplete="address"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                {currentData != null &&
                    <Grid container>
                        <Grid item xs={12}>
                            <AtsContact
                                contactData={contactData}
                                setContactData={setContactData}
                                contactValues={contactValues}
                                errors={contactErrors}
                                setErrors={setContactErrors}
                                contactValidate={contactValidate}
                                handleContactDelete={handleContactDelete}
                                idField="ats_contacts_id"
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
        </>
    );
}

export default AtsConsultancyForm;
