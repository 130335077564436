import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackButton from "../../../../Base/views/ArrowBackButton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Popup from "../../../../Base/views/Popup";
import {
  ApproveTimeSheetAction,
  GetTimeSheetApprovalAction,
} from "../../../../../../store/Leave/MyTeam/Action";
import { ConvertDateTime } from "../../../../Base/helper/baseFunctions";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderCollapse: "collapse",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  "&.total": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  "& .MuiTableCell-root": {
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
  },
}));

const headers = [
  "Project Task",
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Total",
];
const TeamApprove = ({ setFormMode, currentData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState("");
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});
  const { GetSuccess } = useSelector(
    (state) => state?.LeaveReducer?.TimeSheetApprovalReducer
  );
  const handleApprove = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: currentData?.week_no_pk,
      weekly_working_days: JSON.stringify(currentData?.weekly_hour),
      timesheet_id: currentData?.timesheet_pk,
      comments: comments,
      employee_id:currentData?.employee_pk
    });
  };
  const handleReject = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: currentData?.week_no_pk,
      weekly_working_days: JSON.stringify(currentData?.weekly_hour),
      timesheet_id: currentData?.timesheet_pk,
      comments: comments,
      employee_id:currentData?.employee_pk
    });
  };
  const handleReOpen = (status) => {
    setShowComments(true);
    setData({
      flag: status,
      week_no: currentData?.week_no_pk,
      weekly_working_days: JSON.stringify(currentData?.weekly_hour),
      timesheet_id: currentData?.timesheet_pk,
      comments: comments,
      employee_id:currentData?.employee_pk
    });
  };

  const handleConfirm = () => {
    data["comments"] = comments;
    dispatch(ApproveTimeSheetAction(data));
    setSuccess(true);
  };
  useEffect(() => {
    if (GetSuccess === 200 && success) {
      setShowComments(false);
      setComments("");
      setFormMode("card");

      dispatch(GetTimeSheetApprovalAction({ status: "Submitted"}));
    }
    //eslint-disable-next-line
  }, [GetSuccess, success]);
  const getEventColor = (status) => {
    switch (status) {
      case "Approved":
        return "#20e277";
      case "Submitted":
        return "#20e277";
      case "Rejected":
        return "#e68d45";
        case "Reopened":
          return "#fbeae8";
      default:
        return "#94b2d1";
    }
  };
  return (
    <>
      <Box
        sx={{
          padding: "30px 0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            },
          }}
        >
          <ArrowBackButton
            onClick={() => {
              setFormMode("card");
            }}
          />
          <Typography sx={{ padding: "10px" }} variant="h4">
            {currentData?.employee_name}
          </Typography>
          <Typography sx={{ padding: "10px" }} variant="h4">
            {currentData?.start_date} - {currentData?.end_date}
          </Typography>
        </Box>
        <Box></Box>
        <Box
          sx={{
            background: getEventColor(currentData?.flag),
            fontWeight: 500,
            color: "#000",
            fontSize: "12px",
            padding: "8px 10px",
            borderRadius: "50px",
            width: "fit-content",
            marginTop: "10px",
          }}
        >
          {currentData?.flag}
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
              marginRight: "10px",
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => handleApprove("Approved")}
            disabled={
              currentData?.flag === "Approved" || currentData?.flag === "Rejected"
            }
          >
            Approve
          </Button>
          <Button
            sx={{
              fontWeight: 700,
              borderRadius: "8px",
              fontSize: "13px",
              height: "30px",
              marginRight: "10px",
              padding: "4px 10px",
              boxShadow: "none",
              lineHeight: 1.71429,
            }}
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={() => handleReject("Rejected")}
            disabled={
              currentData?.flag === "Approved" || currentData?.flag === "Rejected"
            }
          >
            Reject
          </Button>
          
            <Button
              sx={{
                fontWeight: 700,
                borderRadius: "8px",
                fontSize: "13px",
                height: "30px",
                padding: "4px 10px",
                boxShadow: "none",
                lineHeight: 1.71429,
              }}
              size="small"
              variant="contained"
              className="mpl-primary-btn"
              onClick={() => handleReOpen("Reopened")}
            >
              ReOpen
            </Button>
        
        </Box>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Typography sx={{ padding: "10px" }} variant="h4">
          Time Distribution
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index}>{header}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>{currentData?.project_code}</StyledTableCell>
              {Object.entries(currentData?.weekly_hour?.PROJECTCODE).map(
                ([day, value]) => (
                  <StyledTableCell key={day}>{value}</StyledTableCell>
                )
              )}
              <StyledTableCell>
                {Object.values(currentData?.weekly_hour?.PROJECTCODE).reduce(
                  (total, value) => total + parseInt(value),
                  0
                )}
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ marginTop: "20px" }}>
        <Typography sx={{ padding: "10px" }} variant="h6">
          APPROVAL HISTORY
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Comments</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell component="th" scope="row">
                {ConvertDateTime(currentData?.modifiedon)}
                </StyledTableCell>
                <StyledTableCell>{currentData?.flag}</StyledTableCell>
                <StyledTableCell>{currentData?.employee_name}</StyledTableCell>
                <StyledTableCell>{currentData?.comments}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Popup
        title="Comments"
        openPopup={showComments}
        setOpenPopup={setShowComments}
        fullWidth={true}
      >
        <TextField
          autoFocus={false}
          size="small"
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          variant="outlined"
          fullWidth
          id="comments"
          label="Comments"
          name="comments"
        />
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="mpl-primary-btn"
            sx={{ marginTop: "1.75rem" }}
            onClick={handleConfirm}
          >
            Save
          </Button>
        </Box>
      </Popup>
    </>
  );
};

export default TeamApprove;
