import { toast } from "react-toastify";
import axiosInstance from "../../../../axiosInstance";
import { HRMS_RESUME_PARSER_API } from "../../../Constants/baseConstant";
export const ResumeParserHelper = async (data) => {
  return await axiosInstance({
    method: "POST",
    baseURL: HRMS_RESUME_PARSER_API,
    data: JSON.stringify(data),
  })
    .then((res) => {
      if(data?.job_id?.[0] === false){
        toast.success("Uploaded resumes parsed sucessfully")
      }
      else{
        toast.success("Parsing completed successfully.Candidate moved to not verified section.")
      }
      return { message: "Success", hasError: false, data: res?.data };
    })
    .catch((err) => {
      toast.error("Candidate record is already available")
      return { message: "Error" };
    });
}

