import {
  Box,
  Button,
  Paper,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createRef, useState, useEffect } from "react";
import ApplyLeave from "../../MyTimeSheet/TimeOff/ApplyLeave";
import Popup from "../../../../Base/views/Popup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { TeamTimeOffHelper } from "../../../helper/TimeOff/TimeOffHelper";

const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  margin: theme.spacing(1),
  border: "none !important",
  padding: "7px",
  "&:not(:first-of-type)": {
    borderRadius: `${theme.shape.borderRadius}px !important`,
  },
  "&:first-of-type": {
    borderRadius: `${theme.shape.borderRadius}px !important`,
  },
}));
const TeamTimeOff = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const calendarRef = createRef();
  const [title, setTitle] = useState();
  const [viewType, setViewType] = useState("dayGridMonth");
  const [calenderData, setCalenderData] = useState();
  useEffect(() => {
    let calendarApi = calendarRef.current.getApi();
    setTitle(calendarApi?.currentDataManager?.data?.viewTitle);
    TeamTimeOffHelper({
      month_year: calendarApi?.currentDataManager?.data?.viewTitle,
    }).then((res) => {
      setCalenderData(res?.data?.data?.[0]?.leaverequest);
    });
    //eslint-disable-next-line
  }, []);
  const handleBookClick = () => {
    setOpenPopup(true);
  };
  const handleViewTypeChange = (viewType) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(viewType);
    calendarApi = calendarRef.current.getApi();
    setTitle(calendarApi?.currentDataManager?.data?.viewTitle);
  };
  const handleAlignment = (event, newAlignment) => {
    setViewType(newAlignment);
  };
  const handleChange = (type) => {
    let calendarApi = calendarRef.current.getApi();
    if (type === "today") calendarApi.today();
    else if (type === "next") calendarApi?.next();
    else if (type === "prev") calendarApi?.prev();
    calendarApi = calendarRef.current.getApi();
    setTitle(calendarApi?.currentDataManager?.data?.viewTitle);
  };
  const eventContent = ({ event }) => {
    const backgroundColor = getEventColor(event.extendedProps.status);
    const borderColor = backgroundColor; // Optional: set border color same as background color
    const textColor = "#000000"; // Optional: set text color to white for better visibility

    return (
      <div
        style={{
          backgroundColor,
          borderColor,
          color: textColor,
          borderRadius: "0px",
          fontFamily: "Nunito Sans",
        }}
      >
        {event.extendedProps.name} - {event.extendedProps.Leavetype}
      </div>
    );
  };

  const getEventColor = (status) => {
    switch (status) {
      case "Approved":
        return "#40D696";
      case "Submitted":
        return "#EF962C ";
      case "Rejected":
        return "red";
      default:
        return "gray";
    }
  };
  return (
    <>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          sx={{
            fontWeight: 700,
            borderRadius: "8px",
            fontSize: "13px",
            height: "30px",
            padding: "4px 10px",
            boxShadow: "none",
            lineHeight: 1.71429,
            marginRight: "10px",
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={() => handleBookClick()}
        >
          Book TimeOff
        </Button>
      </Box>
      <Paper
        sx={{ borderRadius: "16px", mt: 1, backgroundColor: "#e6d9fb7a" }}
        elevation={2}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ToggleButtonGroup
              exclusive
              value={viewType}
              onChange={handleAlignment}
              aria-label="view type"
            >
              <ToggleButton
                onClick={() => handleViewTypeChange("dayGridMonth")}
                value="dayGridMonth"
                aria-label="Month"
              >
                <Tooltip title="Month">
                  <ViewModuleIcon fontSize="small" />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton onClick={() => handleChange("prev")}>
              <ArrowBackIosNewIcon sx={{ width: "0.7em", height: "0.75em" }} />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: "1.2rem",
                margin: "0px 16px 0px 16px",
              }}
            >
              {title}
            </Typography>
            <IconButton onClick={() => handleChange("next")}>
              <ArrowForwardIosIcon sx={{ width: "0.7em", height: "0.75em" }} />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => handleChange("today")}
              sx={{
                fontWeight: 700,
                borderRadius: "8px",
                fontSize: "13px",
                height: "30px",
                padding: "4px 10px",
                boxShadow: "none",
                lineHeight: 1.71429,
              }}
              size="small"
              variant="contained"
              className="mpl-primary-btn"
            >
              Today
            </Button>
          </div>
        </div>
        <FullCalendar
          headerToolbar={false}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          contentHeight={800}
          events={calenderData || []}
          eventContent={eventContent}
          ref={calendarRef}
        />
        <div style={{ marginTop: "10px",marginBottom:"20px", textAlign: "center", padding:"10px" }}>
          <div style={{ display: "inline-block", marginRight: "20px" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "#40D696",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></div>
            Approved
          </div>
          <div style={{ display: "inline-block", marginRight: "20px" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "#EF962C",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></div>
            Pending
          </div>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: "red",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></div>
            Rejected
          </div>
        </div>
      </Paper>
      <Popup
        fullWidth={true}
        maxWidth="lg"
        title="Book TimeOff"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <ApplyLeave setOpenPopup={setOpenPopup} />
      </Popup>
    </>
  );
};

export default TeamTimeOff;
