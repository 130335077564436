import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { grey } from "@mui/material/colors";

import { v4 as uuidv4 } from "uuid";
import {
  AddLeaveRequestAction,
  GetLeaveRequestAction,
} from "../../../../../../store/Leave/LeaveRequest/Action";
import CommonDatePicker from "../../../../Base/views/CommonDatePicker";
import SelectableSearch from "../../../../Base/views/SelectableSearch";
import {
  ConvertDate,
  convertBase64,
  getUserData,
} from "../../../../Base/helper/baseFunctions";
import ViewHolidayCalendar from "../../../../Leave/views/ManageLeave/ApplyLeave/HolidayCalendar";
import { GetLeaveOverallBalanceAction } from "../../../../../../store/Leave/LeaveDashboard/Action";
import { GetHolidayCalendarAction } from "../../../../../../store/Leave/HolidayCalendar/Action";
import ConfirmationDialog from "../../../../Base/views/ConfirmationDialog";
import { GetLeaveReasonAction } from "../../../../../../store/Leave/LeaveReason/Action";
import FileUploader from "../../../../ATS/views/ATSCandidate/FileUploader";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var getDaysArray = function (s, e) {
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d));
  }
  return a;
};

const ApplyLeave = ({ setOpenPopup, my_time_off, setFlag }) => {
  const initialFValues = {
    leavetype_id: "",
    year: new Date().getFullYear(),
    requested_days: "",
    from_date: null,
    is_from_half: false,
    to_date: null,
    is_to_half: false,
    status: "Submitted",
    note: "",
    leave_proof: "", //TODO
    lta_availed: false,
    is_from_half_value: "",
    is_to_half_value: "",
    leave_balance: "",
    leave_reason: "",
  };

  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [success, setSuccess] = useState(false);
  const { leaveOverallBalanceData } = useSelector(
    (state) => state.LeaveReducer.LeaveDashboardReducer
  );
  const { leaveRequestData, addSuccess } = useSelector(
    (state) => state?.LeaveReducer.LeaveRequestReducer
  );
  const { holidayCalendarData } = useSelector(
    (state) => state.LeaveReducer.HolidayCalendarReducer
  );
  const { leavereasonData } = useSelector(
    (state) => state.LeaveReducer.LeaveReasonReducer
  );
  const { dashboardData } = useSelector(
    (state) => state?.EmployeeDashboardReducer
  );
  let dashboard = dashboardData?.[0]?.reporting_manager_info?.[0];
  const [leaveTypesData, setLeaveTypesData] = useState({
    load: true,
    values: [],
  });
  const [leaveReasonData, setLeaveReasonData] = useState({
    load: true,
    values: [],
  });
  const [errors, setErrors] = useState({});
  const [leaveRequestModalData, setLeaveRequestModalData] =
    useState(initialFValues);
  const [reqDays, setReqDays] = useState(0);
  const [, startTransition] = useTransition();
  const [opensPopup, setOpensPopup] = useState(false);
  const [openLeaveAlert, setOpenLeaveAlert] = useState(false);
  const empData = getUserData();

  // CALCULATING (FROM DB)
  var getDatesArray = function (s, e) {
    for (
      var a = [], d = new Date(s);
      d <= new Date(e);
      d.setDate(d.getDate() + 1)
    ) {
      a.push(d.toLocaleDateString());
    }
    return a;
  };
  let empLeaveDates = [];
  leaveRequestData?.map((l) => {
    if (l?.status === "Approved") {
      empLeaveDates = empLeaveDates.concat(
        l?.from_date && l?.to_date ? getDatesArray(l.from_date, l.to_date) : []
      );
    }
    return "";
  });
  holidayCalendarData?.map((hc) => {
    if (hc?.date) {
      empLeaveDates.push(new Date(hc.date).toLocaleDateString());
    }
    return "";
  });

  var disableApply =
    (leaveRequestModalData?.is_from_half &&
      leaveRequestModalData?.is_from_half_value === "") ||
    (leaveRequestModalData?.is_to_half &&
      leaveRequestModalData?.is_to_half_value === "");

  useEffect(() => {
    setLeaveRequestModalData({
      ...leaveRequestModalData,
      employee_id: empData?.employee_id,
      employee: empData?.name,
      office_email: empData?.office_email,
    });
    if (addSuccess) {
      setLeaveRequestModalData({
        ...initialFValues,
        employee_id: empData?.employee_id,
        employee: empData?.name,
      });
      dispatch(GetLeaveOverallBalanceAction({ show: "no" }));
      dispatch(GetLeaveRequestAction({ show: "no" }));
      dispatch(
        GetHolidayCalendarAction({
          city_id: empData?.city_id,
          year: new Date().getFullYear(),
          show: "no",
        })
      );
      setFiles([]);
     
    }
    if(addSuccess && success){
      setOpenPopup(false)
      if(my_time_off){
        setFlag(true)
      }
    }
   
    dispatch(GetLeaveReasonAction());
  
    //eslint-disable-next-line
  }, [addSuccess]);

  var daylist =
    leaveRequestModalData?.from_date && leaveRequestModalData?.to_date
      ? getDaysArray(
          new Date(leaveRequestModalData?.from_date),
          new Date(leaveRequestModalData?.to_date)
        )
      : [];

  let disableSaturday = false;
  function disableDates(date) {
    if (disableSaturday) {
      return (
        [0, 6].includes(date.getDay()) ||
        empLeaveDates.includes(date.toLocaleDateString())
      );
    } else {
      return (
        date.getDay() === 0 || empLeaveDates.includes(date.toLocaleDateString())
      );
    }
  }

  const calculateRequestedDate = (event) => {
    if (["is_from_half", "is_to_half"].includes(event?.target?.name)) {
      if (
        event.target.name === "is_from_half" &&
        event.target.checked === false
      ) {
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event.target.name]: event.target.checked,
          is_from_half_value: "",
        });
      } else if (
        event.target.name === "is_to_half" &&
        event.target.checked === false
      ) {
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event.target.name]: event.target.checked,
          is_to_half_value: "",
        });
      } else {
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event.target.name]: event.target.checked,
          [`${event.target.name}_value`]:
            event.target.name === "is_from_half" ? "Second Half" : "First Half",
        });
      }
    } else {
      setLeaveRequestModalData({
        ...leaveRequestModalData,
        [event.target.name]: event.target.value,
        is_to_half: false,
        is_to_half_value: "",
        is_from_half: false,
        is_from_half_value: "",
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...leaveRequestModalData,
          [event.target.name]: event.target.value,
        });
    }

    if (
      ["Maternity Leave", "Paternity Leave"].includes(
        leaveRequestModalData?.leavetype_id?.leavetype_name
      )
    ) {
      if (event.target.name === "from_date") {
        let dates = new Date(event.target.value);
        let addDate = new Date(
          dates.setDate(
            dates.getDate() +
              leaveRequestModalData?.leavetype_id?.available_days
          )
        );
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event.target.name]: event.target.value,
          to_date:
            addDate?.getFullYear() +
            "-" +
            ("0" + (addDate?.getMonth() + 1)).slice(-2) +
            "-" +
            addDate?.getDate(),
        });
      } else {
        let dates = new Date(event.target.value);
        let addDate = new Date(
          dates.setDate(
            dates.getDate() -
              leaveRequestModalData?.leavetype_id?.available_days
          )
        );
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event.target.name]: event.target.value,
          from_date:
            addDate?.getFullYear() +
            "-" +
            ("0" + (addDate?.getMonth() + 1)).slice(-2) +
            "-" +
            addDate?.getDate(),
        });
      }
    }

    startTransition(() => {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate = new Date(
        event.target.name === "to_date"
          ? event.target.value
          : leaveRequestModalData?.to_date || ""
      );
      const secondDate = new Date(
        event.target.name === "from_date"
          ? event.target.value
          : leaveRequestModalData?.from_date || ""
      );
      var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      setReqDays(diffDays + 1);
      if (event?.target?.name === "is_from_half" && event?.target?.checked) {
        setReqDays(reqDays - 0.5);
      } else if (
        event?.target?.name === "is_from_half" &&
        event?.target?.checked === false
      ) {
        setReqDays(reqDays + 0.5);
      }
      if (event?.target?.name === "is_to_half" && event?.target?.checked) {
        setReqDays(reqDays - 0.5);
      } else if (
        event?.target?.name === "is_to_half" &&
        event?.target?.checked === false
      ) {
        setReqDays(reqDays + 0.5);
      }
    });
  };

  const calculateToHoliday = (event) => {
    var t = 0;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(
      event.target.name === "to_date"
        ? event.target.value
        : leaveRequestModalData?.to_date || ""
    );
    const secondDate = new Date(
      event.target.name === "from_date"
        ? event.target.value
        : leaveRequestModalData?.from_date || ""
    );
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    if (
      leaveRequestModalData?.from_date !== null &&
      new Date(event?.target?.value) >
        new Date(leaveRequestModalData?.from_date)
    ) {
      for (
        var dt = new Date(leaveRequestModalData?.from_date);
        dt <= new Date(event?.target?.value);
        dt.setDate(dt.getDate() + 1)
      ) {
        if ([0, 6].includes(dt.getDay())) {
          if (dt.getDay() === 6 && disableSaturday) {
            t = t + 1;
          } else if (
            dt.getDay() === 6 &&
            !disableSaturday &&
            empLeaveDates?.includes(dt.toLocaleDateString())
          ) {
            t = t + 1;
          } else if (dt.getDay() === 0) {
            t = t + 1;
          }
        } else if (empLeaveDates?.includes(dt.toLocaleDateString())) {
          t = t + 1;
        }
      }
      setReqDays(diffDays - t + 1);
    }
  };

  const calculateFromHoliday = (event) => {
    var t = 0;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(
      event.target.name === "to_date"
        ? event.target.value
        : leaveRequestModalData?.to_date || ""
    );
    const secondDate = new Date(
      event.target.name === "from_date"
        ? event.target.value
        : leaveRequestModalData?.from_date || ""
    );
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    if (
      leaveRequestModalData?.to_date !== null &&
      new Date(event?.target?.value) < new Date(leaveRequestModalData?.to_date)
    ) {
      for (
        var dt = new Date(event?.target?.value);
        dt <= new Date(leaveRequestModalData?.to_date);
        dt.setDate(dt.getDate() + 1)
      ) {
        if ([0, 6].includes(dt.getDay())) {
          if (dt.getDay() === 6 && disableSaturday) {
            t = t + 1;
          } else if (
            dt.getDay() === 6 &&
            !disableSaturday &&
            empLeaveDates?.includes(dt.toLocaleDateString())
          ) {
            t = t + 1;
          } else if (dt.getDay() === 0) {
            t = t + 1;
          }
        } else if (empLeaveDates.includes(dt.toLocaleDateString())) {
          t = t + 1;
        }
      }
      setReqDays(diffDays - t + 1);
    }
  };

  const handleInputChange = (event, value) => {
    if (
      ["primary_approver_id", "sec_approver_id", "leave_reason"].includes(event)
    ) {
      if (value) {
        setLeaveRequestModalData({ ...leaveRequestModalData, [event]: value });
      } else {
        setLeaveRequestModalData({ ...leaveRequestModalData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...setLeaveRequestModalData, [event]: value ? value : "" });
    } else if (event === "leavetype_id") {
      if (value) {
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event]: value,
          from_date: null,
          to_date: null,
          leave_reason: "",
        });
      } else {
        setLeaveRequestModalData({
          ...leaveRequestModalData,
          [event]: "",
          from_date: null,
          to_date: null,
          leave_reason: "",
        });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...setLeaveRequestModalData, [event]: value ? value : "" });
    } else if (event?.target?.name === "lta_availed") {
      setLeaveRequestModalData({
        ...leaveRequestModalData,
        [event.target.name]: event.target.checked,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...leaveRequestModalData,
          [event.target.name]: event.target.checked,
        });
    } else {
      setLeaveRequestModalData({
        ...leaveRequestModalData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...leaveRequestModalData,
          [event.target.name]: event.target.value,
        });
    }
  };
  const handleSetLeaveType = () => {
    if (empData?.gender === "Female") {
      setLeaveTypesData({
        load: false,
        values: leaveOverallBalanceData?.filter((pt) => pt.gender !== "Male"),
      });
    } else if (empData?.gender === "Male") {
      setLeaveTypesData({
        load: false,
        values: leaveOverallBalanceData?.filter((pt) => pt.gender !== "Female"),
      });
    } else {
      setLeaveTypesData({ load: false, values: leaveOverallBalanceData });
    }
  };

  const handleSetLeaveReason = () => {
    setLeaveReasonData({ load: false, values: leavereasonData });
  };

  const validate = (fieldValues = leaveRequestModalData) => {
    let temp = { ...errors };

    if ("employee_id" in fieldValues) {
      temp.employee_id =
        fieldValues.employee_id === "" ? "Employee is required" : "";
    }
    if ("leavetype_id" in fieldValues) {
      temp.leavetype_id =
        fieldValues.leavetype_id === "" ? "Leave Type is required" : "";
    }
    if ("from_date" in fieldValues) {
      temp.from_date =
        fieldValues.from_date === ("" || null) ? "From Date is required" : "";
    }
    if ("to_date" in fieldValues) {
      temp.to_date =
        fieldValues.to_date === ("" || null) ? "To Date is required" : "";
    }
    if ("leave_reason" in fieldValues) {
      temp.leave_reason =
        fieldValues.leave_reason === "" &&
        leaveRequestModalData?.leavetype_id?.is_unlimited
          ? "Leave Reason is required"
          : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = async (event) => {
    setOpenLeaveAlert(false);
    if (event.type === "click" || event.which === 13) {
      if (validate()) {
        let b64string = "";
        let filename = "";
        let content_type = "";
        if (files?.[0]) {
          b64string = await convertBase64(files?.[0]);
          let ext = files?.[0]?.name?.split(".").at(-1);
          content_type =
            ext === "pdf"
              ? "application/pdf"
              : ["png", "JPEG", "jpg"]?.includes(ext)
              ? "image/jpeg"
              : "application/msword";
          filename =
            `${empData?.domain}/LMS/LeaveProof/` + uuidv4() + "." + ext;
        }
        dispatch(
          AddLeaveRequestAction({
            ...leaveRequestModalData,
            leave_proof: filename,
            leave_proof_name: b64string,
            content_type: content_type,
            reqDays,
            dashboard,
          })
        );
        setSuccess(true)
      }
    }
  };

  const handleConfirmationAlert = (event) => {
    if (
      reqDays > leaveRequestModalData?.leavetype_id?.available_days &&
      leaveRequestModalData?.leavetype_id?.is_unlimited !== true
    )
      setOpenLeaveAlert(true);
    else handleSubmit(event);
  };

  return (
    <Box>
      <div className="leave-holiday-calendar">
        {opensPopup && (
          <ViewHolidayCalendar
            openPopup={opensPopup}
            setOpenPopup={setOpensPopup}
          />
        )}
        <ConfirmationDialog
          title="Do you wish to proceed?"
          content="You have requested leave more than the balance. It'll be considered as LOP"
          openDialog={openLeaveAlert}
          closeDialog={setOpenLeaveAlert}
          popupTitle="Country Management"
          OkButtonText="Proceed"
          onSubmit={handleSubmit}
        />
        <Grid
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
          item
          xs={5}
          sm={5}
          md={5}
          lg={5}
        >
          <Box
            sx={{
              background: "#94b2d1",
              fontWeight: 500,
              color: "#000",
              fontSize: "12px",
              padding: "8px 10px",
              borderRadius: "50px",
            }}
          >
            Not Submitted
          </Box>
        </Grid>
        <Grid container paddingBottom={"10px"}>
          <Grid item xs={12} md={7} padding={"10px"}>
            <Grid container>
              <Grid
                key="employee_id"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    value={empData?.name}
                    variant="outlined"
                    type={"text"}
                    fullWidth
                    size="small"
                    id="note"
                    label="Employee Name"
                    name="note"
                    disabled={true}
                  />
                </FormControl>
              </Grid>
              <Grid
                key="leavetype_id"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <SelectableSearch
                    label="Leave Type"
                    value={leaveRequestModalData?.leavetype_id}
                    loading={handleSetLeaveType}
                    fieldLabel="leavetype_name"
                    autoFocus={false}
                    required={true}
                    variant={"outlined"}
                    name="leavetype_id"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={leaveTypesData}
                    {...(errors.leavetype_id && {
                      error: true,
                      helperText: errors.leavetype_id,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid
                key="from_lta"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "0px",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControlLabel
                  sx={{ ".MuiFormControlLabel-label": { fontSize: "15px" } }}
                  control={
                    <Checkbox
                      size="medium"
                      name="lta_availed"
                      checked={leaveRequestModalData?.lta_availed}
                      onChange={handleInputChange}
                    />
                  }
                  label="LTA Availed?"
                />
              </Grid>
              <Grid
                key="to_lta"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "7px",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  cursor: "pointer",
                  color: "#0854ffcf",
                  textAlign: "right",
                }}
              >
                <div onClick={() => setOpensPopup(true)}>View Holidays</div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                key="from_date"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <CommonDatePicker
                    disableDates={disableDates}
                    size="small"
                    name="from_date"
                    label="From Date"
                    pickerType="date"
                    required={true}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={leaveRequestModalData?.from_date}
                    handleChange={(value) => {
                      calculateRequestedDate({
                        target: {
                          name: "from_date",
                          value: value
                            ? `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value?.getDate()).slice(-2)
                              }`
                            : null,
                        },
                      });
                      calculateFromHoliday({
                        target: {
                          name: "from_date",
                          value: value
                            ? `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value?.getDate()).slice(-2)
                              }`
                            : null,
                        },
                      });
                    }}
                    {...(errors.from_date && {
                      error: true,
                      helperText: errors.from_date,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid
                key="is_from_half"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <Grid
                  sx={{ display: "flex", alignItems: "flex-end" }}
                  container
                >
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      sx={{
                        ".MuiFormControlLabel-label": { fontSize: "15px" },
                      }}
                      control={
                        <Checkbox
                          size="small"
                          name="is_from_half"
                          checked={leaveRequestModalData?.is_from_half}
                          onChange={calculateRequestedDate}
                          disabled={
                            !(
                              leaveRequestModalData?.from_date &&
                              leaveRequestModalData?.to_date
                            ) ||
                            (leaveRequestModalData?.to_date ===
                              leaveRequestModalData?.from_date &&
                              leaveRequestModalData?.is_to_half)
                          }
                        />
                      }
                      label="Half-day"
                    />
                  </Grid>
                  {leaveRequestModalData?.is_from_half && (
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <RadioGroup
                          onChange={handleInputChange}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="is_from_half_value"
                          value={leaveRequestModalData.is_from_half_value}
                        >
                          {reqDays < 1 && (
                            <FormControlLabel
                              value="First Half"
                              control={<Radio size="small" />}
                              label="First Half"
                            />
                          )}
                          <FormControlLabel
                            value="Second Half"
                            control={<Radio size="small" />}
                            label="Second Half"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                key="to_date"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <CommonDatePicker
                    disableDates={disableDates}
                    size="small"
                    name="to_date"
                    label="To Date"
                    required={true}
                    pickerType="date"
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={leaveRequestModalData?.to_date}
                    minDate={
                      leaveRequestModalData?.from_date
                        ? new Date(leaveRequestModalData.from_date)
                        : ""
                    }
                    handleChange={(value) => {
                      calculateRequestedDate({
                        target: {
                          name: "to_date",
                          value: value
                            ? `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value?.getDate()).slice(-2)
                              }`
                            : null,
                        },
                      });
                      calculateToHoliday({
                        target: {
                          name: "to_date",
                          value: value
                            ? `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value?.getDate()).slice(-2)
                              }`
                            : null,
                        },
                      });
                    }}
                    {...(errors.to_date && {
                      error: true,
                      helperText: errors.to_date,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid
                key="is_to_half"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <Grid
                  sx={{ display: "flex", alignItems: "flex-end" }}
                  container
                >
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      sx={{
                        ".MuiFormControlLabel-label": { fontSize: "15px" },
                      }}
                      control={
                        <Checkbox
                          size="small"
                          name="is_to_half"
                          checked={leaveRequestModalData?.is_to_half}
                          onChange={calculateRequestedDate}
                          disabled={
                            !(
                              leaveRequestModalData?.from_date &&
                              leaveRequestModalData?.to_date
                            ) ||
                            (leaveRequestModalData?.to_date ===
                              leaveRequestModalData?.from_date &&
                              leaveRequestModalData?.is_from_half)
                          }
                        />
                      }
                      label="Half-day"
                    />
                  </Grid>
                  {leaveRequestModalData?.is_to_half && (
                    <Grid item xs={12} md={6}>
                      <FormControl>
                        <RadioGroup
                          onChange={handleInputChange}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="is_to_half_value"
                          value={leaveRequestModalData.is_to_half_value}
                        >
                          <FormControlLabel
                            value="First Half"
                            control={<Radio size="small" />}
                            label="First Half"
                          />
                          {reqDays < 1 && (
                            <FormControlLabel
                              value="Second Half"
                              control={<Radio size="small" />}
                              label="Second Half"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                key="leave_balance"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormLabel sx={{ marginRight: "5px" }}>
                  Current Leave Balance
                </FormLabel>
                <FormControlLabel
                  sx={{ marginLeft: "2px" }}
                  control={
                    <Chip
                      label={
                        (leaveRequestModalData?.leavetype_id?.available_days ||
                          0) + " day(s)"
                      }
                      color="success"
                      variant="outlined"
                      size="small"
                    />
                  }
                  label="&nbsp;"
                />
              </Grid>
              <Grid
                key="lta_availed"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormLabel sx={{ marginRight: "5px" }}>
                  Effective Leave
                </FormLabel>
                <FormControlLabel
                  sx={{ marginLeft: "2px" }}
                  control={
                    <Chip
                      label={isNaN(reqDays) ? 0 : reqDays + " day(s)"}
                      color="success"
                      variant="outlined"
                      size="small"
                    />
                  }
                  label="&nbsp;"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                key="note"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                {leaveRequestModalData?.leavetype_id?.is_unlimited && (
                  <SelectableSearch
                    label="Leave Reason"
                    value={leaveRequestModalData?.leave_reason}
                    loading={handleSetLeaveReason}
                    fieldLabel="reason_name"
                    autoFocus={false}
                    required={true}
                    variant={"outlined"}
                    name="leave_reason"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={leaveReasonData}
                    {...(errors.leave_reason && {
                      error: true,
                      helperText: errors.leave_reason,
                    })}
                  />
                )}
                <FormControl
                  fullWidth
                  sx={{
                    mt: leaveRequestModalData?.leavetype_id?.is_unlimited && 1,
                  }}
                >
                  <TextField
                    value={leaveRequestModalData?.note}
                    variant="outlined"
                    type={"text"}
                    fullWidth
                    onChange={handleInputChange}
                    id="note"
                    multiline
                    rows={
                      leaveRequestModalData?.leavetype_id?.is_unlimited
                        ? 1.8
                        : 4
                    }
                    label="Comment"
                    name="note"
                  />
                </FormControl>
              </Grid>
              <Grid
                key="file"
                item
                xs={12}
                md={12}
                sx={{
                  marginTop: "1.5rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <Grid
                  sx={{
                    textAlign: "center",
                    border: "2px",
                    padding: "20px",
                    borderRadius: "10px",
                    borderStyle: "dashed",
                    backgroundColor: "primary",
                    borderColor: grey[500],
                  }}
                >
                  <FileUploader
                    fileUrl={fileUrl}
                    setFileUrl={setFileUrl}
                    task={true}
                    files={files}
                    setFiles={setFiles}
                    filepath={leaveRequestModalData?.leave_proof}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem variant="middle" />
          <Grid item xs={12} md={4.8}>
            {leaveRequestModalData?.from_date &&
              leaveRequestModalData?.to_date && (
                <Box
                  sx={{
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "20px",
                    margin: "10px",
                  }}
                >
                  <Grid container>
                    <Grid mb={1} xs={12} md={8}>
                      <Typography>
                        As Of {ConvertDate(leaveRequestModalData?.from_date)} -{" "}
                        {ConvertDate(leaveRequestModalData?.to_date)}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                      mb={1}
                      xs={12}
                      md={4}
                    >
                      <Typography>Days</Typography>
                    </Grid>

                    <Grid pl={3} mb={1} xs={12} md={8}>
                      Balance before booking{" "}
                    </Grid>
                    <Grid
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                      mb={1}
                      xs={12}
                      md={4}
                    >
                      {leaveRequestModalData?.leavetype_id?.available_days || 0}
                    </Grid>
                    <Grid pl={3} mb={1} xs={12} md={8}>
                      This booking{" "}
                    </Grid>
                    <Grid
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                      mb={1}
                      xs={12}
                      md={4}
                    >
                      {isNaN(reqDays) ? 0 : reqDays}
                    </Grid>
                    <Grid pl={3} mb={1} xs={12} md={8}>
                      Balance after booking
                    </Grid>
                    <Grid
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                      mb={1}
                      xs={12}
                      md={4}
                    >
                      {leaveRequestModalData?.leavetype_id?.available_days -
                        reqDays || 0}
                    </Grid>
                  </Grid>
                </Box>
              )}
            <Grid item textAlign={"center"}>
              <Typography variant="h6" sx={{ marginTop: "15px" }}>
                Summary
              </Typography>
              <Box
                sx={{
                  padding: "5px",
                  mb: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 300,
                  overflow: "hidden",
                  overflowY: "auto",
                }}
              >
                {daylist?.map((res, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        padding: "5px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body1">
                        {monthNames[res?.getMonth()] + " " + res?.getDate()}
                      </Typography>

                      {res.getDay() === 0 ? (
                        <Box
                          sx={{
                            marginRight: "20px",
                            color: "rgb(46, 125, 50)",
                            backgroundColor: "rgba(46, 125, 50, 0.12)",
                            padding: "0rem 0.25rem",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            fontSize="0.75rem"
                            variant="subtitle1"
                          >
                            Holiday
                          </Typography>
                        </Box>
                      ) : disableSaturday && res.getDay() === 6 ? (
                        <Box
                          sx={{
                            marginRight: "20px",
                            color: "rgb(46, 125, 50)",
                            backgroundColor: "rgba(46, 125, 50, 0.12)",
                            padding: "0rem 0.25rem",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            fontSize="0.75rem"
                            variant="subtitle1"
                          >
                            Holiday
                          </Typography>
                        </Box>
                      ) : empLeaveDates.includes(res.toLocaleDateString()) ? (
                        <Box
                          sx={{
                            marginRight: "20px",
                            color: "rgb(46, 125, 50)",
                            backgroundColor: "rgba(46, 125, 50, 0.12)",
                            padding: "0rem 0.25rem",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            fontSize="0.75rem"
                            variant="subtitle1"
                          >
                            Holiday
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            marginRight: "20px",
                            color: "rgb(145, 85, 253)",
                            backgroundColor: "rgba(145, 85, 253, 0.12)",
                            padding: "0rem 0.25rem",
                            borderRadius: "10px",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            fontSize="0.75rem"
                            variant="subtitle1"
                          >
                            Working Day
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            margin: "5px",
            marginRight: "15px",
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "20px",
          }}
        >
          <Button
            size="small"
            variant="contained"
            className="mpl-primary-btn"
            onClick={handleConfirmationAlert}
            disabled={disableApply}
          >
            Apply
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default ApplyLeave;
