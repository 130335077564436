import * as ActionTypes from '../ActionTypes';

export const GetTimeSheetApprovalAction = (params) => {
    return {
        type: ActionTypes.TIME_SHEET_APPROVAL_GET_REQUEST,params
    }
}

export const ApproveTimeSheetAction = (params) => {
    return {
        type: ActionTypes.APPROVE_TIME_SHEET_REQUEST,params
    }
}

export const ApproveTimeOffAction = (params) => {
    return {
        type: ActionTypes.APPROVE_TIME_OFF_REQUEST,params
    }
}


