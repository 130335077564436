import { toast } from "react-toastify";
import NProgress from 'nprogress';
import axiosInstance from "../../../../axiosInstance";
import { HRMS_ATS_API_URL } from "../../../Constants/baseConstant";
import { getUserData } from "../../Base/helper/baseFunctions";

export const GetAtsCandidateByEmailHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getCandidatesByEmail`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            email_id: params?.email_id,
            uan_number: params?.uan_number,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddExternalCandidateHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addExternalCandidateToInternal`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_empid: user.ats_user_id,
            ext_candidate_id: params?.ext_candidate_id,
            candidate_data: params?.candidate_data,
            certificate_details: params?.certificate_details || null,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Candidate Validated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const MergeExternalCandidateHelper = async (params) => {
    var user = getUserData();
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateExternalCandidateToInternal`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_empid: user.ats_user_id,
            ext_candidate_id: params?.ext_candidate_id,
            internal_candidate_id: params?.internal_candidate_id,
            candidate_data: params?.candidate_data,
            certificate_details: params?.certificate_details || null
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Candidate Validated Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const GetAtsCandidateHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getCandidates`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: +user?.ats_user_id,
            location: params?.location || null,
            yoe: params?.yoe || null,
            notice_period: params?.notice_period || null,
            primary_skill: params?.primary_skill || null,
            secondary_skill: params?.secondary_skill || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
            job_post_id:params?.job_post_id || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddAtsCandidateHelper = async (params) => {
    
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addCandidate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain ? params?.domain : user?.domain,
            user_emp_id: +user?.ats_user_id,
            first_name: params?.first_name,
            last_name: params?.last_name,
            contact_no: params?.contact_no || null,
            email_id: params?.email_id,
            dob: params?.dob || null,
            designation: params?.designation || null,
            years_of_experience: params?.years_of_experience || null,
            current_ctc: params?.current_ctc || null,
            expected_pay: params?.expected_pay || null,
            current_employer: params?.current_employer,
            notice_period: params?.notice_period || null,
            notes: params?.notes || null,
            gender: params?.gender || null,
            primary_skill: params?.primary_skill || "",
            secondary_skill: params?.secondary_skill || "",
            status: "Active",
            resume: params?.resume || "",
            resume_name: params?.resume_name || null,
            identity_id: params?.identity_id?.identity_id || null,
            proof_number: params?.proof_number,
            location: params?.location || null,
            uan_number: params?.uan_number || null,
            alternative_mobile: params?.alternative_mobile || null,
            github_profile: params?.github_profile || null,
            linkedin_profile: params?.linkedin_profile || null,
            certificate_details: params?.certificate_details || null,
            content_type: params?.content_type || ""
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message || "Candidate added successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const UpdateAtsCandidateHelper = async (params) => {
    var user = getUserData();
    toast.info("Saving...")
    return await axiosInstance(`/ats/updateCandidate`, {
        method: "PUT",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain ? params?.domain : user?.domain,
            user_emp_id: +user?.ats_user_id || 0,
            first_name: params?.first_name,
            candidate_id: params?.candidate_id,
            last_name: params?.last_name,
            contact_no: params?.contact_no || null,
            email_id: params?.email_id,
            dob: params?.dob || null,
            designation: params?.designation || null,
            years_of_experience: params?.years_of_experience || null,
            current_ctc: params?.current_ctc || null,
            expected_pay: params?.expected_pay || null,
            current_employer: params?.current_employer,
            notice_period: params?.notice_period || null,
            notes: params?.notes || null,
            gender: params?.gender,
            primary_skill: params?.primary_skill,
            secondary_skill: params?.secondary_skill,
            status: "Active",
            resume: params?.resume || "",
            resume_name: params?.resume_name,
            old_resume_name: params?.old_resume_name,
            identity_id: params?.identity_id?.identity_id || null,
            proof_number: params?.proof_number,
            location: params?.location,
            uan_number: params?.uan_number || null,
            alternative_mobile: params?.alternative_mobile || null,
            github_profile: params?.github_profile || null,
            linkedin_profile: params?.linkedin_profile || null,
            certificate_details: params?.certificate_details || null,
            content_type: params?.content_type || ""
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message || "Candidate Updated successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const ShareCandidateResumeHelper = async (params) => {
    toast.dismiss();
    toast.info("Sending Email...")
    return await axiosInstance(`/ats/shareResume`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            to: params?.to,
            cc: params?.cc,
            bcc: params?.bcc,
            subject: params?.subject,
            email_content: params?.content,
            resume_path: params?.resume_path,
            resume_name: params?.resume_name,
            attachments: params?.attachments || false,
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success(params?.message || "Resume shared successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const AddCandidateFeedbackHistoryHelper = async (params) => {
    let user = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/addCandidateFeedbackHistory`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: params?.domain ? params?.domain : user?.domain,
            user_emp_id: params?.employee_id ? params?.employee_id : user?.ats_user_id || null,
            to_email: params?.to_email,
            requester_name: params?.requester_name,
            candidate: params?.candidate,
            ats_jobpost: params?.ats_jobpost,
            ats_job_stages: params?.ats_job_stages,
            feedback_response_id: params?.feedback_response_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Feedback Submitted successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}

export const DeleteCandidateCertificateHelper = async (params) => {
    let user = getUserData()
    toast.dismiss();
    toast.info("Saving...")
    return await axiosInstance(`/ats/deleteCandidateCertificate`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user?.domain,
            certificate_id: params?.certificate_id
        }
    })
        .then((res) => {
            toast.dismiss();
            toast.success("Certificate Deleted Successfully")
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            toast.dismiss();
            toast.error(err?.response?.data?.error?.[0]?.message)
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}


export const GetQuickSearchHelper = async (params) => {
    var user = getUserData();
    NProgress.start()
    return await axiosInstance(`/ats/getQuickSearch`, {
        method: "POST",
        baseURL: HRMS_ATS_API_URL,
        data: {
            workspace: user.domain,
            user_emp_id: +user?.ats_user_id,
            search: params?.search || null,
            pagenumber: params?.pagenumber || null,
            pagesize: params?.pagesize || null,
        }
    })
        .then((res) => {
            NProgress.done()
            return { message: "Success", hasError: false, data: res?.data }
        }).catch(err => {
            NProgress.done()
            return { message: "Error", hasError: true, data: err?.response?.data }
        })
}