import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { useDispatch, useSelector } from "react-redux";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { GetTimeSheetApprovalAction } from "../../../../../../store/Leave/MyTeam/Action";
import TeamApprove from "./TeamApprove";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)",
      borderCollapse: "collapse",
    },
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '14px',
  },
  [`&.${tableCellClasses.body}`]: {
    padding:"12px", // Adding padding of 8 for body cells
  },
}));

const ApproveTimeSheetGrid = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formMode, setFormMode] = useState("card");
  const [tableData, setTableData] = useState();
  const columns = [
    { id: "timeoff", label: "Time Off", align: "center", minWidth: 170 },
    { id: "work", label: "Work + Time Off", align: "center", minWidth: 100 },
  ];

  const { time_sheet_approval_data } = useSelector(
    (state) => state?.LeaveReducer?.TimeSheetApprovalReducer
  );
  useEffect(() => {
    dispatch(GetTimeSheetApprovalAction({ status: "Submitted" }));
    //eslint-disable-next-line
  }, []);
  const handleOpen = (data) => {
    setTableData(data);
    setFormMode("Form");
  };
  return ["Form"].includes(formMode) ? (
    <TeamApprove setFormMode={setFormMode} currentData={tableData} />
  ) : (
    <>
      <Box sx={{ marginTop: "20px" }}>
      </Box>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" rowSpan={2}>
                  User
                </StyledTableCell>
                <StyledTableCell align="center" rowSpan={2}>
                  Timesheet Period
                </StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>
                  Entered Time
                </StyledTableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            {time_sheet_approval_data?.length === 0 ? (
              <TableBody>
                <TableRow>
                  <StyledTableCell colSpan={6} align="center">
                  No Records found
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {time_sheet_approval_data?.map((row) => {
                  return (
                    <TableRow>
                    
                      <StyledTableCell
                        sx={{ cursor: "pointer", color: "blue" }}
                        onClick={() => handleOpen(row)}
                        align="center"
                      >
                        {row.employee_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">{`${row.start_date} - ${row.end_date}`}</StyledTableCell>
                      <StyledTableCell align="center">{row?.total_time_off}</StyledTableCell>
                      <StyledTableCell align="center">{row?.total_work_time}</StyledTableCell>
                      
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ApproveTimeSheetGrid;
