import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  Typography,
  Box,
  Grid,
  Tooltip,
  Card,
  CardContent,
  IconButton,
  Checkbox,
  Menu,
  List,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import {
  convertBase64,
  getUserData,
  s3Client,
} from "../../../Base/helper/baseFunctions";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { grey } from "@mui/material/colors";
import {
  GetOfferLetterTemplateAction,
} from "../../../../../store/ATS/FormTemplate/Action";
import { useDispatch } from "react-redux";
import {
  AddOfferLetterTemplateHelper,
  UpdateOfferLetterTemplateHelper,
} from "../../helper/AtsFormTemplateHelper";

function Prompt({ setCurrentMode, activeStep, setActiveStep, data, setData }) {
  const dispatch = useDispatch();
  const userData = getUserData();
 
  const [formFields, setFormFields] = useState([
    { name: "date", label: "Date", type: "date", required: true, disable:true },
    { name: "designation", label: "Designation", type: "text", required: true, disable:true },
    { name: "joiningdate", label: "Join Date", type: "date", required: true, disable:true },
  ]);
  const [formComponents, setFormComponents] = useState([]);
  const [formHeading, setFormHeading] = useState({ heading: "" });
  const [imgfiles, setimgFiles] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [logovalid, setLogoValid] = useState(false);
  const [offerletter, setOfferLetter] = useState({
    access_company_logo: false,
    access_footer: false,
    access_header: false,
    company_logo: "",
    footer: "",
    header: "",
  });
  const [menu, setMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    dispatch(GetOfferLetterTemplateAction());
    if (data?.offer_letter_template_id) {
      setFormHeading({ heading: data?.offer_letter_template_name });
      setFormFields(data?.offer_letter_template_fields);
      setOfferLetter({ ...data });
      data?.company_logo && getImageSignedUrl(data?.company_logo);
    }

    //eslint-disable-next-line
  }, []);

  const handleDeletes = (e) => {
    e.preventDefault();
    setimgFiles([]);
    setImageUrl("");
    setOfferLetter((prevData) => ({
      ...prevData,
      company_logo: "", // Setting image to an empty string
    }));
  };

  const getImageSignedUrl = async (currentData) => {
    let filename = currentData;
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: filename,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      ); // Adjustable expiration.
      setImageUrl(url);
      return url;
    } catch (err) {
      toast.error(err);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 2000000,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setimgFiles(acceptedFiles.map((file) => Object.assign(file)));
    },
    onDropRejected: () => {
      toast.error(
        "You can only image upload image with maximum size of 2 MB.",
        {
          duration: 2000,
        }
      );
    },
  });
  const img = imgfiles.map((file) => (
    <img
      style={{ borderRadius: "50%", padding: "2px" }}
      width="100%"
      height="100%"
      key={file.name}
      alt={file.name}
      className="single-file-image"
      src={URL.createObjectURL(file)}
    />
  ));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenu(true);
  };

  const addField = (params) => {
    const newField = {
      name: `field${formFields.length + 1}`,
      label: "",
      type: params?.field?.type,
      options: [],
      required: true,
      multiple: false,
    };
    setFormFields([...formFields, newField]);
    setMenu(!menu);
  };

  const removeField = (index, field) => {
    const updatedFields = [...formFields];
    const errorKeysToClear = [`label${index}`, `options${index}`];

    for (
      let optionIndex = 0;
      optionIndex < field.options?.length;
      optionIndex++
    ) {
      errorKeysToClear.push(`optionLabel${index}${optionIndex}`);
      errorKeysToClear.push(`optionValue${index}${optionIndex}`);
    }

    let temp = { ...errors };
    errorKeysToClear.forEach((key) => {
      temp[key] = "";
    });

    setErrors({
      ...temp,
    });
    updatedFields.splice(index, 1);
    setFormFields(updatedFields);
  };

  const createFieldObj = () => {
    const fieldObjects = formFields.map((field, index) => {
      const name = field.label.toLowerCase().replace(/ /g, "_");
      const fieldObj = {
        name: name,
        type: field.type,
        label: field.label,
        sequence_no: index,
        required: field?.required,
        disable: field?.disable
      };
      return fieldObj;
    });
    return fieldObjects;
  };

  const validate = (fieldValues, formHeading, offerletter) => {
    let temp = { ...errors };
    if ("heading" in formHeading) {
      temp.heading =
        formHeading.heading === "" ? "Form Heading is required" : "";
    }

    fieldValues.forEach((field, index) => {
      if (field.label === "") {
        temp[`label${index}`] = `Prompt ${index + 1} is required`;
      } else {
        temp[`label${index}`] = "";
      }
    });

    if ("access_header" in offerletter) {
      if (offerletter?.access_header) {
        temp.header = offerletter?.header === "" ? "Header is required" : "";
      } else {
        temp.header = "";
      }
    }
    if ("access_footer" in offerletter) {
      if (offerletter?.access_footer) {
        temp.footer = offerletter?.footer === "" ? "Footer is required" : "";
      } else {
        temp.footer = "";
      }
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleChange = (params) => {
    const updatedFields = [...formFields];
    if (params?.e?.target?.name === "field_type") {
      updatedFields[params?.index].type = params?.e.target.value;
      const errorKeysToClear = [
        `label${params?.index}`,
        `options${params?.index}`,
      ];
      for (
        let optionIndex = 0;
        optionIndex < params?.field.options.length;
        optionIndex++
      ) {
        errorKeysToClear.push(`optionLabel${params?.index}${optionIndex}`);
      }

      let temp = { ...errors };
      errorKeysToClear.forEach((key) => {
        temp[key] = "";
      });

      setErrors({
        ...temp,
      });
    }
    if (params?.e?.target?.name === "required") {
      updatedFields[params?.index].required = params?.e?.target?.checked;
    }
    if (params?.e?.target?.name === "field_label") {
      updatedFields[params?.index].label = params?.e.target.value;
    }
    setFormFields(updatedFields);
  };

  const handleInputChange = (event, value) => {
    if (["access_company_logo"].includes(event?.target?.name)) {
      setOfferLetter({
        ...offerletter,
        [event.target.name]: event.target.checked,
        company_logo: "",
      });
    } else if (["access_footer"].includes(event?.target?.name)) {
      setOfferLetter({
        ...offerletter,
        [event.target.name]: event.target.checked,
        footer: "",
      });
    } else if (["access_header"].includes(event?.target?.name)) {
      setOfferLetter({
        ...offerletter,
        [event.target.name]: event.target.checked,
        header: "",
      });
    } else {
      setOfferLetter({
        ...offerletter,
        [event.target.name]: event.target.value,
      });
    }
  };
  const onSubmit = async () => {
    if (validate(formFields, formHeading, offerletter)) {
      const dbObj = createFieldObj();
      if (offerletter.access_company_logo && imgfiles.length === 0 && !offerletter.company_logo) {
        setLogoValid(true);
        return;
      } else {
        setLogoValid(false);
      }
      let img_filename = "";
      let img_b64string = "";
      let old_image = "";
      if (imgfiles?.[0]) {
        img_b64string = await convertBase64(imgfiles?.[0]);
        let ext = imgfiles?.[0]?.name?.split(".").at(-1);
        img_filename =
          `${userData?.domain}/ATS/OfferLetter/CompanyLogo/` +
          uuidv4() +
          "." +
          ext;
        if (offerletter?.company_logo) {
          old_image = offerletter?.company_logo;
        }
      } else {
        img_filename = offerletter?.company_logo;
      }

      if (data?.offer_letter_template_id) {
        UpdateOfferLetterTemplateHelper({
          ...offerletter,
          offer_letter_template_id: data?.offer_letter_template_id,
          offer_letter_template_name: formHeading?.heading,
          offer_letter_template_fields: JSON.stringify(dbObj),
          company_logo: img_filename,
          image_name: img_b64string,
          old_image: old_image
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setFormHeading({ heading: "" });
            setFormFields([]);
            setFormComponents([]);
            setActiveStep(activeStep + 1);
            setData(res?.data?.data?.[0]);
          }
        });
      } else {
        AddOfferLetterTemplateHelper({
          ...offerletter,
          offer_letter_template_name: formHeading?.heading,
          offer_letter_template_fields: JSON.stringify(dbObj),
          company_logo: img_filename,
          image_name: img_b64string,
        }).then((res) => {
          if (res?.data?.statuscode === 200) {
            setFormHeading({ heading: "" });
            setFormFields([]);
            setFormComponents([]);
            setActiveStep(activeStep + 1);
            setData(res?.data?.data?.[0]);
          }
        });
      }
    }
  };

  return (
    <Box sx={{ maxHeight: "85vh", overflowY: "auto", pb: 2 }}>
      <Box>
        <Typography sx={{ mb: 1, textAlign: "center" }} variant="h6">
          {" "}
          Prompt Customization
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2} style={{ justifyContent: "center" }}>
            <Grid sx={{ width: "100%" }} item>
              <Card>
                <CardContent>
                  <TextField
                    label="Offer Letter Title"
                    size="small"
                    variant="standard"
                    margin="normal"
                    name="heading"
                    fullWidth
                    value={formHeading?.heading}
                    required={true}
                    sx={{ mb: 4 }}
                    inputProps={{ style: { fontSize: 23 } }}
                    InputLabelProps={{ style: { fontSize: 20 } }}
                    onChange={(e) =>
                      setFormHeading({ [e.target.name]: e.target.value })
                    }
                    {...(errors.heading && {
                      error: true,
                      helperText: errors.heading,
                    })}
                  />
                  <Box
                    style={{
                      backgroundColor: grey[100],
                      borderRadius: "10px",
                    }}
                  >
                    <Grid container spacing={2} direction={"row"} p={2}>
                      {formFields.map((field, index) => (
                        <Grid item xs={12} key={field.name}>
                          <Box display="flex" alignItems="center" mb={2}>
                            <TextField
                              label="Prompt"
                              value={field.label}
                              name="field_label"
                              size="small"
                              fullWidth
                              disabled={field.disable}
                              onChange={(e) => {
                                handleChange({ e, index });
                              }}
                              {...(errors[`label${index}`] && {
                                error: true,
                                helperText: errors[`label${index}`],
                              })}
                            />
                            <Tooltip title="Remove Field">
                              <IconButton
                                onClick={() => removeField(index, field)}
                                size="small"
                                color="error"
                                aria-label="delete"
                                mt={1}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          {field.type !== "radio" && (
                            <Box sx={{ marginRight:"25px"}} mb={2}>{formComponents[index]}</Box>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                    <Box sx={{ width: "100%", p: 2 }}>
                      <Tooltip title="Add New Prompt">
                        <IconButton
                          onClick={handleMenu}
                          className="mpl-primary-btn"
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        open={menu}
                        onClose={() => setMenu(!menu)}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        getContentAnchorEl={null}
                      >
                        <List
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 0,
                            pb: 0,
                          }}
                        >
                          <MenuItem
                            value="text"
                            name="field_type"
                            onClick={(e) => {
                              addField({ field: { type: "text" } });
                            }}
                          >
                            <TextFieldsIcon
                              color="primary"
                              fontSize="small"
                              sx={{ mr: 1 }}
                            />
                            Text
                          </MenuItem>
                        </List>
                      </Menu>
                    </Box>
                  </Box>
                </CardContent>
                <Grid sx={{ paddingLeft: "25px" }} container>
                  <Grid
                    key="access_header"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="access_header"
                          checked={offerletter?.access_header || false}
                          onChange={handleInputChange}
                        />
                      }
                      label="Include Offer Letter Header?"
                    />
                  </Grid>

                  <Grid
                    key="header"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    {offerletter?.access_header && (
                      <TextField
                        label="Header"
                        size="small"
                        multiline
                        rows={3}
                        variant="outlined"
                        name="header"
                        fullWidth
                        onChange={handleInputChange}
                        value={offerletter?.header}
                        {...(errors.header && {
                          error: true,
                          helperText: errors.header,
                        })}
                      />
                    )}
                  </Grid>

                  <Grid
                    key="access_footer"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="access_footer"
                          checked={offerletter?.access_footer || false}
                          onChange={handleInputChange}
                        />
                      }
                      label="Include Offer Letter Footer?"
                    />
                  </Grid>

                  <Grid
                    key="footer"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    {offerletter?.access_footer && (
                      <TextField
                        multiline
                        rows={3}
                        label="Footer"
                        size="small"
                        variant="outlined"
                        name="footer"
                        fullWidth
                        onChange={handleInputChange}
                        value={offerletter?.footer}
                        {...(errors.footer && {
                          error: true,
                          helperText: errors.footer,
                        })}
                      />
                    )}
                  </Grid>
                  <Grid
                    key="access_company_logo"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="access_company_logo"
                          checked={offerletter?.access_company_logo || false}
                          onChange={handleInputChange}
                        />
                      }
                      label="Include Company Logo in Offer Letter"
                    />
                  </Grid>
                  <Grid
                    key="company_logo"
                    item
                    xs={12}
                    md={6}
                    sx={{
                      marginTop: "1rem",
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    {offerletter?.access_company_logo && (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                marginBottom: "5px",
                                border: "none !important",
                                cursor: "default",
                              }}
                              {...getRootProps({ className: "dropzone" })}
                            >
                              <input {...getInputProps()} />
                              <div className="offer_presentation">
                                <div className="placeholder">
                                  {imgfiles.length ? (
                                    img
                                  ) : offerletter?.company_logo ? (
                                    <img
                                      style={{
                                        borderRadius: "50%",
                                        padding: "2px",
                                      }}
                                      alt="Nothing"
                                      width="100%"
                                      height="100%"
                                      src={imageUrl}
                                    />
                                  ) : (
                                    <>
                                      <AddPhotoAlternateIcon />
                                      <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                      >
                                        Upload Photo
                                      </Typography>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <Typography
                              sx={{ margin: "5px auto 0px" }}
                              variant="caption"
                              className="content-sec"
                            >
                              Only Allowed *.jpeg, *.jpg, *.png
                              <br /> max size of 2 MB
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <Tooltip title="Delete Image">
                              <DeleteIcon
                                sx={{ cursor: "pointer" }}
                                onClick={handleDeletes}
                                color="error"
                              />
                            </Tooltip>
                            {logovalid === true && (
                              <Typography
                                sx={{ color: "#FF4842", fontSize: "0.66rem" }}
                              >
                                Logo is required
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              padding: "0px 10px 0px 10px",
            }}
          >
            <Button
              variant="contained"
              className="mpl-primary-btn"
              onClick={() => setCurrentMode("grid")}
            >
              Back
            </Button>
            <Button
              variant="contained"
              className="mpl-primary-btn"
              onClick={onSubmit}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Prompt;
