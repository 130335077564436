import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import { Box, Paper, Tab } from '@mui/material';
import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Base from '../../../Base/views/Base';
import MyTimeSheetGrid from './TimeSheet/MyTimeSheetGrid';
import MyTimeOff from './TimeOff/MyTimeOff';
import { getUserData } from '../../../Base/helper/baseFunctions';
import { GetEmployeeDashboardAction } from '../../../../../store/EmployeeDashboard/Action';

const MyTimeSheetLandingPage = () => {
    const dispatch = useDispatch();
    const empData = getUserData();
    const [value, setValue] = useState('1');
    const [calendarValue, setCalendarValue] = useState();
    const handleChange = (event, newValue, calendarValue) => {
        setValue(newValue);
        setCalendarValue(calendarValue);
    };

    useEffect(() => {
        dispatch(GetEmployeeDashboardAction({ domain: empData?.domain, emp_id: empData?.employee_id }));
        //eslint-disable-next-line
    }, [])
    return (
        <Base>
          <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
            <Paper sx={{ borderRadius: "10px", minHeight: "87vh" }}>
                <Box sx={{ width: '100%' }}>
                    <TabContext value={value}>
                        <Box>
                            <TabList sx={{
                                minHeight: "40px", ".MuiTab-root": { minHeight: "20px" },
                                '& .MuiTabs-indicator': {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: "center !important",
                                },
                            }} onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Time Sheets" value="1" />
                                <Tab label="Time Off" value="2" />
                            </TabList>
                        </Box>
                       
                        <TabPanel value="1" sx={{ padding: "0px" }}>
                            <MyTimeSheetGrid />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            <MyTimeOff calendarValue={calendarValue} />
                        </TabPanel>
                       
                    </TabContext>
                </Box>
            </Paper>
            </Box>
        </Base>
    )
}

export default MyTimeSheetLandingPage;