import { TextField } from "@mui/material";
import React from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CommonDateTimePicker = ({
    name,
    size,
    label,
    pickerFormat,
    DefaultDTvalue,
    handleChange,
    openTo,
    views = ["year", "month", "day", "hours", "minutes"],
    error,
    helperText,
    variant,
    maxDate,
    minDate,
    disableDates,
    disabled,
    required,
    onKeyPress
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
                {...(openTo && {
                    openTo: openTo,
                })}
                views={views}
                shouldDisableDate={disableDates}
                minDate={minDate}
                maxDate={maxDate}
                name={name}
                label={label}
                inputFormat={pickerFormat}
                value={DefaultDTvalue}
                onChange={handleChange}
                disabled={disabled}
                renderInput={(params) => <TextField {...params} variant={variant} onKeyPress={onKeyPress} disabled={disabled} size={size} error={error} required={required} helperText={helperText} />}
            />
        </LocalizationProvider>
    );
};

export default CommonDateTimePicker;
