import { Box, Paper } from "@mui/material"
import Base from "../../../Base/views/Base"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab'
import { useEffect, useState } from "react";
import AtsTargetAchievementReports from "./AtsTargetAchievementReports";
import DailyProductivityReport from "./DailyProductivityReport";
import QualityReport from "./QualityReport";
import MonthlyReport from "./MonthlyReport";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { GetReportRecruiterAction } from "../../../../../store/ATS/Report/Action";

const AtsReportLandingPage = () => {
    const location = useLocation();
    const defaultValue = location.state?.defaultValue || "";
    const [value, setValue] = useState(defaultValue ? defaultValue : 0);
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetReportRecruiterAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Paper sx={{ borderRadius: "10px", maxHeight: "87vh", overflowY: "auto" }}>
                        {children}
                    </Paper>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Base>
           <Box sx={{ maxHeight: "87vh", overflowY: 'auto' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ mb: 1 }}>
                    
                    <Tab label="Daily Productivity" {...a11yProps(0)} />
                    <Tab label="Monthly Productivity" {...a11yProps(1)} />
                    <Tab label="Target Achievement" {...a11yProps(2)} />
                    <Tab label="Quality" {...a11yProps(3)} />
                 
                </Tabs>
               
                <TabPanel value={value} index={0}>
                    <DailyProductivityReport/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                     <MonthlyReport />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <AtsTargetAchievementReports />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <QualityReport />
                  
                </TabPanel>
            </Box>
        </Base>
    )
}

export default AtsReportLandingPage;