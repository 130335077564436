import { useState, useEffect } from "react";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  MenuItem,
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MaterialTable from "material-table";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Popup from "../../../../../Base/views/Popup";
import AtsCandidatePreview from "../../AtsCandidatePreview";
import {
  GetAtsJobKanbanHelper,
  GetAtsJobKanbanOfferHelper,
  UpdateAtsJobKanbanHelper,
} from "../../../../helper/AtsJobKanbanHelper";
import { GetAtsJobStageHelper } from "../../../../helper/AtsJobStageHelper";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsCandidateAction } from "../../../../../../../store/ATS/Candidate/Action";
import AtsCandidateValidate from "../../../ATSCandidate/AtsCandidateValidate";
import FlagCandidatePopup from "../FlagCandidatePopup";
import RejectPopup from "../RejectPopup";
import AcceptPopup from "../AcceptPopup";
import { ConvertDateTime } from "../../../../../Base/helper/baseFunctions";
import { AddAtsInterviewDetaiilsAction } from "../../../../../../../store/ATS/InterviewDetails/Action";
import ConfirmationDialog from "../../../../../Base/views/ConfirmationDialog";
import { UpdateStatusHelper } from "../../../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper";
import {
  ATS_CANDIDATE,
  ATS_CANDIDATE_JOB,
} from "../../../../../../Constants/baseConstant";
import ContactEmailForm from "../../../ATSCandidate/ContactEmailForm";
import CommonDateTimePicker from "../../../../../Base/views/CommonDateTimePicker";
import { PowerUserDisabledAction } from "../../../../../../../store/RoleAccess/Permission/Action";

function TableBoard({
  currentJobData,
  candidatevalueData,
  setCandidateUpdate,
  candidateUpdate,
  setCandidatePreview,
  toggleDrawer,
  setAnchor,
  anchor,
  setCurrentData,
  currentData,
  filters,
  sortValue,
}) {
  const initialFValues = {
    candidate_job_id: "",
    ats_jobpost_id: "",
    interview_date: "",
    postponed_date: "",
    venue: "",
    modeof_interview: "",
    scheduled_by: "",
    interviewer: "",
    comment: "",
  };

  const dispatch = useDispatch();
  const [inputsData, setInputsData] = useState(initialFValues);
  const [elements, setElements] = useState([]);
  const [atsJobStageData, setAtsJobStageData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openemailPopup, setOpenEmailPopup] = useState(false);
  const [inputData, setInputData] = useState({ user: "", note: "" });
  const [errors, setErrors] = useState({});
  const [reject, setReject] = useState(false);
  const [accept, setAccept] = useState(false);
  const [stagesData, setStagesData] = useState({ load: true, values: [] });
  const [isverified, setIsVerified] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorE, setAnchorE] = useState(null);
  const [clickedData, setClickedData] = useState(null);
  const [values, setValues] = useState({});
  const [FlagCandidate, setFlagCandidate] = useState(false);
  const open = Boolean(anchorEl);
  const { featuresData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer
  );
  useEffect(() => {
    GetAtsJobStageHelper({
      ats_jobpost_id: currentJobData?.ats_jobpost_id,
    }).then((res) => {
      setAtsJobStageData(res?.data?.data);
    });
    getKanbanData();
    dispatch(GetAtsCandidateAction());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (candidateUpdate === true) {
      GetAtsJobStageHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        setAtsJobStageData(res?.data?.data);
      });
      getKanbanData();
      dispatch(GetAtsCandidateAction());
      setCurrentData(candidatevalueData);
      setCandidateUpdate(false);
    }
    // eslint-disable-next-line
  }, [candidateUpdate]);
  useEffect(() => {
    if (
      filters?.assignee_filter?.length > 0 ||
      filters?.proposed_filter?.length > 0 ||
      sortValue?.name === "priority"
    ) {
      let precruiter = [];
      let propose = [];
      filters?.assignee_filter?.forEach((res) =>
        precruiter.push(res?.recruiter_id)
      );
      filters?.proposed_filter?.forEach((res) =>
        propose.push(res?.recruiter_id)
      );
      const assigned_to_id =
        filters?.assignee_filter?.length > 0
          ? construcData(
              filters?.assignee_filter,
              "assigned_to_id",
              "recruiter_id"
            )
          : null;
      const proposed_by_name =
        filters?.proposed_filter?.length > 0
          ? construcData(
              filters?.proposed_filter,
              "proposed_by_name",
              "proposed_by_name"
            )
          : null;
      if (currentJobData?.offer_letter) {
        GetAtsJobKanbanOfferHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          assigned_to_id: assigned_to_id,
          proposed_by_name: proposed_by_name,
          sort_order: sortValue?.order,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
        });
      } else {
        GetAtsJobKanbanHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
          assigned_to_id: assigned_to_id,
          proposed_by_name: proposed_by_name,
          sort_order: sortValue?.order,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
        });
      }
    } else {
      getKanbanData();
    }
    // eslint-disable-next-line
  }, [filters, sortValue]);

  const handleDisabled = (permission) => {
    let value = featuresData?.find((item) => item?.permission === permission);
    return value ? true : false;
  };

  const handlePowerDisabled = (e) => {
    e.stopPropagation();
    dispatch(PowerUserDisabledAction());
  };
  const construcData = (value, label, key) => {
    var temp = [];
    value?.forEach((res) => {
      temp.push({ [label]: res[key] });
    });
    return JSON.stringify(temp);
  };
  const getKanbanData = () => {
    if (currentJobData?.offer_letter) {
      GetAtsJobKanbanOfferHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
      });
    } else {
      GetAtsJobKanbanHelper({
        ats_jobpost_id: currentJobData?.ats_jobpost_id,
      }).then((res) => {
        res?.data?.data && setElements(res?.data?.data);
      });
    }
  };

  const openEmailPopup = (event, data) => {
    event.stopPropagation();
    setOpenEmailPopup(true);
    setCurrentData(data);
    handleClose();
  };
  const handleInputChange = (e, value) => {
    if ("interview_date" === e) {
      setInputsData({ ...inputsData, [e]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputsData, [e]: value });
    } else {
      setInputsData({ ...inputsData, [e.target.name]: e.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...inputsData, [e.target.name]: e.target.value });
    }
    if (value) {
      setInputData({ ...inputData, user: value });
    } else {
      setInputData({ ...inputData, user: "" });
    }
  };
  const handleValues = (event, value) => {
    if (["stage_id"].includes(event)) {
      setValues({ ...values, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...value, [event]: value });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...values, [event.target.name]: event.target.value });
    }
  };
  const validate = (inputData) => {
    let temp = { ...errors };
    if ("joined_date" in inputData) {
      temp.joined_date =
        inputData.joined_date !== "dd/mm/yyyy" ? "" : "Join date is required";
    }
    if ("tentative_joining_date" in inputData) {
      temp.tentative_joining_date =
        inputData.tentative_joining_date !== "dd/mm/yyyy"
          ? ""
          : "Tentative joning date is required";
    }
    if ("reason" in inputData) {
      temp.reason = inputData?.reason === "" ? "Reason is required" : "";
    }
    if ("flag_reason" in inputData) {
      temp.flag_reason =
        inputData?.flag_reason === "" ? "Reason is required" : "";
    }
    if ("revert_reason" in inputData) {
      temp.revert_reason =
        inputData?.revert_reason === "" ? "Revert Reason is required" : "";
    }
    if ("stage_id" in inputData) {
      temp.stage_id = ["", null].includes(inputData?.stage_id)
        ? "Moving Stage is required"
        : "";
    }
    if ("interview_date" in inputData) {
      temp.interview_date =
        inputData.interview_date === "" ? "Interview Date is required" : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleVerify = () => {
    setIsVerified(false);
    setShowValidate(true);
  };

  const handleStageDataLoading = () => {
    setStagesData({ load: false, values: atsJobStageData });
  };
  const handleClick = (event, data) => {
    setClickedData(data);
    setAnchorEl(event.currentTarget);
  };
  const handleCardClick = (event, data) => {
    if (data?.is_verified === true) {
      setCurrentData(data);
      setAnchor(true);
    } else {
      setCurrentData(data);
      setIsVerified(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleScheduleInterview = (event, data) => {
    if (data?.is_verified === true) {
      event.stopPropagation();
      setCurrentData(data);
      setInputsData({
        ...inputsData,
        candidate_job_id: {
          candidate_job_id: data?.candidate_job_id,
          first_name: data?.first_name,
          email_id: data?.email_id,
        },
        ats_jobpost_id: {
          ats_jobpost_id: data?.ats_jobpost_id,
          title: data?.title,
        },
      });
      setOpenPopup(true);
    } else {
      setCurrentData(data);
      setIsVerified(true);
    }
  };
  const HandleSubmits = (e) => {
    e.preventDefault();
    if (validate(inputsData)) {
      let date = inputsData?.interview_date;
      dispatch(
        AddAtsInterviewDetaiilsAction({
          ...inputsData,
          interview_date: `${
            date?.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2) +
            " " +
            ("0" + date.getHours()).slice(-2) +
            ":" +
            ("0" + date.getMinutes()).slice(-2) +
            ":" +
            ("0" + date.getSeconds()).slice(-2)
          }`,
        })
      );
      resetForm();
    }
  };
  const resetForm = () => {
    setInputsData(initialFValues);
    setErrors({});
    setOpenPopup(false);
    setValues({});
  };

  const handleArchiveClick = (event, data) => {
    event.stopPropagation();
    setAnchorE(null);
    setOpenDialog(true);
  };
  const FlagCandidateClick = (event, data) => {
    event.stopPropagation();
    if (data?.flag_reason) {
      setValues({ flag_reason: data?.flag_reason });
    } else {
      setValues({ flag_reason: "" });
    }
    setFlagCandidate(true);
  };
  const handleConfirmArchive = () => {
    UpdateStatusHelper({
      update_id: clickedData?.candidate_job_id,
      status: "Inactive",
      column_name: ATS_CANDIDATE_JOB,
      successMessage: "Candidate Archived Successfully",
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        getKanbanData();
        clickedData?.is_external &&
          UpdateStatusHelper({
            update_id: clickedData?.candidate_id,
            status: "Inactive",
            column_name: ATS_CANDIDATE,
            successMessage: "Candidate Archived Successfully",
            hide: true,
          });
      }
    });
    setOpenDialog(false);
  };
  const handleReject = (e, data) => {
    e.stopPropagation();
    if (data?.job_stage_name === "Rejected") {
      setAccept(true);
      setValues({ revert_reason: "", stage_id: "" });
    } else {
      setReject(true);
      let rejectData = atsJobStageData?.find((res) => res?.name === "Rejected");
      setValues({ reason: "", stage_id: rejectData?.ats_job_stages_id });
    }
  };
  const handleRejectClick = () => {
    if (validate(values)) {
      UpdateAtsJobKanbanHelper({
        reject,
        kanban_data: JSON.stringify([
          {
            candidate_job_id: +clickedData?.candidate_job_id,
            ats_jobpost_id: clickedData?.ats_jobpost_id,
            interview_feedback: clickedData?.interview_feedback,
            ats_job_stages_id:
              typeof values?.stage_id === "object"
                ? values?.stage_id?.ats_job_stages_id
                : values?.stage_id,
            priority: clickedData?.priority || 5,
            ats_status_id: null,
            selected: clickedData?.selected || null,
            candidate_id: clickedData?.candidate_id,
            accepted_ctc: clickedData?.accepted_ctc
              ? clickedData?.accepted_ctc
              : 0,
            tentative_joining_date: clickedData?.tentative_joining_date,
            joined_ctc: clickedData?.joined_ctc,
            joined_date: clickedData?.joined_date,
            offer_released_date: clickedData?.offer_released_date,
            rejected_reason: values?.reason || null,
            reverted_reason: values?.revert_reason || null,
          },
        ]),
      }).then((res) => {
        getKanbanData();
      });
      setErrors([]);
      setAccept(false);
      setReject(false);
    }
  };

  const handleFlagCandidateClick = () => {
    if (validate(values)) {
      UpdateAtsJobKanbanHelper({
        kanban_data: JSON.stringify([
          {
            candidate_job_id: +clickedData?.candidate_job_id,
            ats_jobpost_id: clickedData?.ats_jobpost_id,
            interview_feedback: clickedData?.interview_feedback,
            ats_job_stages_id: clickedData?.ats_job_stages_id,
            priority: clickedData?.priority || 5,
            ats_status_id: null,
            selected: clickedData?.selected || null,
            candidate_id: clickedData?.candidate_id,
            accepted_ctc: clickedData?.accepted_ctc
              ? clickedData?.accepted_ctc
              : 0,
            tentative_joining_date: clickedData?.tentative_joining_date,
            joined_ctc: clickedData?.joined_ctc,
            joined_date: clickedData?.joined_date,
            offer_released_date: clickedData?.offer_released_date,
            rejected_reason: values?.reason || null,
            reverted_reason: values?.revert_reason || null,
            flag_reason: values?.flag_reason || null,
          },
        ]),
      }).then((res) => {
        getKanbanData();
      });
      setErrors([]);
      setFlagCandidate(false);
    }
  };
  return (
    <>
      {showValidate && (
        <AtsCandidateValidate
          setOpenValidatePopup={setShowValidate}
          openValidatePopup={showValidate}
          currentData={currentData}
          getKanbanData={getKanbanData}
          kanban={true}
        />
      )}
      <MaterialTable
        onRowClick={(e, rowData) => handleCardClick(e, rowData)}
        columns={[
          {
            title: "Name",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "full_name",
            grouping: false,
            render: (rowData) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ margin: "0px 0px 0px 0px" }}>
                    {rowData?.full_name}
                  </span>
                  {rowData?.flag_reason && (
                    <Tooltip title={rowData?.flag_reason}>
                      <FlagOutlinedIcon fontSize="small" color="error" />
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
          {
            title: "Email",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "email_id",
            grouping: false,
          },
          {
            title: "Stage",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "job_stage_name",
            grouping: false,
          },
          {
            title: "Assignee",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "assignee_name",
            grouping: false,
          },
          {
            title: "Proposed By",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "comp_cons_name",
            grouping: false,
          },
          {
            title: "Candidate Score",
            cellStyle: { padding: "10px 10px 10px 30px" },
            field: "priority",
            grouping: false,
          },
          {
            title: "Modified Date",
            cellStyle: { padding: "10px 10px 10px 13px" },
            field: "modifiedon",
            grouping: false,
            render: (rowData) => {
              return ConvertDateTime(rowData?.modifiedon);
            },
          },
        ]}
        data={elements || []}
        options={{
          maxBodyHeight: "69vh",
          minBodyHeight: "69vh",
          rowStyle: { fontSize: "13px" },
          sorting: true,
          search: true,
          searchText: "",
          searchFieldAlignment: "right",
          searchAutoFocus: true,
          searchFieldVariant: "standard",
          filtering: false,
          paging: true,
          pageSizeOptions: [25, 50],
          pageSize: 25,
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          paginationPosition: "bottom",
          exportButton: true,
          exportAllData: true,
          exportFileName: "Candidates Details",
          addRowPosition: "first",
          selection: false,
          actionsColumnIndex: -1,
          showSelectAllCheckbox: true,
          showTextRowsSelected: true,
          grouping: false,
          columnsButton: false,
        }}
        actions={[
          {
            icon: () => <MoreVertIcon fontSize="small" />,
            position: "row",
            tooltip: "Settings",
            onClick: (_event, rowData) => handleClick(_event, rowData),
          },
        ]}
        title=""
      />

      <Menu
        id="more-options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-options-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            openEmailPopup(e, clickedData);
          }}
        >
          <ListItemIcon>
            <ForwardToInboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Contact Email</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDisabled("job_post_kanban_update_candidate")
              ? handlePowerDisabled(e)
              : handleScheduleInterview(e, clickedData);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CalendarMonthIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Schedule Interview</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDisabled("job_post_kanban_update_candidate")
              ? handlePowerDisabled(e)
              : handleReject(e, clickedData);
            handleClose();
          }}
        >
          <ListItemIcon>
            {clickedData?.job_stage_name === "Rejected" ? (
              <CheckOutlinedIcon color="success" fontSize="small" />
            ) : (
              <PersonOffOutlinedIcon color="error" fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>
            {clickedData?.job_stage_name === "Rejected" ? "Select" : "Reject"}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDisabled("job_post_kanban_update_candidate")
              ? handlePowerDisabled(e)
              : handleArchiveClick(e, clickedData);
            handleClose();
          }}
        >
          <ListItemIcon>
            <PersonRemoveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleDisabled("job_post_kanban_update_candidate")
              ? handlePowerDisabled(e)
              : FlagCandidateClick(e, clickedData);
            handleClose();
          }}
        >
          <ListItemIcon>
            <FlagOutlinedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Flag Candidate</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title="Archive"
        content="The candidate will be removed from this job, and you can still propose the candidate to other Jobs. Are you sure want to archive?"
        anchorE={anchorE}
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Job"
        OkButtonText="Archive"
        onSubmit={handleConfirmArchive}
      />
      <Popup
        fullWidth={true}
        maxWidth="md"
        close={false}
        openPopup={anchor}
        hideDividers={true}
      >
        <AtsCandidatePreview
          setCandidateUpdate={setCandidateUpdate}
          setCandidatePreview={setCandidatePreview}
          setElements={setElements}
          toggleDrawer={toggleDrawer}
          stageData={atsJobStageData}
          data={currentData}
          currentJobData={currentJobData}
        />
      </Popup>

      <Popup
        maxWidth="lg"
        title="Candidate is not Verified"
        isMasterProduct={false}
        setOpenPopup={setIsVerified}
        openPopup={isverified}
      >
        <Box minWidth="400px">
          <FormControl>
            <FormLabel id="gender-label">
              The Selected Candidate is not Verified
            </FormLabel>
          </FormControl>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              className="mpl-secondary-btn"
              onClick={() => setIsVerified(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              className="mpl-primary-btn"
              onClick={() => handleVerify()}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </Popup>

      <Popup
        fullWidth={true}
        maxWidth="xs"
        title="Schedule Interview"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Grid container spacing={2}>
          <Grid key="interview_date" item xs={12} md={12}>
            <FormControl fullWidth>
              <CommonDateTimePicker
                size="small"
                name="interview_date"
                required
                label="Interview Date"
                pickerType="datetime"
                pickerFormat="dd/MM/yyyy hh:mm a"
                DefaultDTvalue={inputsData?.interview_date}
                handleChange={(value) =>
                  handleInputChange("interview_date", value)
                }
                {...(errors.interview_date && {
                  error: true,
                  helperText: errors.interview_date,
                })}
              />
            </FormControl>
          </Grid>
          <Grid key="venue" item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                size="small"
                value={inputsData.venue}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="venue"
                label="Venue"
                name="venue"
                autoComplete="venue"
              />
            </FormControl>
          </Grid>
          <Grid key="modeof_interview" item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                size="small"
                value={inputsData.modeof_interview}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="modeof_interview"
                label="Mode of Interview"
                name="modeof_interview"
                autoComplete="modeof_interview"
              />
            </FormControl>
          </Grid>
          <Grid key="interviewer" item xs={12} md={12}>
            <FormControl fullWidth>
              <TextField
                size="small"
                value={inputsData.interviewer}
                onChange={handleInputChange}
                variant="outlined"
                fullWidth
                id="interviewer"
                label="Interviewer"
                name="interviewer"
                autoComplete="interviewer"
              />
            </FormControl>
          </Grid>
          <Grid display="flex" justifyContent="flex-end" container>
            <Button
              sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
              variant="outlined"
              onClick={() => setOpenPopup(false)}
            >
              cancel
            </Button>
            <Button
              onClick={HandleSubmits}
              variant="contained"
              color="primary"
              type="submit"
              className="mpl-primary-btn"
              sx={{ marginTop: "1.75rem" }}
            >
              Schedule
            </Button>
          </Grid>
        </Grid>
      </Popup>
      <RejectPopup
        openPopup={reject}
        setOpenPopup={setReject}
        handleRejectClick={handleRejectClick}
        errors={errors}
        setErrors={setErrors}
        setValues={setValues}
        values={values}
      />
      <AcceptPopup
        accept={accept}
        setAccept={setAccept}
        handleStageDataLoading={handleStageDataLoading}
        handleValues={handleValues}
        errors={errors}
        values={values}
        setErrors={setErrors}
        setValues={setValues}
        handleRejectClick={handleRejectClick}
        stagesData={stagesData}
      />
      <FlagCandidatePopup
        openPopup={FlagCandidate}
        setOpenPopup={setFlagCandidate}
        handleFlagCandidateClick={handleFlagCandidateClick}
        errors={errors}
        setValues={setValues}
        setErrors={setErrors}
        values={values}
      />
      <Popup
        fullWidth={true}
        maxWidth="md"
        title="Contact Email"
        openPopup={openemailPopup}
        setOpenPopup={setOpenEmailPopup}
      >
        <ContactEmailForm
          setOpenPopup={setOpenEmailPopup}
          currentData={currentData}
        />
      </Popup>
    </>
  );
}
export default TableBoard;
