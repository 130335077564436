import { Box, Button, Divider, InputBase, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { grey } from "@mui/material/colors";
import { renderEmail } from "react-html-email";
import { AddEmailTokenHelper } from "../../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { ShareCandidateResumeHelper } from "../../helper/AtsCandidateHelper";
import { DownloadAttachment, getUserData } from "../../../Base/helper/baseFunctions";
import OfferApproveEmail from "../ATSCandidate/OfferApproveEmail";
import { OfferEmailSentHelper } from "../../../ATSSettings/helper/AtsFormTemplateResponseHelper";
import { GetAtsJobKanbanOfferHelper } from "../../helper/AtsJobKanbanHelper";

const SendReshareOfferLetter = ({
  setOpenPopup,
  currentData,
  currentJobData,
  setElements
}) => {
  let userData = getUserData();
  const initialValues = {
    to: "",
    subject: "Congratulations! Your Offer Letter Is Here",
    content:`We are pleased to extend to you the offer for the position of ${currentData?.title}. Please find the attached offer letter. Kindly confirm your acceptance by responding below. Should you have any questions, feel free to contact :  ${userData?.office_email || userData?.email_id}. Congratulations!`
  };
  const [inputData, setInputData] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues) => {
    let temp = { ...errors };

    if ("to" in fieldValues) {
      temp.to = fieldValues.to?.trim() === "" ? "To Email is required" : "";
    }
    if ("subject" in fieldValues) {
      temp.subject =
        fieldValues.subject?.trim() === "" ? "Subject is required" : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    currentData?.first_name &&
      setInputData({
        ...inputData,
        to: `${currentData?.email_id}`,
      });
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (event, value) => {
    setInputData({ ...inputData, [event?.target?.name]: event?.target?.value });
    Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
      validate({ ...inputData, [event.target.name]: event.target.value });
  };
  const handleSend = () => {
    if (validate(inputData)) {
      let token = uuidv4() + `=${userData?.domain}`;
      AddEmailTokenHelper({
        token: token,
        email_id: inputData?.to,
        json_data: JSON.stringify([
          {
            ...inputData,
            ...currentData,
            request_emp_id: userData?.employee_id,
            request_ats_id: userData?.ats_user_id,
            request_emp_email : userData?.office_email || userData?.email_id, 
            to: inputData?.to,
          },
        ]),
      });
      ShareCandidateResumeHelper(
        {
          to: inputData?.to,
          bcc: inputData?.bcc,
          subject: inputData?.subject,
          resume_path: currentData?.offer_letter_cloud_path,
          resume_name:
            currentData?.offer_letter_cloud_path &&
            `${currentData?.first_name}.${currentData?.offer_letter_cloud_path
              .split(".")
              .at(-1)}`,
          attachments: currentData?.offer_letter_cloud_path,
          message: "Send Offer Letter sent successfully",
          content: renderEmail(
              <OfferApproveEmail
                userName={currentData?.first_name}
                guid={token}
                message={inputData?.content}
                approvebutton={"Accept"}
                rejectbutton={"Reject"}
                url="/ats/offer-accept/"
              />
          ),
        },
        "Email sent Successfully"
      );
      OfferEmailSentHelper({
        update_tblcolumn: "candidate_email_sent",
        offer_letter_response_id: currentData?.offer_letter_response_id,
        update_tblcolumn_value: "true",
      }).then((res) => {
        GetAtsJobKanbanOfferHelper({
          ats_jobpost_id: currentJobData?.ats_jobpost_id,
        }).then((res) => {
          res?.data?.data && setElements(res?.data?.data);
          setOpenPopup(false);
        });
      });
    }
  };

  return (
    <div>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <InputBase
            disabled
            value={inputData?.to}
            multiline
            fullWidth
            name="to"
            onChange={handleInputChange}
            sx={{ padding: "5px 0px" }}
            placeholder="To"
          />
        </Box>
      </Box>
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.to}</Typography>
      <InputBase
        fullWidth
        name="subject"
        value={inputData?.subject}
        onChange={handleInputChange}
        sx={{ padding: "5px 0px" }}
        placeholder="Subject"
      />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Typography color="error">{errors.subject}</Typography>
      <InputBase
        fullWidth
        name="content"
        value={inputData?.content}
        onChange={handleInputChange}
        minRows={4}
        multiline
        sx={{ padding: "5px 0px" }}
        placeholder="Content"
      />
       <Divider sx={{ borderStyle: "dashed" }} />

       {currentData?.offer_letter_cloud_path && (
        <Box marginTop={2} display="flex" alignItems={"center"}>
          <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
            Offer Letter:&nbsp;
          </Typography>
          <Typography
            className="file-name"
            sx={{ color: "#1155cc" }}
            onClick={() => DownloadAttachment(currentData?.offer_letter_cloud_path)}
            style={{ cursor: "pointer" }}
          >
            {currentData?.first_name || " "}_Offer_Letter.
            {currentData?.offer_letter_cloud_path.split(".").at(-1)}
          </Typography>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          className="mpl-secondary-btn"
          sx={{ ":hover": { color: grey[50] } }}
          size="small"
          variant="text"
          onClick={() => setOpenPopup(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            ml: 1,
            color: grey[50],
            ":hover": { backgroundColor: "#f9c307", color: "#000000" },
          }}
          size="small"
          variant="contained"
          className="mpl-primary-btn"
          onClick={handleSend}
        >
          Send
        </Button>
      </Box>
    </div>
  );
};

export default SendReshareOfferLetter;
