import { Box, FormControl, Grid } from "@mui/material";
import MaterialTable from "material-table";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import { useState } from "react";
import CommonDatePicker from "../../../Base/views/CommonDatePicker";
import { GetDailyProductivityAction } from "../../../../../store/ATS/Report/Action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ConvertDateTime } from "../../../Base/helper/baseFunctions";
import CustomToggle from "../../../Base/views/CustomToggle";
const DailyProductivityReport = () => {
    const dispatch = useDispatch();
    const date = new Date();
    const default_date = `${date?.getFullYear() + '-' + ("0" + (date?.getMonth() + 1)).slice(-2) + '-' + ("0" + date?.getDate()).slice(-2)}`;
    const [userData, setUserData] = useState({ load: false, values: [] })
    const [filters, setFilters] = useState({ primary_recruiter_filter: [], secondary_recruiter_filter: [], from_date: default_date });
    const [toggle, setToggle] = useState(false);
    const { atsDailyProductivity } = useSelector(state => state.AtsReducer?.AtsReportReducer);
    const { atsRecruiterData } = useSelector(state => state.AtsReducer?.AtsReportReducer);

    useEffect(() => {
        let precruiter = []
        let srecruiter = []
        filters?.primary_recruiter_filter?.forEach(res => precruiter.push(res?.ats_users_id))
        filters?.secondary_recruiter_filter?.forEach(res => srecruiter.push(res?.ats_users_id))
        dispatch(GetDailyProductivityAction({
            from_date: filters?.from_date,
            to_date: filters?.from_date,
            primary_recruiter:  precruiter?.length > 0  ? `{${precruiter.join(',')}}` : null,
            secondary_recruiter: srecruiter?.length > 0 ? `{${srecruiter.join(',')}}` : null
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters])

    const handleFilter = (event, value) => {
        if (["primary_recruiter_filter", "secondary_recruiter_filter"].includes(event)) {
            setFilters({ ...filters, [event]: value })
        }
        if (["from_date"].includes(event?.target?.name)) {
            setFilters({ ...filters, [event?.target?.name]: event?.target?.value })
        }
    }

    const handleToggle = () => {
        setToggle(!toggle);
        setFilters(prevFilters => ({
            ...prevFilters,
            primary_recruiter_filter: [], secondary_recruiter_filter: [], from_date: default_date, to_date: default_date
        }));
    };

    return (
        <>
            <Box sx={{ mt: 1 }}>
                <Grid container columnSpacing={1} mb={1}>
                    <Grid key="primary_recruiter_id" item xs={12} sm={4}>
                        {toggle === true ? <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                                +option.ats_users_id === +value.ats_users_id
                            }
                            label="Recruiter"
                            value={filters.secondary_recruiter_filter}
                            loading={() => setUserData({ load: false, values: atsRecruiterData })}
                            fieldLabel="first_name"
                            required={false}
                            multiple={true}
                            grouping={true}
                            variant={"outlined"}
                            name="secondary_recruiter_filter"
                            onChangeEvent={handleFilter}
                            data={userData}
                        /> :
                            <SelectableSearch
                                isOptionEqualToValue={(option, value) =>
                                    +option.ats_users_id === +value.ats_users_id
                                }
                                label="Recruiter"
                                value={filters.primary_recruiter_filter}
                                loading={() => setUserData({ load: false, values: atsRecruiterData })}
                                fieldLabel="first_name"
                                required={false}
                                multiple={true}
                                grouping={true}
                                variant={"outlined"}
                                name="primary_recruiter_filter"
                                onChangeEvent={handleFilter}
                                data={userData}
                            />
                        }

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <CommonDatePicker
                                name="from_date"
                                label="Date"
                                pickerType="date"
                                onKeyPress={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={filters.from_date}
                                maxDate={new Date()}
                                handleChange={(value) =>
                                    handleFilter({
                                        target: {
                                            name: "from_date",
                                            value: value
                                                ? `${value?.getFullYear() +
                                                "-" +
                                                ("0" + (value?.getMonth() + 1)).slice(-2) +
                                                "-" +
                                                ("0" + value?.getDate()).slice(-2)
                                                }`
                                                : "",
                                        },
                                    })
                                }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <MaterialTable
                    columns={[
                        { title: "Date", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "modified_date", editable: "never", render: rowData => { return rowData?.modified_date ? ConvertDateTime(rowData?.modified_date) : "    " } },
                        { title: "Recruiter", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "recruiter_name", editable: "never" },
                        { title: "Job Title", cellStyle: { padding: "10px", textAlign: "left" }, headerStyle: { padding: "10px", textAlign: "left" }, field: "job_title", editable: "never" },
                        { title: "Job Creation Date", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "job_creation_date", editable: "never",render: rowData => { return rowData?.job_creation_date ? ConvertDateTime(rowData?.job_creation_date) : "    " } },
                        { title: "Candidates Proposed", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "shortlisted", editable: "never" },
                        { title: "Interviews Scheduled", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "interviews_scheduled", editable: "never" },
                        { title: "Candidate Rejected", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "rejected", editable: "never" },
                        { title: "Joined", cellStyle: { padding: "10px", textAlign: "center" }, headerStyle: { padding: "10px", textAlign: "center" }, field: "joined", editable: "never" },

                    ]}
                    data={atsDailyProductivity || []}
                    options={{
                        maxBodyHeight: "54vh",
                        minBodyHeight: "54vh",
                        rowStyle: { fontSize: "13px" },
                        sorting: false,
                        search: false,
                        searchFieldAlignment: "right",
                        searchAutoFocus: true,
                        searchFieldVariant: "standard",
                        filtering: false,
                        exportButton: atsDailyProductivity?.length > 0 && {
                            csv: true,
                            pdf: true,
                        },
                        paging: true,
                        pageSizeOptions: [25, 50],
                        pageSize: 25,
                        showTextRowsSelected: true,
                        selectionProps: rowData => ({
                            disabled: [null, '', 0, '0'].includes(rowData.commission_percentage),
                        }),
                        showSelectAllCheckbox: false,
                        actionsColumnIndex: -1,
                        grouping: false,
                        addRowPosition: "first",
                        columnsButton: true,
                    }}
                    actions={[
                        {
                            icon: () => (
                                <CustomToggle
                                    isChecked={toggle}
                                    handleToggleChange={handleToggle}
                                    Yestext={'Secondary'}
                                    NoText={'Primary'}
                                    style={{ height: '32px', padding: '0px', width: '145px', labelPadding: '1px' }}
                                />
                            ),
                            position: "toolbar",
                            isFreeAction: true,
                            tooltip: "Toggle to Switch To Secondary Recruiter",
                        },
                    ]}
                    title="Daily Productivity"
                />
            </Box>
        </>
    )
}

export default DailyProductivityReport;