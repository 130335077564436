import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    MenuItem,
    FormControlLabel,
    Typography,
    Box,
    Grid,
    Fab,
    Tooltip,
    Card,
    CardContent,
    IconButton,
    CardActions,
    Divider,
    Menu,
    List,
    Switch,
    Skeleton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { Add } from "@mui/icons-material";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ArrowBackButton from "../../../Base/views/ArrowBackButton";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { grey } from "@mui/material/colors";
import { NoData } from "../../../Base/views/NotAuthorized";
import { AddFormTemplateAction, DeleteFormTemplateAction, GetFormTemplateAction, UpdateFormTemplateAction } from "../../../../../store/ATS/FormTemplate/Action";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";


function FormBuilder({ formBuilder, setFormBuilder, JD, title }) {
    const dispatch = useDispatch()
    const [formFields, setFormFields] = useState([]);
    const [formComponents, setFormComponents] = useState([]);
    const [formHeading, setFormHeading] = useState({ heading: "" });
    const [menu, setMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [errors, setErrors] = useState({});
    const [builder, setBuilder] = useState(formBuilder || false);
    const [formId, setFormId] = useState("");
    const { templateData, isLoading } = useSelector((state) => state.AtsReducer?.FormTemplateReducer);
    const [deleteConfirm, setDeleteConform] = useState(false);
    useEffect(() => {
        if (JD) {
            setFormHeading({ heading: title })
        }
        else dispatch(GetFormTemplateAction())
        //eslint-disable-next-line
    }, []);



    const updateFormComponents = (fields) => {
        const generatedComponents = fields.map((field, index) => {
            switch (field.type) {
                case "text":
                    return (
                        <>
                            <TextField
                                 sx={{ paddingLeft: "20px" }}
                                 InputLabelProps={{
                                     sx: {
                                         marginLeft:"15px",
                                     }
                                 }}
                                key={field.name}
                                label={"Enter your answer"}
                                name={field.name}
                                fullWidth
                                multiline
                                disabled={true}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                required={field?.required}
                                defaultValue=""
                            />
                        </>
                    );
                case "file":
                    return (
                        <>
                            <TextField
                                sx={{ paddingLeft: "20px" }}
                                key={field.name}
                                name={field.name}
                                size="small"
                                required={field?.required}
                                fullWidth
                                disabled={true}
                                variant="outlined"
                                margin="normal"
                                type="file"
                            />
                        </>
                    );
                default:
                    return null;
            }
        });

        setFormComponents(generatedComponents);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setMenu(true);
    };

    const handleBack = () => {
        setFormHeading({ heading: "" });
        setFormId("");
        setFormFields([]);
        setFormComponents([]);
        setErrors({});
        setBuilder(!builder)
    }

    const addField = (params) => {
        const newField = {
            name: `field${formFields.length + 1}`,
            label: "",
            type: params?.field?.type,
            options: [],
            required: false,
            multiple: false
        };
        setFormFields([...formFields, newField])
        setMenu(!menu);
    };

    const removeField = (index, field) => {
        const updatedFields = [...formFields];
        const errorKeysToClear = [
            `label${index}`,
            `options${index}`,
        ];

        for (let optionIndex = 0; optionIndex < field.options?.length; optionIndex++) {
            errorKeysToClear.push(`optionLabel${index}${optionIndex}`);
            errorKeysToClear.push(`optionValue${index}${optionIndex}`);
        }

        let temp = { ...errors };
        errorKeysToClear.forEach((key) => {
            temp[key] = "";
        });

        setErrors({
            ...temp,
        });
        updatedFields.splice(index, 1);
        setFormFields(updatedFields);
    };

    const addRadioOption = (fieldIndex) => {
        const updatedFields = [...formFields];
        updatedFields[fieldIndex].options.push({ value: "", label: "" });
        setFormFields(updatedFields);
    };

    const removeOption = (index, optionIndex) => {
        const updatedFields = [...formFields];
        updatedFields[index].options.splice(
            optionIndex,
            1
        );
        setFormFields(updatedFields);
    };

    const createFieldObj = () => {
        const fieldObjects = formFields.map((field, index) => {
            const name = field.label.toLowerCase().replace(/ /g, "_");
            const fieldObj = {
                name: name,
                type: field.type,
                label: field.label,
                sequence_no: index,
                required: field?.required,
                multiple: field?.multiple
            };

            if (field.type === "radio") {
                fieldObj.options = field.options.map((option) => ({
                    label: option.label,
                    value: option.value,
                }));
            }
            return fieldObj;
        });
        return fieldObjects;
    };

    const validate = (fieldValues, formHeading) => {
        let temp = { ...errors };

        if ("heading" in formHeading) {
            temp.heading = formHeading.heading === "" ? "Form Heading is required" : "";
        }

        fieldValues.forEach((field, index) => {
            if (field.label === "") {
                temp[`label${index}`] = `Question ${index + 1} is required`;
            } else {
                temp[`label${index}`] = "";
            }

            if (field.type === "radio") {
                if (field.options.length === 0) {
                    temp[`options${index}`] = `Options are required for Question ${index + 1}`;
                } else {
                    temp[`options${index}`] = "";
                    field.options.forEach((option, optionIndex) => {
                        if (option.label === "") {
                            temp[
                                `optionLabel${index}${optionIndex}`
                            ] = `Option ${optionIndex + 1} is required for Question ${index + 1}`;
                        } else {
                            temp[`optionLabel${index}${optionIndex}`] = "";
                        }
                    });
                }
            }
        });

        setErrors({
            ...temp,
        });
        return Object.values(temp).every((x) => x === "");
    };

    const handleChange = (params) => {
        const updatedFields = [...formFields];
        if (params?.e?.target?.name === "field_type") {
            updatedFields[params?.index].type = params?.e.target.value;
            const errorKeysToClear = [
                `label${params?.index}`,
                `options${params?.index}`,
            ];
            for (let optionIndex = 0; optionIndex < params?.field.options.length; optionIndex++) {
                errorKeysToClear.push(`optionLabel${params?.index}${optionIndex}`);
            }

            let temp = { ...errors };
            errorKeysToClear.forEach((key) => {
                temp[key] = "";
            });

            setErrors({
                ...temp,
            });
        }
        if (params?.e?.target?.name === "required") {
            updatedFields[params?.index].required = params?.e?.target?.checked
        }
        if (params?.e?.target?.name === "multiple") {
            updatedFields[params?.index].multiple = params?.e?.target?.checked
        }
        if (params?.e?.target?.name === "field_label") {
            updatedFields[params?.index].label = params?.e.target.value;
        }
        if (params?.e?.target?.name === "option_label") {
            updatedFields[params?.index].options[params?.optionIndex].label = params?.e.target.value;
            updatedFields[params?.index].options[params?.optionIndex].value = params?.e.target.value.toLowerCase().replace(/ /g, "_");
            setFormFields(updatedFields);
        }
        setFormFields(updatedFields);
        updateFormComponents(updatedFields);
    };

    const onSubmit = async () => {
        if (validate(formFields, formHeading)) {
            const dbObj = createFieldObj();
            if (formId) {
                dispatch(UpdateFormTemplateAction({
                    template_id: formId,
                    template_name: formHeading?.heading,
                    template_fields: JSON.stringify(dbObj)
                }))
                if (JD) {
                    setFormBuilder(!formBuilder)
                }
                setFormHeading({ heading: "" });
                setFormId("");
                setFormFields([]);
                setFormComponents([]);
                setBuilder(false)
            }
            else {
                dispatch(AddFormTemplateAction({
                    template_name: formHeading?.heading,
                    template_fields: JSON.stringify(dbObj)
                }))
                if (JD) {
                    setFormBuilder(!formBuilder)
                }
                setFormHeading({ heading: "" });
                setFormFields([]);
                setFormComponents([]);
                setBuilder(false)
            }
        };
    }

    const handleEdit = (data) => {
        setFormId(data?.template_id)
        setFormHeading({ heading: data?.template_name });
        setFormFields(data?.template_fields);
        updateFormComponents(data?.template_fields);
        setBuilder(!builder);
    }

    const handleDeleteConfirm = (id) => {
        setFormId(id);
        setDeleteConform(!deleteConfirm);
    }

    const handleDelete = () => {
        dispatch(DeleteFormTemplateAction({ template_id: formId }));
        setFormId("");
        setDeleteConform(!deleteConfirm);
    }

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedFields = [...formFields];
        const [reorderedField] = updatedFields.splice(result.source.index, 1);
        updatedFields.splice(result.destination.index, 0, reorderedField);
        setFormFields(updatedFields);
    };

    return (
        <Box sx={{ maxHeight: "80vh", overflowY: "auto", pb: 2 }}>
            {!builder ?
                <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#ffffff", display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => setBuilder(!builder)}
                        variant="contained"
                        className="mpl-primary-btn"
                        startIcon={<Add />}
                        sx={{ marginBottom: "10px" }}
                    >
                        Add Form
                    </Button>
                </Box>
                :
                !JD && <Box sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#ffffff", display: "flex", alignItems: "center", justifyContent: "flex-start", p: 2 }}>
                    <ArrowBackButton onClick={handleBack} />
                </Box>
            }
            {!builder ?
                <>
                    {isLoading ? <Grid container direction="row" alignItems="center" spacing={2} mt={1}>
                        {Array(8)
                            .fill()
                            .map((data, index) => (
                                <Grid key={index} item lg={3} md={3} sm={3} xs={12}>
                                    <Card
                                        key={index}
                                        sx={{
                                            ":hover": { cursor: "pointer" },
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                            minHeight: "125px",
                                            maxHeight: "125px"
                                        }}
                                    >
                                        <CardContent>
                                            <Skeleton
                                                sx={{ height: 40 }}
                                                animation="wave"
                                                variant="rectangular"
                                            />
                                        </CardContent>
                                        <Divider variant="middle" />
                                        <CardActions sx={{ padding: "0px" }}>
                                            <Box
                                                sx={{
                                                    padding: "0px 20px 8px 20px",
                                                    display: "flex",
                                                    WebkitBoxPack: "justify",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Skeleton
                                                    animation="wave"
                                                    sx={{ borderRadius: 4 }}
                                                    height={20}
                                                    width="30%"
                                                />
                                                <Skeleton
                                                    animation="wave"
                                                    sx={{ borderRadius: 4 }}
                                                    height={20}
                                                    width="30%"
                                                />
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                        :
                        templateData?.length > 0 ? <Grid container direction="row" alignItems="center" spacing={2} mt={1}>
                            {templateData?.map((template, index) => (
                                <Grid key={index} item lg={3} md={3} sm={3} xs={12} >
                                    <Card key={index} sx={{ ":hover": { cursor: "pointer" }, minHeight: "125px", maxHeight: "125px" }}>
                                        <CardContent>
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <Tooltip title={template?.template_name}>
                                                    <Typography variant="h5" className="truncate-heading">{template?.template_name}</Typography>
                                                </Tooltip>
                                            </Box>
                                        </CardContent>
                                        <Divider variant="middle" />
                                        <CardActions sx={{ padding: "0px" }}>
                                            <Box
                                                sx={{
                                                    padding: "0px 20px 8px 20px",
                                                    display: "flex",
                                                    WebkitBoxPack: "justify",
                                                    justifyContent: "space-between",
                                                    width: "100%",
                                                }}
                                            >
                                                <Tooltip title="Edit">
                                                    <IconButton onClick={() => handleEdit(template)}>
                                                        <EditIcon color="primary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                {
                                                    template?.feedback_response_id === null ?
                                                        <Tooltip title={template?.is_default ? "This is the default template and cannot be deleted" : "Delete"}>
                                                            <IconButton onClick={() => handleDeleteConfirm(template?.template_id)} disabled={template?.is_default} sx={{ color: template?.is_default ? 'grey.500' : 'error.main' }}>
                                                                <DeleteIcon  fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title={"Responses are submitted for this form template. Please create new template."}>
                                                            <IconButton sx={{ color:'grey.500' }}>
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                }

                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>))}
                        </Grid>
                            : <NoData title="You Haven't Created any Templated Yet!" />
                    }
                </>
                : <Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}                              >
                        <Grid container spacing={2} style={{ justifyContent: "center" }}>
                            <Grid item xs={JD ? 12 : 8} md={JD ? 12 : 8}>
                                <Card>
                                    <CardContent>
                                        <TextField
                                            label="Form Title"
                                            size="small"
                                            variant="standard"
                                            margin="normal"
                                            name="heading"
                                            fullWidth
                                            value={formHeading?.heading}
                                            required={true}
                                            sx={{ mb: 4 }}
                                            inputProps={{ style: { fontSize: 23 } }}
                                            InputLabelProps={{ style: { fontSize: 20 } }}
                                            onChange={(e) => setFormHeading({ [e.target.name]: e.target.value })}
                                            {...(errors.heading && {
                                                error: true,
                                                helperText: errors.heading,
                                            })}
                                        />
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId="formFields">
                                                {(provided) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef} >
                                                        {formFields.map((field, index) => (
                                                            <>
                                                                <Draggable key={field.name} draggableId={field.name} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div key={field.name} style={{ marginBottom: "50px", backgroundColor: grey[100], borderRadius: "10px" }}>
                                                                                <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
                                                                                    <DragIndicatorIcon style={{ transform: 'rotate(90deg)' }} />
                                                                                </Box>
                                                                                <Grid container spacing={2} direction={"row"} p={2} >
                                                                                    <Grid item xs={12} md={12} lg={12}>
                                                                                    <Box display="flex" alignItems="center">
                                                                                    <span style={{ marginRight: "8px" }}>{index + 1}.</span>
                                                                                        <TextField
                                                                                            label="Question"
                                                                                            value={field.label}
                                                                                            name="field_label"
                                                                                            size="small"
                                                                                            fullWidth
                                                                                            onChange={(e) => {
                                                                                                handleChange({ e, index });
                                                                                            }}
                                                                                            {...(errors[`label${index}`] && {
                                                                                                error: true,
                                                                                                helperText: errors[`label${index}`],
                                                                                            })}
                                                                                        />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    {field.type !== "radio" &&
                                                                                        <Grid item xs={12} md={12} lg={12}>
                                                                                            {formComponents[index]}
                                                                                        </Grid>
                                                                                    }
                                                                                </Grid>
                                                                                {field.type === "select" && (
                                                                                    <Box sx={{ p: 2,  marginLeft:"20px"}}>
                                                                                        <Button
                                                                                            variant="outlined"
                                                                                            color="primary"
                                                                                            startIcon={<Add />}
                                                                                            onClick={() => {
                                                                                                const updatedFields = [...formFields];
                                                                                                updatedFields[index].options.push({
                                                                                                    value: "",
                                                                                                    label: "",
                                                                                                });
                                                                                                setFormFields(updatedFields);
                                                                                            }}
                                                                                        >
                                                                                            Add Option
                                                                                        </Button>
                                                                                        {field.options.map((option, optionIndex) => (
                                                                                            <div
                                                                                                key={optionIndex}
                                                                                                style={{ marginTop: "10px" }}
                                                                                            >
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={8}>
                                                                                                        <TextField
                                                                                                            type="text"
                                                                                                            placeholder={`Option ${optionIndex + 1} `}
                                                                                                            value={option.label}
                                                                                                            size="small"
                                                                                                            name="option_label"
                                                                                                            fullWidth
                                                                                                            required
                                                                                                            onChange={(e) => {
                                                                                                                handleChange({ e, index, optionIndex });
                                                                                                            }}
                                                                                                            {...(errors[`optionLabel${index}${optionIndex}`] && {
                                                                                                                error: true,
                                                                                                                helperText: errors[`optionLabel${index}${optionIndex}`],
                                                                                                            })}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={2}>
                                                                                                        <Tooltip title="Remove Option">
                                                                                                            <Fab
                                                                                                                onClick={() => {
                                                                                                                    removeOption(index, optionIndex);
                                                                                                                }}
                                                                                                                size="small"
                                                                                                                color="error"
                                                                                                                aria-label="delete"
                                                                                                                mt={1}
                                                                                                            >
                                                                                                                <DeleteIcon fontSize="small" />
                                                                                                            </Fab>
                                                                                                        </Tooltip>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        ))}
                                                                                        {errors[`options${index}`] && (
                                                                                            <Typography variant="body2" color="error">
                                                                                                {errors[`options${index}`]}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Box>
                                                                                )}
                                                                                {field.type === "radio" && (
                                                                                    <Box sx={{ p: 2, marginLeft:"20px" }}>
                                                                                        <Button
                                                                                            variant="outlined"
                                                                                            color="primary"
                                                                                            startIcon={<Add />}
                                                                                            onClick={() => addRadioOption(index)}
                                                                                        >
                                                                                            Add Option
                                                                                        </Button>
                                                                                        {field.options.map((option, optionIndex) => (
                                                                                            <div
                                                                                                key={optionIndex}
                                                                                                style={{ marginTop: "10px" }}
                                                                                            >
                                                                                                <Grid container spacing={2}>
                                                                                                    <Grid item xs={8}>
                                                                                                        <TextField
                                                                                                            type="text"
                                                                                                            placeholder={`Option ${optionIndex + 1} `}
                                                                                                            value={option.label}
                                                                                                            size="small"
                                                                                                            name="option_label"
                                                                                                            fullWidth
                                                                                                            required
                                                                                                            onChange={(e) => {
                                                                                                                handleChange({ e, index, optionIndex });
                                                                                                            }}
                                                                                                            {...(errors[`optionLabel${index}${optionIndex}`] && {
                                                                                                                error: true,
                                                                                                                helperText: errors[`optionLabel${index}${optionIndex}`],
                                                                                                            })}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={2}>
                                                                                                        <Tooltip title="Remove Option">
                                                                                                            <Fab
                                                                                                                onClick={() => {
                                                                                                                    removeOption(index, optionIndex);
                                                                                                                }}
                                                                                                                size="small"
                                                                                                                color="error"
                                                                                                                aria-label="delete"
                                                                                                            >
                                                                                                                <DeleteIcon fontSize="small" />
                                                                                                            </Fab>
                                                                                                        </Tooltip>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        ))}
                                                                                        {errors[`options${index}`] && (
                                                                                            <Typography variant="body2" color="error">
                                                                                                {errors[`options${index}`]}
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Box>
                                                                                )}
                                                                                <Box sx={{ display: "flex", justifyContent: "flex-end", mb: -2 }}>
                                                                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                                                        <FormControlLabel
                                                                                            control={
                                                                                                <Switch checked={field?.required} onChange={(e) => handleChange({ e, index })} name="required" />
                                                                                            }
                                                                                            label="Required"
                                                                                        />
                                                                                        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 0.5, p: 2 }}>
                                                                                            <Tooltip title="Remove Field">
                                                                                                <IconButton
                                                                                                    onClick={() => removeField(index, field)}
                                                                                                    size="small"
                                                                                                    color="error"
                                                                                                    aria-label="delete"
                                                                                                    mt={1}
                                                                                                >
                                                                                                    <DeleteIcon fontSize="small" />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </div>
                                                                        </div>)}
                                                                </Draggable>
                                                                <Box sx={{ width: "100%", p: 2 }}>
                                                                    {index === formFields.length - 1 && (
                                                                        <Tooltip title="Add New Question">
                                                                            <IconButton onClick={handleMenu} className="mpl-primary-btn">
                                                                                <Add fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Menu
                                                                        anchorEl={anchorEl}
                                                                        open={menu}
                                                                        onClose={() => setMenu(!menu)}
                                                                        value={field.type}
                                                                        anchorOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'center',
                                                                            horizontal: 'left',
                                                                        }}
                                                                        getContentAnchorEl={null}
                                                                    >
                                                                        <List sx={{ display: 'flex', flexDirection: 'row', pt: 0, pb: 0 }}>
                                                                            <MenuItem
                                                                                value="text"
                                                                                name="field_type"
                                                                                onClick={(e) => {
                                                                                    handleChange({ e, index, field: { ...field, type: 'text' } });
                                                                                    addField({ e, index, field: { ...field, type: 'text' } });
                                                                                }}
                                                                            >
                                                                                <TextFieldsIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                                                                Text
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                value="radio"
                                                                                name="field_type"
                                                                                onClick={(e) => {
                                                                                    handleChange({ e, index, field: { ...field, type: 'radio' } });
                                                                                    addField({ e, index, field: { ...field, type: 'radio' } });
                                                                                }}
                                                                            >
                                                                                <RadioButtonCheckedIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                                                                Choice
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                value="file"
                                                                                name="field_type"
                                                                                onClick={(e) => {
                                                                                    handleChange({ e, index, field: { ...field, type: 'select' } });
                                                                                    addField({ e, index, field: { ...field, type: 'file' } });
                                                                                }}
                                                                            >
                                                                                <CloudUploadIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                                                                File Upload
                                                                            </MenuItem>
                                                                        </List>
                                                                    </Menu>
                                                                </Box>
                                                            </>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}

                                            </Droppable>
                                            <Box sx={{ width: "100%", p: 2 }}>
                                                {formFields.length === 0 && (
                                                    <Tooltip title="Add New Question">
                                                        <IconButton onClick={handleMenu} className="mpl-primary-btn">
                                                            <Add fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={menu}
                                                    onClose={() => setMenu(!menu)}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'left',
                                                    }}
                                                    getContentAnchorEl={null}
                                                >
                                                    <List sx={{ display: 'flex', flexDirection: 'row', pt: 0, pb: 0 }}>
                                                        <MenuItem
                                                            value="text"
                                                            name="field_type"
                                                            onClick={(e) => {
                                                                addField({ field: { type: 'text' } });
                                                            }}
                                                        >
                                                            <TextFieldsIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                                            Text
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="radio"
                                                            name="field_type"
                                                            onClick={(e) => {
                                                                addField({ field: { type: 'radio' } });
                                                            }}
                                                        >
                                                            <RadioButtonCheckedIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                                            Choice
                                                        </MenuItem>
                                                        <MenuItem
                                                            value="file"
                                                            name="field_type"
                                                            onClick={(e) => {
                                                                addField({ field: { type: 'file' } });
                                                            }}
                                                        >
                                                            <CloudUploadIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                                                            File Upload
                                                        </MenuItem>
                                                    </List>
                                                </Menu>
                                            </Box>
                                        </DragDropContext>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Button
                                variant="contained"
                                className="mpl-primary-btn"
                                onClick={onSubmit}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>}
            <ConfirmationDialog
                title="Delete Template?"
                content={"Are you sure want to Delete this Template?"}
                openDialog={deleteConfirm}
                closeDialog={setDeleteConform}
                OkButtonText="OK"
                onSubmit={handleDelete}
            />
        </Box>
    );
}

export default FormBuilder;